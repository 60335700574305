import styled from '@emotion/styled';
import CloseIcon from '@mui/icons-material/Close';
import { useModal } from 'ui/components/publicPages/screens/modals/useModal';
import TitleServiceImg from 'ui/images/casting-flow/title_service_pc.png';
import TitleService2Img from 'ui/images/casting-flow/title_service_pc2.png';
import TitleServiceSpImg from 'ui/images/user-lp/title_service_sp.png';
import TextService1Img from 'ui/images/user-lp/text_service_01.png';
import TextService2Img from 'ui/images/user-lp/text_service_02.png';
import TextService3Img from 'ui/images/user-lp/text_service_03.png';
import ServiceOwnerImg from 'ui/images/user-lp/img_service_owner.png';
import ServiceSpreaderImg from 'ui/images/user-lp/img_service_spreader.png';
import ServiceQuestImg from 'ui/images/user-lp/img_service_guest.png';
import ServiceStatement1Img from 'ui/images/user-lp/img_service_statement01.png';
import ServiceStatement2Img from 'ui/images/user-lp/img_service_statement02.png';
import Step01 from 'ui/images/user-lp/ttl_service_step01.png';
import Step01Bg from 'ui/images/user-lp/bg_service_step01_pc.png';
import Step01BgSp from 'ui/images/user-lp/bg_service_step01_sp.png';
import Step02 from 'ui/images/user-lp/ttl_service_step02.png';
import Step02Bg from 'ui/images/user-lp/bg_service_step02_pc.png';
import Step02BgSp from 'ui/images/user-lp/bg_service_step02_sp.png';
import Step03 from 'ui/images/user-lp/ttl_service_step03.png';
import Step03Bg from 'ui/images/user-lp/bg_service_step03_pc.png';
import Step03BgSp from 'ui/images/user-lp/bg_service_step03_sp.png';
import BtmPc from 'ui/images/user-lp/text_session_btm_pc.png';
import BtmSp from 'ui/images/user-lp/text_session_btm_sp.png';
import { mq } from 'utils/responsive';
import { Only } from 'ui/components/publicPages/elements/Only/Only';
import { Button } from 'ui/components/publicPages/elements/Button/Button';
import LaunchIcon from '@mui/icons-material/Launch';

const UnderLine = styled.div`
  text-decoration: underline;
  cursor: pointer;
  margin: 5px 0;
`;

const ModalContainer = styled.div`
  background-color: var(--spr-white);
  height: 85vh;
  @supports (height: 100svh) {
    height: 85svh;
  }
  border-radius: 8px;
  padding: 24px;
  overflow-y: auto;

  p {
    margin: 0;
  }
`;

const ModalContent = styled.div`
  background-color: var(--spr-primary);
  border-radius: 18px;
  display: block;
  box-sizing: border-box;
  overflow: auto;
  outline: 0;
  max-width: calc(min(75vw, 800px));
  height: 100%;
  align-items: center;
  justify-content: center;

  padding: 20px 0 40px;
  ${mq('SP')} {
    padding: 13vh 0 6vh;
    @supports (height: 100svh) {
      padding: 13svh 0 6svh;
    }
  }
`;

const Top = styled.div`
  img {
    display: block;
    margin: 0 auto;
  }

  img:nth-of-type(1) {
    width: 300px;
  }
  img:nth-of-type(2) {
    width: 250px;
  }

  ${mq('SP')} {
    img {
      margin: 0 auto 13vw auto;
      width: 51vw;

      @supports (width: 100svw) {
        margin: 0 auto 13svw auto;
        width: 51svw;
      }
    }
  }
`;

const Close = styled.div`
  right: 0;
  top: 0;
  cursor: pointer;
  width: 40px;
  height: 40px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FlowUl = styled.ul`
  max-width: 705px;
  height: 30vh;
  @supports (height: 100svh) {
    height: 30svh;
  }
  position: relative;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;

  li:nth-of-type(1) img {
    margin-left: 0;
  }
  li:nth-of-type(2) img {
    position: relative;
    z-index: 10;
    margin-left: calc(max(-1vw, -10px));
  }
  li:nth-of-type(3) img {
    margin-left: calc(max(-2vw, -20px));
  }

  ${mq('SP')} {
    width: fit-content;
    height: 32.6vw;
    @supports (width: 100svw) {
      height: 32.6svw;
    }
    padding-left: 10px;
  }
`;

const FlowLi = styled.li`
  list-style: none;
  display: list-item;
  zoom: normal;
  width: 21vw;
  @supports (width: 100svw) {
    width: 21svw;
  }
`;

const PersonsContainer = styled.div`
  height: 460px;
  margin-top: -34px;
  position: relative;
  width: 100%;
  z-index: 20;

  img {
    position: absolute;
    left: 50%;
    height: auto;

    animation-name: bounce;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    @keyframes bounce {
      0% {
        transform: translate(0, 0px);
      }

      100% {
        transform: translate(0, 20px);
      }
    }

    &:nth-of-type(1) {
      animation-duration: 1.4s;
      animation-delay: 0.2s;
      top: 54px;
      margin-left: -312px;
      width: 225px;
    }
    &:nth-of-type(2) {
      animation-duration: 1.5s;
      animation-delay: 0;
      top: 0px;
      margin-left: -132px;
      width: 262px;
      z-index: 100;
    }
    &:nth-of-type(3) {
      animation-duration: 1.6s;
      animation-delay: 0.7s;
      top: 48px;
      margin-left: 110px;
      width: 200px;
    }

    ${mq('SP')} {
      &:nth-of-type(1) {
        top: 11.1vw;
        width: 22.4vw;
        margin-left: -29vw;

        @supports (width: 100svw) {
          top: 11.1svw;
          width: 22.4svw;
          margin-left: -29svw;
        }
      }
      &:nth-of-type(2) {
        top: 0vw;
        margin-left: -12vw;
        width: 26vw;

        @supports (width: 100svw) {
          top: 0svw;
          margin-left: -12svw;
          width: 26svw;
        }
      }
      &:nth-of-type(3) {
        top: 6vw;
        margin-left: 13.3vw;
        width: 16vw;

        @supports (width: 100svw) {
          top: 6svw;
          margin-left: 13.3svw;
          width: 16svw;
        }
      }
    }
  }
`;

const SessionTxt = styled.div`
  font-size: 16px;
  line-height: 2;
  font-weight: bold;
  text-align: center;
  position: relative;
  text-align: center;
  margin-bottom: 50px;
  ${mq('SP')} {
    font-size: 12px;
    margin-bottom: 21vw;

    @supports (width: 100svw) {
      margin-bottom: 21svw;
    }
  }
`;

const Break = styled.br`
  display: none;
  ${mq('SP')} {
    display: block;
  }
`;

const ServiceStatement1 = styled.img`
  top: 20px;
  margin-left: -340px;
  width: 120px;
  position: absolute;
  left: 50%;
  height: auto;
  ${mq('SP')} {
    margin-left: -30.4vw;
    width: 16vw;
    top: auto;

    @supports (width: 100svw) {
      margin-left: -30.4svw;
      width: 16svw;
    }
  }
`;

const ServiceStatement2 = styled.img`
  top: 38px;
  margin-left: 215px;
  width: 120px;
  position: absolute;
  left: 50%;
  height: auto;
  ${mq('SP')} {
    margin-left: 14.6vw;
    width: 16vw;
    top: auto;

    @supports (width: 100svw) {
      margin-left: 14.6svw;
      width: 16svw;
    }
  }
`;

const List = styled.ol`
  position: relative;
  z-index: 0;
  width: 100%;
  margin: 0 auto 40px;
  padding: 0;
  list-style: none;
  ${mq('SP')} {
    width: 100%;
    padding: 60px 0 0 0;
    margin: 0 0 40px;
  }
`;

const ListItem = styled.li`
  position: relative;
  background-size: cover;
  width: 100%;
  height: 385px;
  padding: 45px 57px;
  background-position: center center;
  background-repeat: no-repeat;
  ${mq('SP')} {
    height: 490px;
    padding: 50px 35px;
  }

  &:nth-of-type(1) {
    z-index: 300;
    background-image: url(${Step01Bg});
    ${mq('SP')} {
      height: 113.3vw;
      @supports (width: 100svw) {
        height: 113.3svw;
      }
      background-image: url(${Step01BgSp});
    }
  }
  &:nth-of-type(2) {
    z-index: 200;
    margin-top: -53px;
    background-image: url(${Step02Bg});
    ${mq('SP')} {
      height: 133.3vw;
      background-image: url(${Step02BgSp});
      margin-top: -9.5vw;

      @supports (width: 100svw) {
        height: 133.3svw;
        margin-top: -9.5svw;
      }
    }
  }
  &:nth-of-type(3) {
    z-index: 100;
    margin-top: -54px;
    height: 353px;
    background-image: url(${Step03Bg});
    ${mq('SP')} {
      height: 106.6vw;
      background-image: url(${Step03BgSp});
      margin-top: -10vw;

      @supports (width: 100svw) {
        height: 106.6svw;
        margin-top: -10svw;
      }
    }
  }
`;

const ItemImg = styled.img`
  width: 110px;
  height: auto;
  ${mq('SP')} {
    width: 21.3vw;

    @supports (width: 100svw) {
      width: 21.3svw;
    }
  }
`;

const ItemTitle = styled.h3`
  margin-block: 25px 9px;
  font-size: 24px;
  line-height: 30px;
  ${mq('SP')} {
    font-size: 5.3vw;
    @supports (width: 100svw) {
      font-size: 5.3svw;
    }
    margin-block: 15px;
  }
`;

const ItemText = styled.p`
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  white-space: nowrap;
  span {
    display: inline-block;
    margin-top: 6px;
    padding: 1px 6px;
    border-radius: 4px;
    background-color: var(--spr-black);
    font-size: 14px;
    color: var(--spr-white);
    font-weight: 500;
  }
  ${mq('SP')} {
    font-size: 2.93vw;
    @supports (width: 100svw) {
      font-size: 2.93svw;
    }
    line-height: 1.66;

    span {
      font-size: 2.93vw;
      @supports (width: 100svw) {
        font-size: 2.93svw;
      }
    }
  }
`;

const BtmImg = styled.div`
  width: 650px;
  margin-inline: auto;
  height: 225px;
  text-align: center;
  background-image: url(${BtmPc});
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  margin-bottom: 40px;

  ${mq('SP')} {
    width: 100%;
    height: 440px;
    background-image: url(${BtmSp});
    margin-bottom: 12vw;

    @supports (width: 100svw) {
      margin-bottom: 12svw;
    }
  }
`;

const CloseIconBottom = styled.div`
  display: flex;
  align-items: center;
  padding-top: 40px;
  font-size: 16px;
  justify-content: center;
  cursor: pointer;
  color: var(--spr-black);
`;

const SpreadyBottom = styled.div`
  width: 200px;
  margin: 0 auto;
  font-size: 16px;
  font-weight: bold;
  button {
    gap: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  svg {
    vertical-align: sub;
  }
`;

/**
 * cast画面:Spreadyの「セッション」とはタップ時のモーダル
 */
export const SessionFlow: React.FC = () => {
  const { openModal, closeModal, createModal } = useModal();

  return (
    <>
      <UnderLine onClick={openModal}>Spreadyの「セッション」とは</UnderLine>
      {createModal(
        <ModalContainer>
          <Close>
            <CloseIcon style={{ width: 24, height: 24 }} onClick={closeModal} />
          </Close>
          <ModalContent>
            <Top>
              <Only pc>
                <img src={TitleServiceImg} alt="話すことセッションから、" />
                <img src={TitleService2Img} alt="未来は広がる" />
              </Only>
              <Only sp>
                <img src={TitleServiceSpImg} alt="話すことセッションから、未来は広がる" />
              </Only>
            </Top>
            <FlowUl>
              <FlowLi>
                <img
                  src={TextService1Img}
                  loading="lazy"
                  alt="企業担当者:セッションオーナー 事業の協力者を探している"
                />
              </FlowLi>
              <FlowLi>
                <img
                  src={TextService2Img}
                  loading="lazy"
                  alt="あなた：スプレッダー ゲストを誘って一緒に参加"
                />
              </FlowLi>
              <FlowLi>
                <img
                  src={TextService3Img}
                  loading="lazy"
                  alt="あなたの友人知人：ゲスト セッションを通じて事業に協力"
                />
              </FlowLi>
            </FlowUl>
            <PersonsContainer>
              <img src={ServiceOwnerImg} loading="lazy" />
              <img src={ServiceSpreaderImg} loading="lazy" />
              <img src={ServiceQuestImg} loading="lazy" />
            </PersonsContainer>
            <SessionTxt>
              <p>
                Spreadyは、新しいことに
                <Break />
                ワクワクしたい人たちの
                <br />
                出会いと挑戦をサポートする
                <br />
                コラボレーションプラットフォームです。
                <br />
                さあ、好奇心の赴くままに、
                <br />
                気になるセッションを見つけて
                <Break />
                会いにいきましょう。
              </p>
              <ServiceStatement1 src={ServiceStatement1Img} loading="lazy" />
              <ServiceStatement2 src={ServiceStatement2Img} loading="lazy" />
            </SessionTxt>
            <List>
              <ListItem>
                <ItemImg src={Step01} alt="step1" loading="lazy" />
                <ItemTitle>セッションを見つける</ItemTitle>
                <ItemText>
                  毎週届くセッションをチェック。
                  <br />
                  ピンとくるものを見つけたら…
                </ItemText>
              </ListItem>
              <ListItem>
                <ItemImg src={Step02} alt="step2" loading="lazy" />
                <ItemTitle>エントリーする</ItemTitle>
                <ItemText>
                  条件に該当する友人知人を誘ってみましょう。
                  <br />
                  興味を持ってくれたら一緒にエントリー！
                  <br />
                  <span>条件に該当する人と一緒に参加することで、</span>
                  <br />
                  <span>自分には経験や知見のないジャンルの</span>
                  <br />
                  <span>セッションにも参加できる！</span>
                </ItemText>
              </ListItem>
              <ListItem>
                <ItemImg src={Step03} alt="step3" loading="lazy" />
                <ItemTitle>
                  マッチングしたら、
                  <br />
                  セッション成立
                </ItemTitle>
                <ItemText>新しいものを生み出す会話や体験を楽しんで。</ItemText>
              </ListItem>
            </List>
            <BtmImg />
            <SpreadyBottom>
              <Button
                button_type="dark"
                fontSize="14px"
                padding="8px 4px"
                onClick={() => window.open('/#service')}
              >
                <span>Spreadyとは</span>
                <LaunchIcon />
              </Button>
            </SpreadyBottom>
            <CloseIconBottom onClick={closeModal}>
              <CloseIcon style={{ width: 50, height: 50, cursor: 'pointer' }} />
              閉じる
            </CloseIconBottom>
          </ModalContent>
        </ModalContainer>,
      )}
    </>
  );
};
