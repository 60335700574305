import { Reward, RewardRankName } from './Reward';

export type ExchangedReward = {
  id: number;
  stamp_gift: Reward;
  user_stamp: {
    id: number;
    user_id: number;
    stamp: string;
    stamp_number_of_card: number;
    card_number: number;
    pressed_at: string;
  };
  used_ticket: {
    id: number;
    user_stamp: {
      id: number;
      user_id: number;
      stamp: string;
      stamp_number_of_card: number;
      card_number: number;
      pressed_at: string;
    };
    rank: {
      id: number;
      name: string;
    };
  };
  created_at: string;
};

export const GIFT_TICKET_STATUS = {
  TICKET_STATUS_USED: 'TICKET_STATUS_USED',
  TICKET_STATUS_AVAILABLE: 'TICKET_STATUS_AVAILABLE',
  TICKET_STATUS_BEFORE_EXPIRY_DATE: 'TICKET_STATUS_BEFORE_EXPIRY_DATE',
  TICKET_STATUS_EXPIRED: 'TICKET_STATUS_EXPIRED',
} as const;

export type GiftTicketStatus = (typeof GIFT_TICKET_STATUS)[keyof typeof GIFT_TICKET_STATUS];

export const GIFT_TICKET_STATUS_NAME = {
  [GIFT_TICKET_STATUS.TICKET_STATUS_USED]: '引換済み',
  [GIFT_TICKET_STATUS.TICKET_STATUS_AVAILABLE]: '引換可能',
  [GIFT_TICKET_STATUS.TICKET_STATUS_BEFORE_EXPIRY_DATE]: '期限間近',
  [GIFT_TICKET_STATUS.TICKET_STATUS_EXPIRED]: '期限切れ',
} as const;

type GiftTicketStatusName = (typeof GIFT_TICKET_STATUS_NAME)[keyof typeof GIFT_TICKET_STATUS_NAME];

export class GiftTicketLog {
  constructor(
    public pressed_at: string,
    public card_number: number,
    public stamp_number_of_card: number,
    public with_ticket: boolean,
    public pressed_reason: string,
    public rank_name?: RewardRankName,
    public expired_date?: string,
    public status?: GiftTicketStatus,
    public status_name?: GiftTicketStatusName,
    public exchanged_gift?: string,
  ) {}
}
