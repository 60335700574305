import styled from '@emotion/styled';
import SendIcon from '@mui/icons-material/Send';
import { TextArea } from 'ui/components/user/elements/TextArea/TextArea';
import { Only } from 'ui/components/user/elements/Only/Only';
import { useMessageInput } from './useMessageInput';

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 12px;
  width: 100%;
  padding-top: 10px;
`;

const InputWrapper = styled.div`
  flex: 1;
`;

const Icon = styled.button`
  margin-top: 4px;
  padding: 8px 12px;
  background-color: var(--spr-black);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: opacity 0.2s ease-out;
  &:hover {
    opacity: 0.8;
  }
`;

export interface MessageInputProps {
  roomId: string;
  template?: string;
  disabledText?: string | null;
  sendMessage: (roomId: string, message: string) => Promise<void>;
  onComplete?: () => void;
}

/**
 * メッセージ入力欄
 */
export const MessageInput: React.FC<MessageInputProps> = (props) => {
  const { text, rows, requesting, setText, onFocus, onBlur, onClick } = useMessageInput(props);
  return (
    <Wrapper>
      <InputWrapper>
        <Only pc>
          <TextArea
            value={text}
            placeholder={props.disabledText || 'メッセージを入力してください'}
            rows={4}
            resize="vertical"
            onChange={(e) => setText(e.target.value)}
          />
        </Only>
        <Only sp>
          <TextArea
            value={text}
            placeholder={props.disabledText || 'メッセージを入力してください'}
            rows={rows}
            fontSize="var(--font-large)"
            onChange={(e) => setText(e.target.value)}
            onFocus={onFocus}
            onBlur={onBlur}
          />
        </Only>
      </InputWrapper>
      <Icon
        disabled={requesting || !!props.disabledText}
        aria-label="メッセージ送信"
        onClick={onClick}
      >
        <SendIcon sx={{ color: 'var(--spr-white)' }} />
      </Icon>
    </Wrapper>
  );
};
