import { API_LOCATION_SPREADY } from 'infrastructure/config';
import {
  IPostInput,
  IPostResponse,
} from 'interfaceAdapter/gatewaySchemas/ILineAccountLinkCallbackClient';
import { fetchFixture } from 'utils/fetchFixture';
import fixture from './LineAccountLinkCallback.post.json';
import { ILineAccountLinkCallbackClient } from 'interfaceAdapter/gatewaySchemas/ILineAccountLinkCallbackClient';

export class LineAccountLinkCallbackClient implements ILineAccountLinkCallbackClient {
  async post(input: IPostInput): Promise<IPostResponse> {
    const PATH = `${API_LOCATION_SPREADY}/line/account-link/callback`;
    const headers = {
      'Content-Type': 'application/json',
    };
    const method = 'POST';

    try {
      const response = input.mock
        ? await fetchFixture(fixture)
        : await fetch(`${PATH}`, {
            method,
            headers,
            body: JSON.stringify({ code: input.code, state: input.state }),
            // ローカルの場合はフロントとAPIが別オリジンなのでincludeにしないとCookieの送受信がされない
            credentials: process.env.REACT_APP_IS_LOCAL === 'true' ? 'include' : 'same-origin',
          });

      return response.json();
    } catch {
      throw new Error('処理に失敗しました');
    }
  }
}
