export const MEET_REQUEST_STATUS = {
  WAITING_ACCEPT: 'MEET_REQ_STATUS_WAITING_ACCEPT',
  CONNECTED: 'MEET_REQ_STATUS_CONNECTED',
  EXPIRED: 'MEET_REQ_STATUS_EXPIRED',
  DECLINED: 'MEET_REQ_STATUS_DECLINED',
};

export type MeetRequestStatus = (typeof MEET_REQUEST_STATUS)[keyof typeof MEET_REQUEST_STATUS];

export interface IMeetRequestCompanyUser {
  id: number;
  last_name: string;
  first_name: string;
  icon_image?: string | null;
  status: number;
}

export interface IMeetRequestUser {
  id: number;
  last_name: string;
  first_name: string;
  icon_image?: string | null;
  spreader_name?: string | null;
  company_name?: string | null;
  company_job?: string | null;
}

export interface IMeetRequestProject {
  id: number;
  title: string;
  main_pic: string;
  privacy?: boolean;
}

export interface IMeetRequestTeam {
  id: number | null;
  name: string | null;
  icon_image: string | null;
}

export interface IMeetRequest {
  id: number;
  from_company_user: IMeetRequestCompanyUser;
  to_spreader: IMeetRequestUser;
  project: IMeetRequestProject;
  created_at: Date;
  status: MeetRequestStatus;
  chat_room_id: string | null;
  topic: string;
  attender: string;
  meeting_at?: Date | null;
  survey_url?: string | null;
  contract: {
    team: IMeetRequestTeam;
  };
}

export class MeetRequest implements IMeetRequest {
  constructor(
    public id: number,
    public from_company_user: IMeetRequestCompanyUser,
    public to_spreader: IMeetRequestUser,
    public project: IMeetRequestProject,
    public created_at: Date,
    public status: MeetRequestStatus,
    public chat_room_id: string | null,
    public topic: string,
    public attender: string,
    public contract: {
      team: IMeetRequestTeam;
    },
    public meeting_at?: Date | null,
    public survey_url?: string | null,
  ) {}
}
