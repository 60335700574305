import { ComponentPropsWithoutRef, forwardRef, useState } from 'react';
import styled from '@emotion/styled';
import { ValidationError } from 'ui/components/company/elements/ValidationError/ValidationError';

type Props = ComponentPropsWithoutRef<'textarea'> & {
  toggledHeightOnFocus?: boolean; // focus時に高さを変えるか
  fontSize?: string;
  resize?: React.CSSProperties['resize'];
  max?: number;
  error?: string;
};

const Wrapper = styled.span`
  position: relative;
  display: inline-block;
  width: 100%;
`;

const TextAreaElement = styled.textarea<{ fontSize?: string; resize?: string }>`
  color: rgba(0, 0, 0, 0.87);
  min-height: 57px;
  max-height: 420px;
  width: 100%;
  padding: 8px 10px;
  border: 1px solid var(--gray-light-2);
  border-radius: 4px;
  font-size: ${(props) => props.fontSize || 'var(--font-size--large)'};
  line-height: 1.5;
  resize: ${(props) => props.resize || 'none'};
  &:focus {
    border: 1px solid var(--gray-light-2);
    outline: none;
  }
  &::placeholder {
    color: var(--gray-dark-1);
  }
`;

const Counter = styled.span`
  position: absolute;
  bottom: -36px;
  right: 2px;
`;

const Error = styled.span`
  position: absolute;
  bottom: -12px;
  left: 10px;
  z-index: var(--z-validation-error);
  white-space: nowrap;
`;

/**
 * テキストエリアコンポーネント
 */
export const TextArea = forwardRef<HTMLTextAreaElement, Props>(
  (
    {
      toggledHeightOnFocus = false,
      disabled = false,
      placeholder,
      value,
      fontSize,
      resize,
      rows = 2,
      max,
      error,
      onChange,
      onFocus = () => {},
      onBlur = () => {},
    },
    ref,
  ) => {
    const [focus, setFocus] = useState<boolean>(false);

    // focusしたらtextareaの高さを変える
    const onFocusFunc = (e: React.FocusEvent<HTMLTextAreaElement>) => {
      setFocus(true);
      onFocus(e);
    };

    return (
      <Wrapper>
        <TextAreaElement
          ref={ref}
          value={value}
          rows={toggledHeightOnFocus ? (focus ? rows * 2 : rows) : rows}
          disabled={disabled}
          onChange={onChange}
          onFocus={onFocusFunc}
          onBlur={onBlur}
          fontSize={fontSize}
          resize={resize}
          placeholder={placeholder}
        />
        {max && (
          <Counter>
            {`${value}`.length ?? 0} / {max}
          </Counter>
        )}
        <Error>
          <ValidationError message={error} />
        </Error>
      </Wrapper>
    );
  },
);
TextArea.displayName = 'TextArea';
