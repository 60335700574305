import styled from '@emotion/styled';
import { FC } from 'react';
import { PublicHeader } from 'ui/components/company/features/PublicHeader/PublicHeader';
import { Button } from 'ui/components/user/elements/Button/Button';
import { useLineAccountLinkComplete } from 'ui/pages/User/LineAccountLink/useLineAccountLinkComplete';

const Body = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-items: center;
`;

const Image = styled.img`
  width: 10px;
  margin-right: 5px;
`;

export const LineAccountLinkComplete: FC = () => {
  const { canEnter, completed, lineOfficialAccountId } = useLineAccountLinkComplete();

  return (
    <>
      <PublicHeader title="LINEアカウント連携" />
      <Body>
        {completed ? (
          <>
            <h2>アカウント連携完了</h2>
            <p>LINEアカウントの連携が完了しました!</p>
            <div>
              <BackToLineButton lineOfficialAccountId={lineOfficialAccountId} />
            </div>
          </>
        ) : canEnter ? (
          <>
            <p>
              <Image src="/loading.gif" />
              LINEアカウントの連携中です。
            </p>
          </>
        ) : (
          <>
            <p>
              <Image src="/loading.gif" />
              ログイン状態を確認しています。
            </p>
          </>
        )}
      </Body>
    </>
  );
};

/**
 * LINEに戻るボタン
 * LINEアプリが起動して公式アカウントのトークルームが開く
 */
const BackToLineButton = ({ lineOfficialAccountId }: { lineOfficialAccountId: string }) => {
  const redirectToLineTalkRoom = () => {
    location.href = `https://line.me/R/oaMessage/${encodeURIComponent(lineOfficialAccountId)}`;
  };

  return (
    <Button
      status="primary"
      padding="16px"
      fontSize="15px"
      width="100%"
      onClick={redirectToLineTalkRoom}
    >
      LINEに戻る
    </Button>
  );
};
