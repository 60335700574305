import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { authContext } from 'application/contexts/useAuth';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { LineAccountLinkCompleteRepository } from 'interfaceAdapter/repositories/LineAccountLinkComplete';

export const useLineAccountLinkComplete = () => {
  const { checkToken, setUrlAfterLogin } = useContext(authContext);
  const diContainer = useContext(diContainerContext);
  const lineAccountLinkCompleteRepository = diContainer.resolve(LineAccountLinkCompleteRepository);
  const navigate = useNavigate();
  const [canEnter, setCanEnter] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [lineOfficialAccountId, setLineOfficialAccountId] = useState('');

  // ログインしてない場合はログイン画面に遷移
  useEffect(() => {
    checkCanEnter();
  }, []);

  // ログイン済みの場合は連携実行
  useEffect(() => {
    if (!canEnter) {
      return;
    }

    complete();
  }, [canEnter]);

  const checkCanEnter = async () => {
    const { pathname } = window.location;

    if (await checkToken(true)) {
      setCanEnter(true);
    } else {
      setUrlAfterLogin(pathname);
      navigate('/login/');
      return;
    }
  };

  const complete = async () => {
    const res = await lineAccountLinkCompleteRepository.complete();
    if (!res.success) {
      navigate('/user/line/account-link/failed', { replace: true });
      return;
    }
    setLineOfficialAccountId(res.lineOfficialAccountId);
    setCompleted(true);
  };

  return { canEnter, completed, lineOfficialAccountId };
};
