import { FC, PropsWithChildren } from 'react';
import styled from '@emotion/styled';
import ButtonImg from 'ui/images/user-lp/icon_button.svg';
import { mq } from 'utils/responsive';

interface ButtonProps {
  bgColor: string;
  fontColor: string;
}

const Button = styled.button<ButtonProps>`
  display: inline-block;
  border-color: rgb(0, 0, 0);
  border-style: solid;
  padding: 10px 55px 10px 30px;
  font-weight: bold;
  cursor: pointer;
  background-image: url(${ButtonImg});
  background-size: 24px;
  background-position: right 20px center;
  background-repeat: no-repeat;
  transition: transform 0.1s, box-shadow 0.1s;
  background-color: ${(props: { bgColor: string }) => {
    switch (props.bgColor) {
      case 'red':
        return `var(--spr-red)`;
      case 'white':
        return `var(--spr-white)`;
      default:
        return props.bgColor;
    }
  }};
  color: ${(props: { fontColor: string }) => {
    switch (props.fontColor) {
      case 'white':
        return `var(--spr-white)`;
      case 'black':
        return `var(--spr-black)`;
      default:
        return props.fontColor;
    }
  }};
  font-size: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 40px;
  border-width: 4px;
  box-shadow: 0px 4px 0px 0px rgb(0, 0, 0);

  ${mq('NARROW')} {
    width: 100%;
    border-width: 1.06vw;
    border-color: rgb(0, 0, 0);
    border-style: solid;
    border-radius: 10.66vw;
    box-shadow: 0px 1.066vw 0px 0px rgb(0, 0, 0);
    background-size: 6.13vw;
    background-position: right 4vw center;
    padding: 5.86vw 8vw 5.86vw 0;
    font-size: 2vw;

    @supports (width: 100svw) {
      border-width: 0.6svw;
      border-radius: 10.66svw;
      box-shadow: 0px 0.6svw 0px 0px rgb(0, 0, 0);
      background-size: 2.4svw;
      background-position: right 1svw center;
      padding: 2.86svw 4svw 2.86svw 2svw;
      font-size: 2svw;
    }
  }

  ${mq('SP')} {
    width: 100%;
    border-width: 0.6vw;
    border-color: rgb(0, 0, 0);
    border-style: solid;
    border-radius: 10.66vw;
    box-shadow: 0px 0.6vw 0px 0px rgb(0, 0, 0);
    background-size: 3.8vw;
    background-position: right 1.5vw center;
    padding: 3.6vw 6vw 3.6vw 1.6vw;
    font-size: 3.8vw;

    @supports (width: 100svw) {
      border-width: 0.6svw;
      border-radius: 10.66svw;
      box-shadow: 0px 0.6svw 0px 0px rgb(0, 0, 0);
      background-size: 3.8svw;
      background-position: right 1.5svw center;
      padding: 3.6svw 6svw 3.6svw 1.6svw;
      font-size: 3.8svw;
    }
  }
`;

interface Props {
  children: React.ReactNode;
  handler: () => void;
  bgColor?: string;
  fontColor?: string;
}

export const ShadowButton: FC<PropsWithChildren<Props>> = (props) => {
  const { children, handler, bgColor, fontColor } = props;

  return (
    <Button bgColor={bgColor ?? 'red'} fontColor={fontColor ?? 'white'} onClick={handler}>
      {children}
    </Button>
  );
};
