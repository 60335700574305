import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import yup from 'utils/yup';
import { useForm } from 'react-hook-form';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { CompanySearchResult } from 'domain/entities/CompanySearchResult/CompanySearchResult';
import { NewTeamRepository } from 'interfaceAdapter/repositories/NewTeam';

export const formSchema = yup.object().shape({
  name: yup.string().required(),
  corporate_number: yup.string().required(),
  zipcode: yup.string().required(),
  address: yup.string().required(),
});
type Form = yup.InferType<typeof formSchema>;

export const useTeamCreateCorpSelect = () => {
  const navigate = useNavigate();
  const diContainer = useContext(diContainerContext);
  const teamCreateRepository = diContainer.resolve(NewTeamRepository);

  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<Form>({
    resolver: yupResolver(formSchema),
    mode: 'onChange',
  });

  const onCompanySelect = (company: CompanySearchResult) => {
    setValue('name', company.name);
    setValue('corporate_number', company.number);
    setValue('zipcode', company.zip_code);
    setValue('address', company.address);
  };

  const onSubmit = handleSubmit((data) => {
    teamCreateRepository.setCompanyInfo(data);
    navigate('/workgroup/create/profile/');
  });

  return {
    control,
    errors,
    onCompanySelect,
    onSubmit,
  };
};
