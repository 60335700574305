import CompanyContextConfig from 'CompanyContextConfig';
import PublicPageContextConfig from 'PublicPageContextConfig';
import UserContextConfig from 'UserContextConfig';
import { FC } from 'react';
import { Navigate, RouterProvider, createBrowserRouter, useParams } from 'react-router-dom';
import { CompanyStandard } from 'ui/components/company/layouts/CompanyStandard/CompanyStandard';
import { TeamUrlHashStandard } from 'ui/components/company/layouts/CompanyStandard/TeamUrlHashStandard';
import CompanyPasswordReset from 'ui/components/company/layouts/PasswordReset/PasswordReset';
import { PublicPage } from 'ui/components/publicPages/layouts/PublicPage/PublicPage';
import { MyPage } from 'ui/components/user/layouts/MyPage/MyPage';
import { UserStandard } from 'ui/components/user/layouts/UserStandard/UserStandard';
import { SupportChat } from 'ui/components/user/patterns/SupportChat/SupportChat';
import { CompanyCastings } from 'ui/pages/Company/Castings/CompanyCastings';
import CompanyChat from 'ui/pages/Company/Chat/CompanyChat';
import { CompanyContract } from 'ui/pages/Company/Contract/CompanyContract';
import { CompanyDashboard } from 'ui/pages/Company/Dashboard/CompanyDashboard';
import { CompanyEdit } from 'ui/pages/Company/Edit/CompanyEdit';
import { TeamSwitchPage } from 'ui/pages/Company/Team/TeamSwitch';
import { CurrentWorkGroupPage } from 'ui/pages/Company/Team/CurrentWorkGroup';
import { TeamCreateCorpSelectPage } from 'ui/pages/Company/TeamCreate/TeamCreateCorpSelect';
import { TeamProfileSetupPage } from 'ui/pages/Company/TeamCreate/TeamProfileSetup';
import { TeamCreateConfirmPage } from 'ui/pages/Company/TeamCreate/TeamCreateConfirm';
import { CompanyLogin } from 'ui/pages/Company/Login/CompanyLogin';
import { CompanyMeetRequest } from 'ui/pages/Company/MeetRequest/CompanyMeetRequest';
import CompanyDraftProjectComplete from 'ui/pages/Company/Project/CompanyDraftProjectComplete';
import CompanyProjectComplete from 'ui/pages/Company/Project/CompanyProjectComplete';
import CompanyProjectList from 'ui/pages/Company/Project/CompanyProjectList';
import CompanyProjectPrepare from 'ui/pages/Company/Project/CompanyProjectPrepare';
import CompanyShareDisableProjectComplete from 'ui/pages/Company/Project/CompanyShareDisableProjectComplete';
import EditProject from 'ui/pages/Company/Session/EditProject/EditProject';
import { SendEmail } from 'ui/pages/Company/Register/SendEmail/SendEmail';
import CompanyUserSignUp from 'ui/pages/Company/Register/CompanyUserSignUp';
import CompanyUserRegisterProfile from 'ui/pages/Company/Register/CompanyUserRegisterProfile';
import RegisterCompanyProfile from 'ui/pages/Company/Register/RegisterCompanyProfile';
import CompanyProfileConfirm from 'ui/pages/Company/Register/CompanyProfileConfirm';
import { CompanySupport } from 'ui/pages/Company/Support/CompanySupport';
import { CompanyMeetRequestSurvey } from 'ui/pages/Company/Survey/CompanyMeetRequestSurvey';
import { IntroductionCompanySurvey } from 'ui/pages/Company/Survey/IntroductionCompanySurvey';
import CompanyUserEdit from 'ui/pages/Company/Users/CompanyUserEdit';
import CompanyUserList from 'ui/pages/Company/Users/CompanyUserList';
import { CompanyTerms } from 'ui/pages/CompanyApplication/CompanyTerms';
import { CommerceDisclosure } from 'ui/pages/Info/CommerceDisclosure/CommerceDisclosure';
import { Policy } from 'ui/pages/Info/Policy/Policy';
import { Terms } from 'ui/pages/Info/Terms/Terms';
import { NotFound } from 'ui/pages/NotFound/NotFound';
import { PublicCast } from 'ui/pages/PublicCast/PublicCast';
import { PublicProject } from 'ui/pages/PublicProject/PublicProject';
import { PublicRewards } from 'ui/pages/PublicRewards/PublicRewards';
import { Castings } from 'ui/pages/User/Castings/Castings';
import { Chat } from 'ui/pages/User/Chat/Chat';
import { EditFacebook } from 'ui/pages/User/Edit/Facebook';
import EmailChangeConfirm from 'ui/pages/User/EmailChangeConfirm';
import { GiftTickets } from 'ui/pages/User/GiftTickets/GiftTickets';
import { Gifts } from 'ui/pages/User/Gifts/Gifts';
import { Home } from 'ui/pages/User/Home/Home';
import { LoginFacebook } from 'ui/pages/User/Login/Facebook/Facebook';
import { Login } from 'ui/pages/User/Login/Login';
import { Meets } from 'ui/pages/User/Meets/Meets';
import PasswordReset from 'ui/pages/User/PasswordReset/PasswordReset';
import { PointLog } from 'ui/pages/User/Point/PointLog/PointLog';
import { PointRanking } from 'ui/pages/User/Point/Ranking/Ranking';
import Questionnaires from 'ui/pages/User/Questionnaires/Questionnaires';
import { EmailVerify } from 'ui/pages/User/Register/EmailVerify';
import { Register } from 'ui/pages/User/Register/Register';
import { ShipInfo } from 'ui/pages/User/ShipInfo/ShipInfo';
import { IntroductionUserSurvey } from 'ui/pages/User/Survey/IntroductionUserSurvey';
import { UserMeetRequestSurvey } from 'ui/pages/User/Survey/UserMeetRequestSurvey';
import { Top } from 'ui/pages/User/Top/Top';
import { Withdrawal } from 'ui/pages/User/Withdrawal/Withdrawal';

import { AddCompanyBillingInfo } from 'ui/pages/Company/Billing/AddCompanyBillingInfo';
import { CompanyBillingInfo } from 'ui/pages/Company/Billing/CompanyBillingInfo';
import { CompanyBillingList } from 'ui/pages/Company/Billing/CompanyBillingList';
import CompanyProjectCheckoutSuccess from 'ui/pages/Company/Project/CompanyProjectCheckoutSuccess';
import { ClosingSoonProjects } from 'ui/pages/User/ClosingSoonProjects/ClosingSoonProjects';
import { MyIntroducedProjects } from 'ui/pages/User/MyIntroducedProjects/MyIntroducedProjects';
import { Project } from 'ui/pages/User/Project/Project';
import { ProjectReviews } from 'ui/pages/User/ProjectReviews/ProjectReviews';
import { RecommendProjects } from 'ui/pages/User/RecommendProjects/RecommendProjects';
import { SearchProjects } from 'ui/pages/User/SearchProjects/SearchProjects';
import { WantedProjects } from 'ui/pages/User/WantedProjects/WantedProjects';
import CompanyUserRegisterComplete from 'ui/pages/Company/Register/CompanyUserRegisterComplete';
import CompanyUserRegisterFromInviteComplete from 'ui/pages/Company/Register/CompanyUserRegisterFromInviteComplete';
import { Invite } from 'ui/pages/Company/Register/Invite';
import InvitedCompanyUserSignUp from './ui/pages/Company/Register/InvitedCompanyUserSignUp';
import GeneratedDraft from './ui/pages/Company/Session/GeneratedDraft/GeneratedDraft';
import BlankDraft from './ui/pages/Company/Session/BlankDraft/BlankDraft';
import DuplicatedDraft from './ui/pages/Company/Session/DuplicatedDraft/DuplicatedDraft';
import EditDraft from './ui/pages/Company/Session/EditDraft/EditDraft';
import { LineAccountLinkRequest } from 'ui/pages/User/LineAccountLink/LineAccountLinkRequest';
import { LineAccountLinkCallback } from 'ui/pages/User/LineAccountLink/LineAccountLinkCallback';
import { LineAccountLinkComplete } from 'ui/pages/User/LineAccountLink/LineAccountLinkComplete';
import { LineAccountLinkFailed } from 'ui/pages/User/LineAccountLink/LineAccountLinkFailed';

// 後方互換性維持のため非ログインで表示できるURLを {prefix}/:id/{suffix} のURLにリダイレクトする
const RedirectWithParam = (arg: { prefix?: string; suffix?: string }) => {
  const { prefix, suffix } = arg;
  // リダイレクト元のURL中のパラメータは :id 固定で取り出すので注意
  const { id } = useParams();
  const to = `/${[prefix, id, suffix].filter(Boolean).join('/')}/`;
  return <Navigate to={to} replace />;
};

const router = createBrowserRouter([
  /** 公開ページ */
  {
    element: <PublicPageContextConfig />,
    children: [
      { path: '/projects/:projectId/', element: <PublicProject /> },
      {
        element: <PublicPage />,
        children: [
          { path: '/rewards/', element: <PublicRewards /> },
          { path: '/info/terms/', element: <Terms /> },
          { path: '/info/policy/', element: <Policy /> },
          { path: '/info/commerce-disclosure/', element: <CommerceDisclosure /> },
          { path: '/company-application/terms/', element: <CompanyTerms /> },
        ],
      },
    ],
  },

  /** トップページ */
  { path: '/', element: <Top /> },

  /** LINEアカウント連携 */
  { path: '/user/line/account-link/request', element: <LineAccountLinkRequest /> },
  { path: '/user/line/account-link/callback', element: <LineAccountLinkCallback /> },
  { path: '/user/line/account-link/failed', element: <LineAccountLinkFailed /> },

  /** ユーザー系 */
  {
    element: <UserContextConfig />,
    children: [
      /** 認証系 */
      { path: '/register/', element: <Register /> },
      { path: '/register/verify-email/', element: <EmailVerify /> },
      { path: '/login/', element: <Login /> },
      { path: '/login/facebook/', element: <LoginFacebook /> },
      { path: '/email-change-confirm/', element: <EmailChangeConfirm /> },
      { path: '/password-reset/', element: <PasswordReset /> },
      { path: '/user/line/account-link/complete', element: <LineAccountLinkComplete /> },
      { path: '/cast/:castCode/', element: <PublicCast /> },
      {
        path: '/survey/user/introduction/answer/:surveyCode/',
        element: <IntroductionUserSurvey />,
      },
      {
        path: '/survey/meet-request/user/answer/:meetRequestId/',
        element: <UserMeetRequestSurvey />,
      },
      /** ログイン後 */
      {
        element: <UserStandard />,
        children: [
          { path: '/user/', element: <Home /> },
          { path: '/user/project/:projectId/', element: <Project /> },
          { path: '/user/kw/', element: <SearchProjects /> },
          { path: '/user/meets/', element: <Meets /> },
          { path: '/user/casting/', element: <Castings key="/user/casting/" /> },
          { path: '/user/casting/casted/', element: <Castings key="/user/casting/casted/" /> },
          { path: '/user/chat/rooms/', element: <Chat /> },
          { path: '/user/chat/rooms/:roomId/', element: <Chat /> },
          { path: '/user/chat/support/', element: <SupportChat /> },
          { path: '/user/gifts/', element: <Gifts /> },
          { path: '/user/gifts/delivery/', element: <Navigate to="/user/ship_info/" replace /> },
          { path: '/user/ship_info/', element: <ShipInfo /> },
          { path: '/user/gift_tickets/', element: <GiftTickets /> },
          { path: '/user/mypage/', element: <MyPage /> },
          { path: '/user/questionnaires/', element: <Questionnaires /> },
          { path: '/user/edit/', element: <Navigate to="/user/mypage/?profile=1" replace /> },
          { path: '/user/edit/facebook/', element: <EditFacebook /> },
          { path: '/user/point/ranking/', element: <PointRanking /> },
          { path: '/user/point/log/', element: <PointLog /> },
          { path: '/user/withdrawal/', element: <Withdrawal /> },
          { path: '/user/projects/recommend/', element: <RecommendProjects /> },
          { path: '/user/projects/wanted/', element: <WantedProjects /> },
          { path: '/user/projects/introduced/', element: <MyIntroducedProjects /> },
          { path: '/user/projects/reviews/', element: <ProjectReviews /> },
          { path: '/user/projects/closing/', element: <ClosingSoonProjects /> },
        ],
      },
    ],
  },

  /** ビジネス系 business */
  {
    element: <CompanyContextConfig />,
    children: [
      /** 認証系 */
      { path: '/business/', element: <CompanyLogin /> },
      { path: '/business/password-reset/', element: <CompanyPasswordReset /> },
      { path: '/business/register/email/', element: <SendEmail /> },
      { path: '/business/register/auth/:url_id/', element: <CompanyUserSignUp /> },
      { path: '/business/invitation/auth/:url_id/', element: <InvitedCompanyUserSignUp /> },
      {
        path: '/survey/business/introduction/answer/:surveyCode/',
        element: <IntroductionCompanySurvey />,
      },
      {
        path: '/survey/meet-request/business/answer/:meetRequestId/',
        element: <CompanyMeetRequestSurvey />,
      },
      {
        path: '/business/register/user/profile/',
        element: <CompanyUserRegisterProfile />,
      },
      {
        path: '/business/register/workgroup/profile/',
        element: <RegisterCompanyProfile />,
      },
      {
        path: '/business/register/confirm_profile/',
        element: <CompanyProfileConfirm />,
      },
      {
        path: '/business/registration/complete/',
        element: <CompanyUserRegisterComplete />,
      },
      {
        path: '/business/registration/from_invite/complete/',
        element: <CompanyUserRegisterFromInviteComplete />,
      },

      /** 認証済み workgroup */
      {
        element: <CompanyStandard />,
        children: [
          { path: '/workgroup/switch/', element: <TeamSwitchPage /> },
          { path: '/workgroup/create/', element: <TeamCreateCorpSelectPage /> },
          { path: '/workgroup/create/profile/', element: <TeamProfileSetupPage /> },
          { path: '/workgroup/create/confirm/', element: <TeamCreateConfirmPage /> },
          { path: '/current/workgroup/', element: <CurrentWorkGroupPage /> },

          /** ワークグループ所属済み workgroup */
          {
            element: <TeamUrlHashStandard />,
            children: [
              { path: '/workgroup/*', element: null }, // ここで workgroup/ へのリクエストを受け付け、 useCompanyStandard で urhHash を付与させる
              { path: '/workgroup/:urlHash/', element: <CompanyDashboard /> },
              { path: '/workgroup/:urlHash/users/', element: <CompanyUserList /> },
              { path: '/workgroup/:urlHash/users/edit/:userId/', element: <CompanyUserEdit /> },
              { path: '/workgroup/:urlHash/users/invite/', element: <Invite /> },
              { path: '/workgroup/:urlHash/projects/', element: <CompanyProjectList /> },
              { path: '/workgroup/:urlHash/projects/edit/:projectId/', element: <EditProject /> },
              { path: '/workgroup/:urlHash/projects/prepare/', element: <CompanyProjectPrepare /> },
              {
                path: '/workgroup/:urlHash/projects/create/assets/:assetsId/',
                element: <GeneratedDraft />,
              }, // 生成アセット利用によるセッションの新規作成(下書き)
              {
                path: '/workgroup/:urlHash/projects/create/',
                element: <BlankDraft />,
              }, // 空の状態でのセッションの新規作成(下書き)
              {
                path: '/workgroup/:urlHash/projects/draft/duplicate_from/:projectId/',
                element: <DuplicatedDraft />,
              }, // 既存セッションを複製して下書き
              {
                path: '/workgroup/:urlHash/projects/draft/edit/:draftId/',
                element: <EditDraft />,
              }, // 下書きの編集
              {
                path: '/workgroup/:urlHash/projects/complete/:projectId/',
                element: <CompanyProjectComplete />,
              },
              {
                path: '/workgroup/:urlHash/projects/draft/complete/',
                element: <CompanyDraftProjectComplete />,
              },
              {
                path: '/workgroup/:urlHash/projects/share-disable/complete/:projectId/',
                element: <CompanyShareDisableProjectComplete />,
              },
              {
                path: '/workgroup/:urlHash/projects/:projectId/checkout/success/',
                element: <CompanyProjectCheckoutSuccess />,
              },
              { path: '/workgroup/:urlHash/chat/support/', element: <CompanySupport /> },
              { path: '/workgroup/:urlHash/chat/support/:roomId/', element: <CompanySupport /> },
              { path: '/workgroup/:urlHash/chat/room/:roomId/', element: <CompanyChat /> },
              { path: '/workgroup/:urlHash/chat/:roomId/', element: <CompanyChat /> },
              { path: '/workgroup/:urlHash/castings/', element: <CompanyCastings /> },
              { path: '/workgroup/:urlHash/edit/', element: <CompanyEdit /> },
              { path: '/workgroup/:urlHash/contract/', element: <CompanyContract /> },
              { path: '/workgroup/:urlHash/meet/', element: <CompanyMeetRequest /> },
              { path: '/workgroup/:urlHash/billing-info/', element: <CompanyBillingInfo /> },
              { path: '/workgroup/:urlHash/billing-info/new/', element: <AddCompanyBillingInfo /> },
              { path: '/workgroup/:urlHash/payments/', element: <CompanyBillingList /> },
              { path: '/workgroup/:urlHash/*', element: null }, // どれにも当てはまらないURLが useCompanyStandard によって無限リダイレクトされてしまうのを防ぐ
            ],
          },
        ],
      },
    ],
  },

  /** 旧企業 company */
  {
    element: <CompanyContextConfig />,
    children: [
      /** 認証系 */
      { path: '/company/', element: <Navigate to="/business/" replace /> },
      {
        path: '/company/password-reset/',
        element: <Navigate to="/business/password-reset/" replace />,
      },
      {
        path: '/company/register/email/',
        element: <Navigate to="/business/register/email/" replace />,
      },
      {
        path: '/company/register/auth/:id/',
        element: <RedirectWithParam prefix="business/register/auth" />,
      },
      {
        path: '/company/main/users/invite/',
        element: <Navigate to="/workgroup/users/invite/" replace />,
      },
      {
        path: '/company/invitation/auth/:id/',
        element: <RedirectWithParam prefix="business/invitation/auth" />,
      },
      {
        path: '/survey/company/introduction/answer/:id/',
        element: <RedirectWithParam prefix="survey/business/introduction/answer" />,
      },
      {
        path: '/survey/meet-request/company/answer/:id/',
        element: <RedirectWithParam prefix="survey/meet-request/business/answer" />,
      },
      {
        path: '/company/register/user/profile/',
        element: <Navigate to="/business/register/user/profile/" replace />,
      },
      {
        path: '/company/register/company/profile/',
        element: <Navigate to="/business/register/workgroup/profile/" replace />,
      },
      {
        path: '/company/register/confirm_profile/',
        element: <Navigate to="/business/register/confirm_profile/" replace />,
      },
      {
        path: '/company/registration/complete/',
        element: <Navigate to="/business/registration/complete/" replace />,
      },

      /** ログイン後 */
      {
        element: <CompanyStandard />,
        children: [
          { path: '/company/main/', element: <Navigate to="/workgroup/" replace /> },
          { path: '/company/main/users/', element: <Navigate to="/workgroup/users/" replace /> },
          {
            path: '/company/main/users/edit/:id/',
            element: <Navigate to="/workgroup/users/edit/:id/" replace />,
          },
          {
            path: '/company/main/projects/',
            element: <Navigate to="/workgroup/projects/" replace />,
          },
          {
            path: '/company/main/projects/prepare/',
            element: <Navigate to="/workgroup/projects/prepare/" replace />,
          },
          {
            path: '/company/main/projects/create/assets/:id/',
            element: <Navigate to="/workgroup/projects/create/assets/:id/" replace />,
          }, // 生成アセット利用 セッションの新規作成
          {
            path: '/company/main/projects/create/',
            element: <Navigate to="/workgroup/projects/create/" replace />,
          }, // 空の状態でのセッションの新規作成
          {
            path: '/company/main/projects/create/:id/',
            element: <Navigate to="/workgroup/projects/draft/duplicate_from/:id/" replace />,
          }, // セッションの複製
          {
            path: '/company/main/projects/edit/:id/',
            element: <Navigate to="/workgroup/projects/edit/:id/" replace />,
          },
          {
            path: '/company/main/draft/projects/edit/:id/',
            element: <Navigate to="/workgroup/projects/draft/edit/:id/" replace />,
          },
          {
            path: '/company/main/projects/complete/:id/',
            element: <Navigate to="/workgroup/projects/complete/:id/" replace />,
          },
          {
            path: '/company/main/projects/draft/complete/',
            element: <Navigate to="/workgroup/projects/draft/complete/" replace />,
          },
          {
            path: '/company/main/projects/share-disable/complete/:id/',
            element: <Navigate to="/workgroup/projects/share-disable/complete/:id/" replace />,
          },
          {
            path: '/company/projects/:id/checkout/success',
            element: (
              <Navigate
                to="/workgroup/projects/share-disable/complete/:id/checkout/success"
                replace
              />
            ),
          },
          {
            path: '/company/main/chat/support/',
            element: <Navigate to="/workgroup/chat/support/" replace />,
          },
          {
            path: '/company/main/chat/support/:id/',
            element: <RedirectWithParam prefix="workgroup/chat/support" />,
          },
          {
            path: '/company/main/chat/room/:id/',
            element: <RedirectWithParam prefix="workgroup/chat/room" />,
          },
          {
            path: '/company/main/chat/:id/',
            element: <RedirectWithParam prefix="workgroup/chat" />,
          },
          {
            path: '/company/main/castings/',
            element: <Navigate to="/workgroup/castings/" replace />,
          },
          { path: '/company/main/edit/', element: <Navigate to="/workgroup/edit/" replace /> },
          { path: '/company/main/switch/', element: <Navigate to="/workgroup/switch/" replace /> },
          {
            path: '/company/main/contract/',
            element: <Navigate to="/workgroup/contract/" replace />,
          },
          { path: '/company/main/meet/', element: <Navigate to="/workgroup/meet/" replace /> },
          {
            path: '/company/billing-info/',
            element: <Navigate to="/workgroup/billing-info/" replace />,
          },
          {
            path: '/company/billing-info/new',
            element: <Navigate to="/workgroup/billing-info/new/" replace />,
          },
          { path: '/company/payments', element: <Navigate to="/workgroup/payments/" replace /> },
        ],
      },
    ],
  },

  { path: '*', element: <Navigate to="/not_found/" replace /> },
  { path: '/not_found/', element: <NotFound /> },
]);

const RouterConfig: FC = () => <RouterProvider router={router} />;

export default RouterConfig;
