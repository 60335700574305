import styled from '@emotion/styled';
import { PropsWithChildren } from 'react';
import { mqUser } from 'utils/responsive';

const Button = styled.button<
  Partial<Props> & {
    textColor: string;
    borderColor: string;
  }
>`
  display: block;
  padding: 22px 110px;
  border: 1px solid ${(props) => `var(--${props.borderColor})`};
  outline: none;
  border-radius: 60px;
  background-color: ${(props) => `var(--${props.bgColor})`};
  font-size: var(--font-xx-large);
  font-weight: var(--font-bold);
  color: var(--${(props) => props.textColor});
  white-space: nowrap;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  opacity: ${(props) => (props.disabled ? 0.2 : 1)};

  &:not(:disabled):hover {
    opacity: 0.7;
  }

  ${mqUser('SP')} {
    width: 100% !important;
    padding: 16px 20px;
  }
`;

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  bgColor: string;
  fontColor?: string;
}

export const ActionButton: React.FC<PropsWithChildren<Props>> = ({
  disabled,
  fontColor,
  style,
  bgColor,
  children,
  onClick,
}) => {
  const textColor = fontColor ? fontColor : bgColor === 'white' ? 'gray-dark-4' : 'white';
  const borderColor = bgColor === 'white' || bgColor === 'warn-paler' ? 'gray' : 'transparent';
  return (
    <Button
      type="button"
      disabled={disabled}
      bgColor={bgColor}
      textColor={textColor}
      borderColor={borderColor}
      style={style}
      onClick={onClick}
    >
      {children}
    </Button>
  );
};
