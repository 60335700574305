import * as yup from 'yup';
import { OptionPlan } from 'domain/valueObjects/companyApplication/companyApplication';
import { COMPANY_STATUS, Company, ICompany } from 'domain/entities/Company/Company';

export const companySchema = yup
  .object()
  .shape({
    id: yup.number().required(),
    name: yup.string().required(),
    zipcode: yup.string().optional(),
    address: yup.string().required(),
    capital: yup.number().default(null).nullable().optional(),
    employee: yup.number().default(null).nullable().optional(),
    icon_image: yup.string().nullable().defined(),
    president_name: yup.string().default(null).nullable().optional(),
    foundation_at: yup.date().nullable().optional(),
    url: yup.string().required(),
    status: yup.string().oneOf(Object.values(COMPANY_STATUS)).required(),
    url_hash: yup.string().nullable(),
    herp_webhook_url: yup.string().nullable().optional(),
    background_pic: yup.string().optional(),
    pics: yup.array().of(yup.string().required()).optional(),
    about_company: yup.string().optional().nullable(),
    url_ogps: yup
      .array()
      .of(
        yup.object().shape({
          image: yup.string().nullable().defined(),
          url: yup.string().required(),
          title: yup.string().nullable().defined(),
        }),
      )
      .optional(),
    nps_score: yup.number().optional(),
    slack_webhook_url: yup.string().optional(),
    logo_display_enable: yup.boolean().optional(),
    logo_display_pushed: yup.boolean().optional(),
    search_words: yup.string().optional().nullable(),
    copy: yup.string().optional().nullable(),
    corporate_number: yup.string().optional().nullable(),
    contract_start_at: yup.date().optional(),
    contract_status: yup.number().optional(),
    plan: yup
      .object()
      .shape({
        id: yup.number().optional(),
        key: yup.string().optional(),
        display_name: yup.string().optional(),
        initial_cost: yup.number().optional(),
        price: yup.number().optional(),
        number_of_published_projects: yup.number().optional(),
      })
      .optional(),
    contract_period: yup
      .object()
      .shape({
        start_date: yup
          .date()
          .nullable()
          .optional()
          .default(null)
          .transform((current, original) => (original ? new Date(original) : current)),
        end_date: yup
          .date()
          .nullable()
          .optional()
          .default(null)
          .transform((current, original) => (original ? new Date(original) : current)),
      })
      .optional(),
  })
  .required();

export type ICompanySchema = yup.InferType<typeof companySchema>;

export interface IEditCompanySchema {
  name: string;
  copy: string | null;
  address: string;
  zipcode: string;
  url: string;
  president_name: string | null;
  capital: number | null;
  employee: number | null;
  about_company: string | null;
  icon_image: string;
  logo_display_enable: boolean;
  herp_webhook_url: string | null;
  pics?: string[] | [];
  urls?: string[] | [];
  slack_webhook_url: string | null;
}

export interface IPrivacyTeamSchema {
  name: null;
  copy: null;
  address: null;
  zipcode: null;
  url: null;
  president_name: null;
  capital: null;
  employee: null;
  about_company: null;
  icon_image: null;
  herp_webhook_url: null;
  pics?: null;
  urls?: null;
  slack_webhook_url: null;
  status: COMPANY_STATUS;
  url_hash: string | null;
}

export interface ICompanyFactory {
  buildEmpty(): ICompany;
  build(input: ICompanySchema): ICompany;
}

export class CompanyFactory implements ICompanyFactory {
  public buildEmpty() {
    return new Company(
      0,
      '',
      '',
      null,
      null,
      '',
      null,
      new Date(),
      '',
      COMPANY_STATUS.ACTIVE,
      null,
    );
  }

  public build(input: ICompanySchema) {
    return new Company(
      input.id,
      input.name,
      input.address,
      input.capital,
      input.employee,
      input.icon_image ?? '',
      input.president_name,
      new Date(input.foundation_at ?? ''),
      input.url,
      input.status,
      input.url_hash ?? '',
      input.zipcode,
      input.herp_webhook_url,
      input.background_pic,
      input.pics,
      input.about_company,
      input.url_ogps,
      input.nps_score,
      input.slack_webhook_url,
      input.logo_display_enable,
      input.logo_display_pushed,
      input.search_words,
      input.copy,
      input.contract_start_at ? new Date(input.contract_start_at) : undefined,
      input.contract_status,
      input.plan ? OptionPlan.fromJson(input.plan) : undefined,
      input.corporate_number === null ? undefined : input.corporate_number,
      input.contract_period
        ? {
            start_date:
              input.contract_period.start_date && new Date(input.contract_period.start_date),
            end_date: input.contract_period.end_date && new Date(input.contract_period.end_date),
          }
        : undefined,
    );
  }

  public buildPrivacy(input: IPrivacyTeamSchema) {
    return new Company(
      0,
      '',
      '',
      null,
      null,
      '',
      null,
      new Date(),
      '',
      input.status,
      input.url_hash,
    );
  }
}
