import { useEffect } from 'react';
import { SimpleHeader } from 'ui/components/company/panes/Header/SimpleHeader';
import { Controller } from 'react-hook-form';
import styled from '@emotion/styled';
import { FormSectionTitle } from 'ui/components/company/elements/FormSectionTitle/FormSectionTitle';
import { FormError } from 'ui/components/company/elements/FormError/FormError';
import { FormInput } from 'ui/components/company/elements/FormInput/FormInput';
import { ActionButton } from 'ui/components/company/elements/ActionButton/ActionButton';
import { ForgotPassword } from 'ui/components/company/screens/modals/ForgotPassword/ForgotPassword';
import { useLoginForm } from './useLoginForm';
import { InviteProcessing } from 'ui/components/company/panes/Invite/InviteProcessing';
import { Link } from 'react-router-dom';
import { mqUser } from 'utils/responsive';

const Wrapper = styled.div`
  width: 100%;
  height: calc(100vh - 82px);
  @supports (height: 100svh) {
    min-height: calc(100svh - 82px);
  }

  margin-inline: auto;
  padding: 40px;
  background-color: var(--primary);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.div`
  padding-bottom: 40px;
  line-height: 1.5;
  font-size: var(--font-xxxxx-large);
  font-weight: 900;
  ${mqUser('SP')} {
    font-size: var(--font-xx-large);
  }
`;

const SubTitle = styled.div`
  font-size: var(--font-xx-large);
  font-weight: 800;
  line-height: 1.5;
`;

const Text = styled.div`
  font-size: var(--font-large);
  font-weight: 600;
  line-height: 1.5;
  margin-bottom: 10px;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  max-width: 960px;
  margin-inline: auto;
  width: 100%;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  background-color: var(--white);
  margin-inline: auto;
  width: 100%;
  padding: 40px 28px;
  border-radius: 12px;

  .input {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    max-width: 500px;
  }
`;

const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 40px;
`;

const UserLoginLink = styled.div`
  font-size: var(--font-x-large);
  color: var(--gray-dark-3);
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: center;

  a {
    text-decoration: underline;
  }
`;

/**
 * 企業系ログインフォーム
 */
export const LoginForm: React.FC = () => {
  const {
    control,
    errors,
    redirectIfLoggedIn,
    loading,
    isInviteProcessing,
    showNeedRegistrationMessage,
    message,
    login,
    register,
  } = useLoginForm();

  useEffect(() => {
    redirectIfLoggedIn();
  }, []);

  if (isInviteProcessing) {
    return <InviteProcessing />;
  }

  return (
    <>
      <SimpleHeader />
      <Wrapper>
        <Title>ビジネスユーザー</Title>
        <FormContainer>
          {showNeedRegistrationMessage && (
            <Text>ユーザー登録が完了していない場合はログインすると手順の続きから始められます</Text>
          )}
          {message && <Text>{message}</Text>}
          <Form>
            <div className="input">
              <FormSectionTitle title="メールアドレス" titleSize="18px" isRequired />
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <FormInput {...field} placeholder="email" hasError={!!errors.email?.message} />
                )}
              />
              <FormError message={errors.email?.message} />
            </div>
            <div className="input">
              <FormSectionTitle title="パスワード" titleSize="18px" isRequired />
              <Controller
                name="password"
                control={control}
                render={({ field }) => (
                  <FormInput
                    {...field}
                    type="password"
                    placeholder="password"
                    hasError={!!errors.password?.message}
                  />
                )}
              />
              <FormError message={errors.password?.message} />
            </div>
            <span className="forgot">
              <ForgotPassword />
            </span>
            <ActionButton
              bgColor="secondary-dark"
              style={{ margin: '12px auto 0', width: '320px', padding: '20px' }}
              disabled={loading}
              onClick={login}
            >
              ログイン
            </ActionButton>
          </Form>
        </FormContainer>

        <LinkContainer>
          <SubTitle>はじめての方</SubTitle>
          <ActionButton
            bgColor="white"
            style={{
              margin: '20px auto 0',
              width: '320px',
              padding: '20px',
              color: 'var(--accent)',
              border: '1px solid var(--accent)',
            }}
            disabled={loading}
            onClick={register}
          >
            新規ビジネスユーザー登録
          </ActionButton>
        </LinkContainer>

        <LinkContainer>
          <SubTitle>その他</SubTitle>
          <UserLoginLink>
            <Link to={'/login/'}>個人ユーザー用ログイン</Link>
            <Link
              to="https://www.notion.so/spready/Spready-2415ce16287048b99a69106d85dc9337"
              target="_blank"
              rel="noopener noreferrer"
            >
              ご利用ガイド
            </Link>
            <Link
              to="https://tayori.com/faq/dcab4ff9c09fe8195157202caec2b5693f2a8f85"
              target="_blank"
              rel="noopener noreferrer"
            >
              よくあるご質問 FAQ
            </Link>
          </UserLoginLink>
        </LinkContainer>
      </Wrapper>
    </>
  );
};
