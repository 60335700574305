import styled from '@emotion/styled';
import { SimpleHeader } from 'ui/components/company/panes/Header/SimpleHeader';
import { CompanyUserSignUpForm } from 'ui/components/company/panes/CompanyUserSignUp/CompanyUserSignUpForm';
import { AboutService } from 'ui/components/company/features/AboutService/AboutService';
import { mqUser } from 'utils/responsive';

const Body = styled.div`
  width: 100%;
  height: calc(100vh - 82px);
  @supports (height: 100svh) {
    min-height: calc(100svh - 82px);
  }

  margin-inline: auto;
  padding: 20px;
  background-color: var(--primary);
  overflow-y: auto;
`;

const Title = styled.div`
  font-size: var(--font-xxxxx-large);
  font-weight: 600;
  margin-top: 30px;
  margin-bottom: 20px;
  text-align: center;
  ${mqUser('SP')} {
    font-size: var(--font-xx-large);
  }
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 460px);
  column-gap: 20px;
  max-width: 960px;
  margin: 50px auto;

  ${mqUser('SP')} {
    grid-template-columns: minmax(0, 460px);
    place-content: start center;
    row-gap: 20px;

    > div:first-of-type {
      grid-row: 2; // フォームは下側へ
    }
  }
`;

export const CompanyUserSignUp: React.FC = () => {
  return (
    <>
      <SimpleHeader />
      <Body>
        <Title>ビジネスユーザーを作成する</Title>
        <Container>
          <CompanyUserSignUpForm />
          <AboutService />
        </Container>
      </Body>
    </>
  );
};
