export const USER_STATUS = {
  /** メール認証待ち */
  WAITING_MAIL_CONFIRMATION: 0,
  ACTIVE: 4,
  DEACTIVE: 6,
};
type UserStatus = (typeof USER_STATUS)[keyof typeof USER_STATUS];

export const USER_IDENTITY = {
  SPREADER: 1,
  INVITED: 3,
};
export type UserIdentity = (typeof USER_IDENTITY)[keyof typeof USER_IDENTITY];

export interface IUser {
  id: number | null;
  spreader_name: string;
  last_name: string;
  first_name: string;
  email: string;
  password: string | null;
  api_token: string;
  birthday: Date | null;
  icon_image: string;
  identity: UserIdentity;
  status: UserStatus | null;
  company_name: string;
  company_job: string;
  urls: (string | undefined)[];
  mail_news_receive: boolean;
  social_capital_point: number;
  social_capital_ranking: number | null;
  age: number | null;
  profiee_url: string;
  facebook_url: string;
  is_profiee_url_open: boolean;
  is_facebook_url_open: boolean;
  tutorial_finished: boolean;
  is_company_info_open: boolean;
  has_new_stamps: boolean;
  has_new_feedback: boolean;
  industry_segment_parent: string;
  industry_segment_child: string;
  job_segment_parent: string;
  job_segment_child: string;
  links: string[];
  job_news_recieve: boolean;
  past_job_description: string;
  skills: { id: number; name: string }[];
  applying_for_change_email: string | null;
  isSpreader(): boolean;
}

export class User implements IUser {
  constructor(
    public id: number | null,
    public spreader_name: string,
    public last_name: string,
    public first_name: string,
    public email: string,
    public password: string | null,
    public api_token: string,
    public birthday: Date | null,
    public icon_image: string,
    public identity: UserIdentity,
    public status: UserStatus | null,
    public company_name: string,
    public company_job: string,
    public urls: (string | undefined)[],
    public mail_news_receive: boolean,
    public social_capital_point: number,
    public social_capital_ranking: number | null,
    public age: number | null,
    public profiee_url: string,
    public facebook_url: string,
    public is_profiee_url_open: boolean,
    public is_facebook_url_open: boolean,
    public tutorial_finished: boolean,
    public is_company_info_open: boolean,
    public has_new_stamps: boolean,
    public has_new_feedback: boolean,
    public industry_segment_parent: string,
    public industry_segment_child: string,
    public job_segment_parent: string,
    public job_segment_child: string,
    public links: string[],
    public job_news_recieve: boolean,
    public past_job_description: string,
    public skills: { id: number; name: string }[],
    public applying_for_change_email: string | null,
  ) {}

  public isSpreader() {
    return this.identity === USER_IDENTITY.SPREADER;
  }
}
