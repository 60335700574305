import { inject, injectable } from 'tsyringe';
import { type ICompanyUserTokenRepository } from 'application/repositorySchemas/ICompanyUserTokenRepository';
import { type ISupportChatMessageRepository } from 'application/repositorySchemas/ISupportChatMessageRepository';
import { type ISupportChatMessageClient } from 'interfaceAdapter/gatewaySchemas/ISupportChatMessage';
import { type ISupportChatClient } from 'interfaceAdapter/gatewaySchemas/ISupportChatClient';
import { buildResponse, HttpBadRequest, HttpForbidden } from 'domain/types/httpResponse';

@injectable()
export class SupportChatMessageRepository implements ISupportChatMessageRepository {
  constructor(
    @inject('ICompanyUserTokenRepository')
    public readonly companyUserToken: ICompanyUserTokenRepository,
    @inject('ISupportChatMessageClient')
    public readonly supportChatMessageClient: ISupportChatMessageClient,
    @inject('ISupportChatClient')
    public readonly supportChatClient: ISupportChatClient,
  ) {}

  public async send(roomId: string, message: string) {
    const { status, message: responseMessage } = await this.supportChatMessageClient.post({
      token: this.companyUserToken.get() || '',
      roomId,
      message,
    });
    const throwable = buildResponse(status, responseMessage);
    if (throwable instanceof HttpBadRequest || throwable instanceof HttpForbidden) {
      throw throwable;
    }
  }

  public async remove(chatId: number) {
    await this.supportChatClient.delete({ token: this.companyUserToken.get() || '', chatId });
  }
}
