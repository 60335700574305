import { fetchFixture } from 'utils/fetchFixture';
import fixture from './CurrentRewardProgress.get.json';
import { API_LOCATION_SPREADY } from 'infrastructure/config';
import {
  ICurrentRewardProgressClient,
  IGetInput,
  IGetResponse,
} from '../../../interfaceAdapter/gatewaySchemas/ICurrentRewardProgressClient';

const PATH = `${API_LOCATION_SPREADY}/user/stamp_cards`;

export class CurrentRewardProgressClient implements ICurrentRewardProgressClient {
  async get(input: IGetInput): Promise<IGetResponse> {
    const { token, mock = false } = input;
    const method = 'GET';
    const headers = {
      'Content-Type': 'application/json',
      'X-HTTP-AUTH-TOKEN': token,
    };

    try {
      const response = mock
        ? await fetchFixture(fixture)
        : await fetch(PATH, {
            method,
            headers,
          });
      return response.json();
    } catch {
      throw new Error('処理に失敗しました');
    }
  }
}
