import styled from '@emotion/styled';
import { IProject, PROJECT_CATEGORY } from 'domain/entities/Project/Project';
import { ProjectDesc } from 'ui/components/user/patterns/ProjectDesc/ProjectDesc';
import { ProjectManagementUsers } from 'ui/components/user/patterns/ProjectManagementUsers/ProjectManagementUsers';
import { ProjectReviews } from 'ui/components/user/patterns/ProjectReviews/ProjectReviews';
import { ProjectShare } from 'ui/components/user/patterns/ProjectShare/ProjectShare';
import videoIcon from 'ui/images/com/videocam.svg';
import { CompanyDetail } from 'ui/components/user/patterns/CompanyDetail/CompanyDetail';
import { useProject } from './useProject';

const Wrapper = styled.div`
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  gap: 24px;
  padding-bottom: 40px;
`;

const Interviewer = styled.div<{ marginTop?: boolean }>`
  margin: ${(props) => (props.marginTop ? '30px 0 5px' : '5px 0')};
`;

const ImgContainer = styled.div`
  text-align: center;
  overflow: hidden;

  img {
    width: 100%;
    border-radius: 8px;
  }
`;

const Title = styled.div`
  overflow: hidden;
  color: #1e1e1e;
  text-overflow: ellipsis;
  font-size: 24px;
  font-weight: var(--font-weight--bolder);
  line-height: 1.6;
`;

const CompanyName = styled.div`
  color: var(--gray-dark-2);
  font-size: 12px;
  font-weight: 600;
  ${(props) => props.onClick && 'cursor:pointer;'}
`;

const Tags = styled.div`
  display: flex;
  gap: 5px;
  margin-top: -20px;
`;

const Tag = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 3px 8px;
  border-radius: 5px;
  background-color: var(--spr-${(props) => props.color});
  color: var(--spr-white);
  font-size: 14px;
  line-height: 16px;
`;

const VideoIcon = styled.img`
  width: 16px;
  height: 16px;
  filter: invert(100%);
`;

const Company = styled.div`
  margin-top: 26px;
  padding: 24px;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.5);

  h3 {
    color: #b4b4b4;
    font-size: 14px;
    font-weight: var(--font-weight--bolder);
    line-height: 1.6;
    margin: 0;
  }
`;

const Relative = styled.div`
  position: relative;
`;

const Balloon = styled.div`
  padding: 16px 24px;
  font-size: 14px;
  background: var(--spr-white);
  position: relative;
  top: -30px;
  width: calc(100% - 32px);
  left: 50%;
  transform: translateX(-50%);
  border-radius: 8px;

  &:before {
    content: '';
    position: absolute;
    top: -22px;
    right: 24px;
    border: 8px solid transparent;
    border-bottom: 15px solid var(--spr-white);
  }

  div {
    color: var(--spr-primary-dark);
    font-weight: var(--font-weight--bolder);
    margin-bottom: 10px;
  }

  p {
    margin: 0;
  }
`;

/**
 * プロジェクト詳細画面に使用する
 */
// TODO このレイアウトをcompany側のプレビュー画面にも反映する
// TODO panes/ProjectDetailから派生、リニューアル後不要になれば削除する

export const Project: React.FC<{
  project: IProject;
  onClickInvitation?: () => void;
}> = ({ project, onClickInvitation }) => {
  const { ref, onCompanyClick } = useProject();

  return (
    <Wrapper>
      <div>
        {/* タイトル */}
        <Title>{project.title}</Title>
        <CompanyName onClick={!project.privacy ? onCompanyClick : undefined}>
          {project.privacy ? '企業情報：非公開' : project.company?.name}
        </CompanyName>
      </div>
      <Relative>
        {/* メイン画像 */}
        <ImgContainer>
          <img src={project.main_pic} alt={project.title} />
        </ImgContainer>
        {project.summary && (
          <Balloon>
            <div>会いたい方へ、メッセージ</div>
            <p dangerouslySetInnerHTML={{ __html: project.summary.replace(/\n/g, '<br />') }} />
          </Balloon>
        )}
      </Relative>

      {/* タグ */}
      <Tags className="Tags">
        {project.category === PROJECT_CATEGORY.EVENT && <Tag color="accent">イベント参加</Tag>}
        {project.enable_offline_interview && (
          <Tag color="accent">
            <VideoIcon src={videoIcon} alt="ビデオアイコン" />
            オンライン可
          </Tag>
        )}
      </Tags>

      {/* シェア */}
      <ProjectShare project={project} onClickInvitation={onClickInvitation} />

      {/* プロジェクト詳細 */}
      <ProjectDesc project={project} />

      {/* シェア */}
      <ProjectShare project={project} onClickInvitation={onClickInvitation} />

      {/* 依頼企業 */}

      <Company ref={ref}>
        {!project.privacy && (
          <>
            <h3>依頼企業について</h3>
            <CompanyDetail company={project.company} />
          </>
        )}
        {/* インタビューアー */}
        <Interviewer marginTop={!project.privacy}>
          <h3>このセッションの担当者</h3>
          <ProjectManagementUsers
            project={project}
            title={project.category === PROJECT_CATEGORY.EVENT ? 'イベント主催者' : undefined}
            onCompanyClick={onCompanyClick}
          />
        </Interviewer>
      </Company>

      {/* セッションレビュー */}
      <ProjectReviews reviews={project.introduced_user_reviews} />
    </Wrapper>
  );
};
