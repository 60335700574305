import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { RoomViewType } from 'ui/components/company/layouts/Chat/useRoomList';
import { NotificationBadge } from 'ui/components/company/elements/NotificationBadge/NotificationBadge';

const Wrapper = styled.div`
  display: flex;
  gap: 60px;
`;

const Item = styled.div`
  position: relative;
  width: 90px;
  padding: 4px 8px;
  background-color: ${(props: { active: boolean }) =>
    props.active ? 'var(--spr-black)' : 'var(--gray-light-2)'};
  border-radius: 20px;
  color: ${(props: { active: boolean }) =>
    props.active ? 'var(--spr-white)' : 'var(--spr-black)'};
  font-size: 12px;
  text-align: center;
  cursor: pointer;
`;

const Badge = css`
  position: absolute;
  top: -2px;
  right: 2px;
`;

interface Props {
  viewType: RoomViewType;
  options: Record<RoomViewType, string>;
  badgeCounts: Record<RoomViewType, number>;
  onChange: (value: RoomViewType) => void;
}

/**
 * メッセージ ルーム一覧の閲覧タブ設定
 */
export const RoomViewTabs: React.FC<Props> = ({ viewType, options, badgeCounts, onChange }) => {
  return (
    <Wrapper>
      {Object.entries(options).map(([key, value]) => (
        <Item key={key} active={viewType === key} onClick={() => onChange(key)}>
          {value}
          {badgeCounts[key] > 0 && (
            <NotificationBadge size={16} count={badgeCounts[key]} css={Badge} />
          )}
        </Item>
      ))}
    </Wrapper>
  );
};
