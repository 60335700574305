import { useCallback, useEffect, useState } from 'react';
import { MessageInputProps } from './MessageInput';

export const useMessageInput = ({
  roomId,
  template,
  sendMessage,
  onComplete,
}: MessageInputProps) => {
  const [text, setText] = useState(template ?? '');
  const [rows, setRows] = useState(6);
  const [requesting, setRequesting] = useState(false);

  const onFocus = useCallback(() => {
    setRows(12);
  }, []);

  const onBlur = useCallback(() => {
    if (text.length === 0) {
      setRows(6);
    }
  }, [text]);

  const onClick = useCallback(async () => {
    if (text.length === 0) {
      return;
    }
    setRequesting(true);
    try {
      await sendMessage(roomId, text);
      onComplete?.();
      setText('');
    } finally {
      setRequesting(false);
    }
  }, [roomId, text, sendMessage]);

  useEffect(() => {
    // 初期化処理
    setText(template ?? '');
  }, [roomId]);

  return { text, rows, requesting, setText, onFocus, onBlur, onClick };
};
