import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { IconButton } from 'ui/components/company/elements/IconButton/IconButton';
import { ActionButton } from 'ui/components/company/elements/ActionButton/ActionButton';
import { UserIcon } from 'ui/components/company/elements/UserIcon/UserIcon';
import EditIcon from 'ui/images/company/icons/edit.svg';
import { useCompanyUserConfirm } from './useCompanyUserConfirm';
import { CompanyOgp } from 'domain/valueObjects/Ogp/CompanyOgp';
import { SnackBar } from 'ui/components/company/elements/SnackBar/SnackBar';

const Card = styled.div`
  margin-bottom: 20px;
  padding: 40px 28px;
  border-radius: 12px;
  background-color: var(--white);
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 20px;
  font-size: var(--font-xxx-large);
  font-weight: 900;
  line-height: 1;
`;

const Group = styled.div`
  margin-bottom: 16px;
  padding-bottom: 12px;
  border-bottom: 1px solid var(--gray-light-1);
  font-weight: 900;
`;

const Label = styled.p`
  margin: 0 0 8px;
  font-size: var(--font-x-large);
`;

const Value = styled.p`
  margin: 0;
  font-size: var(--font-large);
  white-space: pre-line;
`;

const Pic = styled.div`
  align-items: center;
  img {
    width: 250px;
    margin-inline: 1%;
    border-radius: 8px;
  }
`;

const Ogp = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;

  p {
    margin: 0;
  }
`;

/**
 * 企業ユーザー登録 確認画面
 *
 */
export const CompanyUserConfirm: React.FC = () => {
  const navigate = useNavigate();
  const { user, requesting, isInvited, showSnackbar, setShowSnackbar, onConfirm } =
    useCompanyUserConfirm();

  return (
    <div>
      <SnackBar
        open={showSnackbar}
        setOpen={setShowSnackbar}
        message={'登録が完了しました。'}
        callback={() => navigate('/business/registration/complete/')}
      />
      <Card>
        <Title>
          ユーザー情報
          <IconButton
            icon={EditIcon}
            buttonStyle={{ padding: '9px 12px' }}
            onClick={() => navigate('/business/register/user/profile/')}
          >
            修正する
          </IconButton>
        </Title>
        <Group>
          <Label>名前</Label>
          <Value>
            {user.last_name} {user.first_name}
          </Value>
        </Group>
        <Group>
          <Label>ユーザーアイコン</Label>
          <Value>
            <UserIcon src={user.icon_image} size={80} />
          </Value>
        </Group>
        <Group>
          <Label>パスワード</Label>
          <Value>*************</Value>
        </Group>
        <Group>
          <Label>企業名</Label>
          <Value>{user.company.name}</Value>
        </Group>
        <Group>
          <Label>部署</Label>
          <Value>{user.department}</Value>
        </Group>
        <Group>
          <Label>役職</Label>
          <Value>{user.job || '未入力'}</Value>
        </Group>
        <Group>
          <Label>電話番号</Label>
          <Value>{user.phone_number}</Value>
        </Group>
        {!isInvited && (
          <Group>
            <Label>紹介コード</Label>
            <Value>{user.referral_code || '未入力'}</Value>
          </Group>
        )}
      </Card>

      <Card>
        <Title>
          企業情報（ワークグループ）の入力
          <IconButton
            icon={EditIcon}
            buttonStyle={{ padding: '9px 12px' }}
            onClick={() => navigate('/business/register/workgroup/profile/')}
          >
            修正する
          </IconButton>
        </Title>
        <Group>
          <Label>企業名</Label>
          <Value>{user.company.name}</Value>
        </Group>
        <Group>
          <Label>郵便番号</Label>
          <Value>{user.company.zipcode}</Value>
        </Group>
        <Group>
          <Label>御社所在地</Label>
          <Value>{user.company.address}</Value>
        </Group>
        <Group>
          <Label>コーポレートサイトURL</Label>
          <Value>{user.company.url}</Value>
        </Group>
        <Group>
          <Label>ロゴ（アイコン画像）</Label>
          <Value>
            {user.company.icon_image ? (
              <UserIcon src={user.company.icon_image} size={80} />
            ) : (
              '未選択'
            )}
          </Value>
        </Group>
        <Group>
          <Label>会社説明</Label>
          <Value>{user.company.about_company}</Value>
        </Group>
        <Group>
          <Label>会社説明画像1</Label>
          <Value>
            {user.company.pics && user.company.pics.length > 0 ? (
              <Pic>
                <img src={user.company.pics[0]} alt={user.company.name} />
              </Pic>
            ) : (
              '未選択'
            )}
          </Value>
        </Group>
        <Group>
          <Label>会社説明画像2</Label>
          <Value>
            {user.company.pics && user.company.pics.length > 1 ? (
              <Pic>
                <img src={user.company.pics[1]} alt={user.company.name} />
              </Pic>
            ) : (
              '未選択'
            )}
          </Value>
        </Group>
        <Group>
          <Label>会社紹介URL</Label>
          <Value>
            <Ogp>
              {user.company.url_ogps && user.company.url_ogps.length > 0
                ? user.company.url_ogps.map((ogp: CompanyOgp) => <p key={ogp.url}>{ogp.url}</p>)
                : '未入力'}
            </Ogp>
          </Value>
        </Group>
        <Group>
          <Label>企業のコピーや理念など</Label>
          <Value>{user.company.copy}</Value>
        </Group>
        <Group>
          <Label>HERP連携用WebHookURL</Label>
          <Value>{user.company.herp_webhook_url || '未入力'}</Value>
        </Group>
        <Group>
          <Label>Slack通知用WebHookURL</Label>
          <Value>{user.company.slack_webhook_url || '未入力'}</Value>
        </Group>
      </Card>

      <Card>
        <ActionButton
          bgColor="secondary-dark"
          style={{ margin: '0 auto', padding: '22px 70px' }}
          disabled={requesting}
          onClick={onConfirm}
        >
          この内容で登録する
        </ActionButton>
      </Card>
    </div>
  );
};
