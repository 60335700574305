import { FC } from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { mq } from 'utils/responsive';
import BackIcon from 'ui/images/user-lp/icon_button.svg';

const Wrapper = styled.div`
  width: 1024px;
  margin: 10px auto;

  ${mq('NARROW')} {
    width: 89.3vw;
    margin: 2.1vw auto;
  }

  a {
    position: relative;
    padding: 5px 0 5px 35px;

    @media all and (-ms-high-contrast: none) {
      padding: 8px 0 2px 35px;
    }

    ${mq('SP')} {
      padding: 1.3vw 0 1.3vw 10.6vw;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      margin-top: auto;
      margin-bottom: auto;
      display: inline-block;
      background: url(${BackIcon});
      transform: rotate(180deg);
      width: 27px;
      height: 27px;
      background-size: 27px;

      ${mq('SP')} {
        width: 6.6vw;
        height: 6.6vw;
        background-size: 6.6vw;
      }
    }

    span {
      font-weight: bold;
      font-size: 18px;
      padding-bottom: 5px;
      border-bottom: 3px solid #000;
      @media all and (-ms-high-contrast: none) {
        padding-bottom: 2px;
      }

      ${mq('SP')} {
        font-size: 3.7vw;
        padding-bottom: 1.3vw;
        border-bottom: 0.8vw solid #000;
      }
    }
  }
`;

export const BackButton: FC = () => {
  return (
    <Wrapper>
      <Link to="/">
        <span>戻る</span>
      </Link>
    </Wrapper>
  );
};
