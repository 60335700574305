import styled from '@emotion/styled';
import { CompanyUserItem } from 'ui/components/company/features/CompanyUserItem/CompanyUserItem';
import { MessageItem } from 'ui/components/company/features/MessageItem/MessageItem';
import { RoomMeetRequest } from 'ui/components/company/features/RoomMeetRequest/RoomMeetRequest';
import { RoomProjectSummary } from 'ui/components/company/features/RoomProjectSummary/RoomProjectSummary';
import { ChatInput } from 'ui/components/company/patterns/ChatInput/ChatInput';
import { UserItem } from 'ui/components/company/patterns/UserItem/UserItem';
import { MemberEdit } from 'ui/components/company/screens/modals/MemberEdit/MemberEdit';
import { MeetupDateRemind } from '../../screens/modals/MeetupDateRemind/MeetupDateRemind';
import { ChatDetailProps, useChatDetail } from './useChatDetail';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const MemberWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 20px;
  padding-right: 10px;
`;

const MembersTitle = styled.div`
  font-size: 10px;
`;

const DialogOpener = styled.div`
  padding-left: 3px;
  font-size: 9px;
  text-decoration: underline;
  cursor: pointer;
`;

const MessageWrapper = styled.div`
  height: 100%;
  padding-inline: 16px;
  overflow-y: auto;
  flex: 1;
`;

const Meta = styled.div`
  margin-block: 20px;
  text-align: center;
  color: var(--gray-dark-1);
`;

const NoData = styled.div`
  margin-top: 20px;
  text-align: center;
`;

const NotJoined = styled.div`
  height: 80px;
  margin: 0 -20px -20px;
  padding: 8px;
  background-color: var(--spr-back1);
`;

export const ChatDetail: React.FC<ChatDetailProps> = (props) => {
  const {
    ref,
    user,
    roomId,
    messages,
    members,
    meetRequests,
    joined,
    memberEditModal,
    remindModal,
    remindModalProps,
    fetchMembers,
    onSendMessage,
    fetchRooms,
    removeMessage,
  } = useChatDetail(props);

  return (
    <Wrapper>
      <MemberWrapper>
        {members.companyUsers.length > 0 && <MembersTitle>参加者</MembersTitle>}
        {members.users.map((user) => (
          <UserItem key={user.id} user={user} realName viewType="iconOnly" />
        ))}
        {members.companyUsers.map((user) => (
          <CompanyUserItem key={user.id} user={user} viewType="iconOnly" />
        ))}
        {roomId && <DialogOpener onClick={memberEditModal.openModal}>追加/編集</DialogOpener>}
      </MemberWrapper>

      <MessageWrapper ref={ref}>
        {/* 応募・紹介 */}
        {props.room?.chatType === 'cast' && props.room?.project && (
          <RoomProjectSummary project={props.room.project} />
        )}

        {/* 会いたいリクエスト */}
        {props.room?.chatType === 'mr' &&
          meetRequests.map((meetRequest) => (
            <RoomMeetRequest key={meetRequest.id} meetRequest={meetRequest} />
          ))}

        {/* 株式会社アカリク経由のユーザーと面談する場合のアラート */}
        {props.room?.is_introduced_user_from_acaric && (
          <div
            style={{
              fontSize: '1.2rem',
              maxWidth: '80%',
              lineHeight: '2rem',
              margin: '20px auto',
              padding: '10px',
              border: '1px solid #f0f0f0',
              borderRadius: '8px',
            }}
          >
            本紹介の被紹介者は株式会社アカリク経由のユーザーです。ユーザーヒアリングは通常通り行っていただけます。
            万一、雇用契約や業務委託登用に繋がった場合、株式会社アカリクに紹介フィーが発生いたします。
          </div>
        )}

        {messages.length === 0 && <NoData>メッセージがまだありません</NoData>}

        {messages.length > 0 && <Meta>先頭のメッセージ</Meta>}
        {messages.map((message) => (
          <MessageItem
            key={`${message.timestamp}_${message.user_type}_${message.user_id}`}
            message={message}
            companyUser={user}
            removeMessage={removeMessage}
          />
        ))}
        {messages.length > 0 && <Meta>これ以上メッセージはありません</Meta>}
      </MessageWrapper>

      {joined ? (
        <ChatInput roomId={roomId ?? ''} onSend={onSendMessage} />
      ) : (
        <NotJoined>
          メッセージの送信／未読の通知の受信は、右上の参加者編集よりご自身をこのチャットルームに参加させてください。
        </NotJoined>
      )}

      {memberEditModal.createModal(
        <MemberEdit
          roomId={roomId ?? ''}
          members={members.companyUsers}
          fetchMembers={fetchMembers}
          closeModal={memberEditModal.closeModal}
        />,
      )}

      {remindModal.createModal(
        <MeetupDateRemind
          {...remindModalProps}
          close={remindModal.closeModal}
          onUpdateMeetingAtComplete={fetchRooms}
        />,
      )}
    </Wrapper>
  );
};
