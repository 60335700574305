import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { TEAM_ROLE_TYPE } from 'domain/entities/Team/TeamRole';
import { companyUserContext } from 'application/contexts/useCompanyUser';
import { companyStatsContext } from 'application/contexts/useCompanyStats';
import { companyMessageContext } from 'application/contexts/useCompanyMessage';
import { companyAppContext } from 'application/contexts/useCompanyApp';
import EditNoteIcon from 'ui/images/company/sidebar/edit_note.svg';
import MailIcon from 'ui/images/company/sidebar/mail.svg';
import GroupIcon from 'ui/images/company/sidebar/group.svg';
import WidgetIcon from 'ui/images/company/sidebar/widget.svg';
import HelpIcon from 'ui/images/company/sidebar/help.svg';
import HeartPlusIcon from 'ui/images/company/sidebar/heart_plus.svg';

interface SidebarGroup {
  name: string;
  icon: string;
  links: {
    key?: string;
    name: string;
    // 内部リンク
    path?: string;
    // 外部リンク
    href?: string;
    description?: string;
  }[];
}

export const SIDEBAR_GROUPS: SidebarGroup[] = [
  {
    name: '募集する',
    icon: EditNoteIcon,
    links: [
      {
        name: 'ダッシュボード',
        path: '/workgroup/',
      },
      {
        name: 'セッション一覧・作成',
        path: '/workgroup/projects/',
      },
    ],
  },
  {
    name: '応募管理',
    icon: MailIcon,
    links: [
      {
        key: 'castings',
        name: '紹介一覧',
        path: '/workgroup/castings/',
      },
      {
        key: 'messages',
        name: 'メッセージ',
        path: '/workgroup/chat/rooms/',
      },
    ],
  },
  {
    name: '会いたいリクエスト',
    icon: GroupIcon,
    links: [
      {
        name: '検索・進捗管理',
        path: '/workgroup/meet/',
        description:
          '会いたいリクエストとは、Spreadyユーザーの中から「会いたい」と思う方を探し、リクエストを送信する機能です。',
      },
    ],
  },
  {
    name: '各種設定・契約・請求',
    icon: WidgetIcon,
    links: [
      {
        name: 'ユーザー一覧',
        path: '/workgroup/users/',
      },
      {
        name: 'ワークグループ情報',
        path: '/workgroup/edit/',
      },
      {
        key: 'contracts',
        name: '契約管理',
        path: '/workgroup/contract/',
      },
      {
        name: '請求先管理',
        path: '/workgroup/billing-info/',
      },
      {
        name: '請求履歴',
        path: '/workgroup/payments/',
      },
    ],
  },
  {
    name: 'サポート',
    icon: HelpIcon,
    links: [
      {
        name: 'ご利用ガイド',
        href: 'https://www.notion.so/spready/Spready-2415ce16287048b99a69106d85dc9337',
      },
    ],
  },
  {
    name: '新規事業支援',
    icon: HeartPlusIcon,
    links: [
      {
        name: '定量調査',
        href: 'https://client.spready.jp/qs',
      },
      {
        name: 'HASSAN',
        href: 'https://client.spready.jp/hassan',
      },
      {
        name: '新規事業人材支援',
        href: 'https://client.spready.jp/expertworks',
      },
      {
        name: 'ブートキャンプ',
        href: 'https://client.spready.jp/zeroichi_bootcamp_online',
      },
    ],
  },
];

export const useSidebar = () => {
  const { user } = useContext(companyUserContext);
  const { pageVisibility } = useContext(companyAppContext);
  const { waitingFirstContactCount } = useContext(companyStatsContext);
  const { unreadIds } = useContext(companyMessageContext);

  // 展開しているグループのindexを保持
  const [openState, setOpenState] = useState<Set<number>>(new Set());

  // 通知数など
  const [notifications, setNotifications] = useState<{ [key: string]: number }>({});

  const showSidebar = useMemo(() => {
    return user.team_role !== TEAM_ROLE_TYPE.SUPPORT_CHAT_PERMITTED;
  }, [user.team_role]);

  const toggleOpenState = useCallback((index: number) => {
    setOpenState((prev) => {
      const newState = new Set(prev);
      newState.has(index) ? newState.delete(index) : newState.add(index);
      return newState;
    });
  }, []);

  // 通知数の更新
  useEffect(() => {
    setNotifications((prev) => ({ ...prev, castings: waitingFirstContactCount }));
  }, [waitingFirstContactCount]);
  useEffect(() => {
    setNotifications((prev) => ({ ...prev, messages: unreadIds.length }));
  }, [unreadIds]);

  return {
    showSidebar,
    pageVisibility,
    notifications,
    openState,
    toggleOpenState,
  };
};
