import { PropsWithChildren } from 'react';
import styled from '@emotion/styled';

type ButtonStatus = 'primary' | 'secondary' | 'white' | 'danger' | 'cancel';

/** ボタンの見た目を指定 */
interface ButtonStyle {
  width?: string;
  fontSize?: string;
  padding?: string;
}

type Props = ButtonStyle & {
  // todo: 全ての button_type 指定箇所を削除したら、こちらは required にする
  status?: ButtonStatus;
  icon?: React.ReactNode;
  /** @deprecated status を指定する */
  button_type?: 'dark' | 'blue' | 'pale_blue' | 'red' | 'weak_red' | 'delete' | 'gray';
  disabled?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

const BaseButton = styled.button`
  max-width: 100%;
  border: none;
  outline: none;
  border-radius: 4px;
  text-align: center;
  font-size: ${(props: { fontSize?: string }) => props.fontSize || '14px'};
  cursor: pointer;
  transition: opacity 0.3s ease-out;

  &:hover,
  &:focus {
    opacity: 0.8;
  }
`;

const DarkButton = styled(BaseButton)`
  width: ${(props: { width?: string }) => props.width || '320px'};
  height: 40px;
  padding-inline: 16px;
  background-color: var(--spr-black);
  color: var(--spr-white);
`;

const BlueButton = styled(BaseButton)`
  padding: ${(props: { padding?: string }) => props.padding || '10px 8px'};
  background-color: var(--spr-accent);
  color: var(--spr-white);
  &:disabled {
    background-color: #0000001f;
    color: #00000042;
    cursor: default;
  }
`;

const PaleBlueButton = styled(BaseButton)`
  padding: 9px 15px;
  background-color: var(--spr-accent-paler);
  color: var(--spr-accent-dark);
  border: 1px solid rgba(0, 0, 0, 0.12);
  margin-right: 12px;
`;

const RedButton = styled(BaseButton)`
  padding: ${(props: { padding?: string }) => props.padding || '10px 8px'};
  background-color: var(--color-new-lp-warn);
  color: var(--spr-white);
`;

const WeakRedButton = styled(BaseButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
  width: ${(props: { width?: string }) => props.width || null};
  border-radius: 4px;
  padding: 8px;
  font-size: 14px;
  line-height: 24px;
  font-weight: 700;
  background-color: var(--spr-warn-paler);
  color: var(--spr-warn);
`;

const DeleteButton = styled(BaseButton)`
  padding: ${(props: { padding?: string }) => props.padding || '8px 10px'};
  font-size: ${(props: { fontSize?: string }) => props.fontSize || '10px'};
  color: var(--color-new-lp-warn);
  background-color: transparent;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14),
    0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  border: 1px solid rgba(0, 0, 0, 0.12);
`;

const GrayButton = styled(BaseButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
  width: ${(props: { width?: string }) => props.width || null};
  border-radius: 4px;
  padding: 8px;
  font-size: 14px;
  line-height: 24px;
  font-weight: 700;
  color: var(--gray-dark-3);
  background-color: var(--gray-light-2);
`;

const Base = styled.button<ButtonStyle>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  width: ${(props) => props.width || null};
  padding: ${(props) => props.padding || '8px 12px'};
  border: none;
  outline: none;
  border-radius: 8px;
  font-size: ${(props) => props.fontSize ?? null};
  line-height: 1;
  font-weight: 600;
  cursor: pointer;
  transition: opacity 0.3s ease-out;

  &:hover,
  &:focus {
    opacity: 0.8;
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

const Icon = styled.span`
  flex-basis: content;
`;

const PrimaryButton = styled(Base)`
  color: var(--spr-white);
  background-color: var(--spr-secondary);
  border: 1px solid var(--spr-secondary);
`;

const SecondaryButton = styled(Base)`
  color: var(--spr-primary-dark);
  background-color: var(--spr-primary-paler);
  border: 1px solid var(--spr-primary-dark);
`;

const WhiteButton = styled(Base)`
  color: var(--spr-primary-dark);
  background-color: var(--spr-white);
  border: 1px solid var(--gray-light-2);
`;

const DangerButton = styled(Base)`
  color: var(--spr-warn);
  background-color: var(--spr-warn-pale);
  border: 1px solid var(--spr-warn);
`;

const CancelButton = styled(Base)`
  color: var(--gray-dark-2);
  background-color: var(--spr-white);
  border: 1px solid var(--gray-light-1);
`;

/**
 * 汎用ボタンコンポーネント
 */
export const Button: React.FC<PropsWithChildren<Props>> = ({
  status,
  button_type,
  disabled,
  width,
  fontSize,
  padding,
  icon = null,
  onClick,
  children,
}) => {
  const ButtonElement = {
    primary: PrimaryButton,
    secondary: SecondaryButton,
    white: WhiteButton,
    danger: DangerButton,
    cancel: CancelButton,

    dark: DarkButton,
    blue: BlueButton,
    pale_blue: PaleBlueButton,
    red: RedButton,
    weak_red: WeakRedButton,
    delete: DeleteButton,
    gray: GrayButton,
  }[button_type ?? status ?? 'dark'];
  return (
    <ButtonElement
      disabled={disabled}
      width={width}
      fontSize={fontSize}
      padding={padding}
      onClick={onClick}
    >
      {icon && <Icon>{icon}</Icon>}
      {children}
    </ButtonElement>
  );
};
