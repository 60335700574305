import { inject, injectable } from 'tsyringe';

import { type ILineAccountLinkCompleteClient } from 'interfaceAdapter/gatewaySchemas/ILineAccountLinkCompleteClient';
import { type IUserTokenRepository } from 'application/repositorySchemas/IUserTokenRepository';
import {
  ILineAccountLinkCompleteRepository,
  CompleteResponse,
} from 'application/repositorySchemas/ILineAccountLinkCompleteRepository';

@injectable()
export class LineAccountLinkCompleteRepository implements ILineAccountLinkCompleteRepository {
  constructor(
    @inject('IUserTokenRepository')
    public readonly userToken: IUserTokenRepository,
    @inject('ILineAccountLinkCompleteClient')
    public readonly client: ILineAccountLinkCompleteClient,
  ) {}

  async complete(): Promise<CompleteResponse> {
    const response = await this.client.post({
      token: this.userToken.get() || '',
    });

    if (response.message) {
      return {
        success: false,
        errorMessage: response.message,
      };
    }

    const lineOfficialAccountId = response.data.line_official_account_id;
    if (!lineOfficialAccountId) {
      throw new Error('line_official_account_id is empty');
    }

    return {
      success: true,
      lineOfficialAccountId,
    };
  }
}
