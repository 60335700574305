import { inject, injectable } from 'tsyringe';

import {
  ILineAccountLinkRequestRepository,
  LineAccountLinkRequestResponse,
} from 'application/repositorySchemas/ILineAccountLinkRequestRepository';
import { type ILineAccountLinkRequestClient } from 'interfaceAdapter/gatewaySchemas/ILineAccountLinkRequestClient';

@injectable()
export class LineAccountLinkRequestRepository implements ILineAccountLinkRequestRepository {
  constructor(
    @inject('ILineAccountLinkRequestClient')
    public readonly client: ILineAccountLinkRequestClient,
  ) {}

  async request({
    disableAutoLogin,
  }: {
    disableAutoLogin: boolean;
  }): Promise<LineAccountLinkRequestResponse> {
    const response = await this.client.post({ disableAutoLogin });

    const redirectUrl = response.data.redirect_url;
    if (!redirectUrl) {
      throw new Error('redirectUrl is empty');
    }

    return {
      redirectUrl,
    };
  }
}
