// `@inject` で依存関係逆転の法則を利用した上位レイヤーのクラスを利用する場合は記載すること
// Interface名に対応する実装クラスのマッピングをここでやっています

import { CompanyUserLogout } from 'application/usecases/CompanyUserLogout';
import { UserClearAllStorages } from 'application/usecases/UserClearAllStorages';
import { UserConfirmEmail } from 'application/usecases/UserConfirmEmail';
import { UserLogout } from 'application/usecases/UserLogout';
import { CastingFactory } from 'domain/entities/factories/Casting';
import { ChatRoomFactory } from 'domain/entities/factories/ChatRoom';
import { CompanyFactory } from 'domain/entities/factories/Company';
import { CompanyUserFactory } from 'domain/entities/factories/CompanyUser';
import { MeetRequestFactory } from 'domain/entities/factories/MeetRequest';
import { MessageFactory } from 'domain/entities/factories/Message';
import { NewsFactory } from 'domain/entities/factories/News';
import { PreCompanyUserFactory } from 'domain/entities/factories/PreCompanyUser';
import { ProjectFactory } from 'domain/entities/factories/Project';
import { QuestionnaireFactory } from 'domain/entities/factories/Questionnaire';
import { GiftTicketLogFactory } from 'domain/entities/factories/Reward';
import {
  CurrentRewardProgressFactory,
  GiftTicketProgressFactory,
} from 'domain/entities/factories/StampCard';
import { TeamFactory } from 'domain/entities/factories/Team';
import { UserFactory } from 'domain/entities/factories/User';
import { WebinarGuestFactory } from 'domain/entities/factories/WebinarGuest';
import { CompanyContractFactory } from 'domain/valueObjects/factories/CompanyContract';
import { IntroductionSurveyFactory } from 'domain/valueObjects/factories/IntroductionSurvey';
import { MeetRequestSurveyFactory } from 'domain/valueObjects/factories/MeetRequestSurvey';
import { ProjectBillingsFactory } from 'domain/valueObjects/factories/ProjectBillings';
import { RewardFactory } from 'domain/valueObjects/factories/Reward';
import { SpreaderChallengeFactory } from 'domain/valueObjects/factories/SpreaderChallenge';
import { UserNotificationFactory } from 'domain/valueObjects/factories/UserNotification';
import { BusinessChatClient } from 'infrastructure/apiClients/BusinessChat/BusinessChat';
import { CastCompaniesClient } from 'infrastructure/apiClients/CastCompanies/CastCompanies';
import { ChatRoomsClient } from 'infrastructure/apiClients/ChatRooms/ChatRooms';
import { ClosingSoonProjectsClient } from 'infrastructure/apiClients/ClosingSoonProjects/ClosingSoonProjects';
import { CompanyClient } from 'infrastructure/apiClients/Company/Company';
import { CompanyBillingSettingClient } from 'infrastructure/apiClients/CompanyBillingSetting/CompanyBillingSetting';
import { CompanyCheckTokenClient } from 'infrastructure/apiClients/CompanyCheckToken/CompanyCheckToken';
import { CompanyContractClient } from 'infrastructure/apiClients/CompanyContract/CompanyContract';
import { CompanyDraftProjectClient } from 'infrastructure/apiClients/CompanyDraftProject/CompanyDraftProject';
import { CompanyDraftProjectCreateClient } from 'infrastructure/apiClients/CompanyDraftProjectCreate/CompanyDraftProjectCreate';
import { CompanyDraftProjectDeleteClient } from 'infrastructure/apiClients/CompanyDraftProjectDelete/CompanyDraftProjectDelete';
import { CompanyDraftProjectEditClient } from 'infrastructure/apiClients/CompanyDraftProjectEdit/CompanyDraftProjectEdit';
import { CompanyDraftProjectsClient } from 'infrastructure/apiClients/CompanyDraftProjects/CompanyDraftProjects';
import { CompanyInvitedRegistrationSendMailClient } from 'infrastructure/apiClients/CompanyInvitedRegistrationSendMail/CompanyInvitedRegistrationSendMail';
import { CompanyInvitedRegistrationUserProfileClient } from 'infrastructure/apiClients/CompanyInvitedRegistrationUserProfile/CompanyInvitedRegistrationUserProfile';
import { CompanyLoginByEmailClient } from 'infrastructure/apiClients/CompanyLoginByEmail/CompanyLoginByEmail';
import { CompanyMoneyForwardDepartmentsClient } from 'infrastructure/apiClients/CompanyMoneyForwardDepartment/CompanyMoneyForwardDepartment';
import { CompanyNewsClient } from 'infrastructure/apiClients/CompanyNews/CompanyNews';
import { CompanyRegistrationPasswordClient } from 'infrastructure/apiClients/CompanyRegistrationPassword/CompanyRegistrationPassword';
import { CompanyRegistrationSendMailClient } from 'infrastructure/apiClients/CompanyRegistrationSendMail/CompanyRegistrationSendMail';
import { CompanySearchByNameClient } from 'infrastructure/apiClients/CompanySearchByName/CompanySearchByName';
import { CompanySearchByNumberClient } from 'infrastructure/apiClients/CompanySearchByNumber/CompanySearchByNumber';
import { CompanySendPasswordResetEmailClient } from 'infrastructure/apiClients/CompanySendPasswordResetEmail/CompanySendPasswordResetEmail';
import { CompanyTicketQuotationClient } from 'infrastructure/apiClients/CompanyTicketQuotation/CompanyTicketQuotation';
import { CompanyUserProfileRegistrationClient } from 'infrastructure/apiClients/CompanyUserProfileRegistration/CompanyUserProfileRegistration';
import { CompanyUserRegistrationConfirmClient } from 'infrastructure/apiClients/CompanyUserRegistrationConfirm/CompanyUserRegistrationConfirm';
import { ConfirmEmailClient } from 'infrastructure/apiClients/ConfirmEmail/ConfirmEmail';
import { DraftProjectAssetClient } from 'infrastructure/apiClients/DraftProjectAsset/DraftProjectAsset';
import { DraftProjectRequestClient } from 'infrastructure/apiClients/DraftProjectRequest/DraftProjectRequest';
import { ExchangeGiftsClient } from 'infrastructure/apiClients/ExchangeGifts/ExchangeGifts';
import { ExchangedGiftsClient } from 'infrastructure/apiClients/ExchangedGifts/ExchangedGifts';
import { FeaturedKeywordsClient } from 'infrastructure/apiClients/FeaturedKeywords/FeaturedKeywords';
import { FeedbackClient } from 'infrastructure/apiClients/Feedback/Feedback';
import { FinishTutorialClient } from 'infrastructure/apiClients/FinishTutorial/FinishTutorial';
import { FirstContactBillingClient } from 'infrastructure/apiClients/FirstContactBilling/FirstContactBilling';
import { FrequencyWordProjectsClient } from 'infrastructure/apiClients/FrequencyWordProjects/FrequencyWordProjects';
import { GenerateFacebookAuthTokenClient } from 'infrastructure/apiClients/GenerateFacebookAuthToken/GenerateFacebookAuthToken';
import { GenerateFacebookAuthUrlClient } from 'infrastructure/apiClients/GenerateFacebookAuthUrl/GenerateFacebookAuthUrl';
import { GiftTicketLogClient } from 'infrastructure/apiClients/GiftTicketLog/GiftTicketLog';
import { GiftTicketProgressClient } from 'infrastructure/apiClients/GiftTicketProgress/GiftTicketProgress';
import { GiftsClient } from 'infrastructure/apiClients/Gifts/Gifts';
import { IntroducedUserReviewsClient } from 'infrastructure/apiClients/IntroducedUserReviews/IntroducedUserReviews';
import { InvitationCodeClient } from 'infrastructure/apiClients/InvitationCode/InvitationCode';
import { InvitationUrlClient } from 'infrastructure/apiClients/InvitationUrl/InvitationUrl';
import { InvitationWriteIntroductionClient } from 'infrastructure/apiClients/InvitationWriteIntroduction/InvitationWriteIntroduction';
import { InvitedApplicationClient } from 'infrastructure/apiClients/InvitedApplication/InvitedApplication';
import { InvitedUsersClient } from 'infrastructure/apiClients/InvitedUsers/InvitedUsers';
import { InviterIntroductionClient } from 'infrastructure/apiClients/InviterIntroductionClient/InviterIntroduction';
import { LogoutClient } from 'infrastructure/apiClients/Logout/Logout';
import { MeetRequestByRoomIdClient } from 'infrastructure/apiClients/MeetRequestByRoomId/MeetRequestByRoomId';
import { MembersClient } from 'infrastructure/apiClients/Members/Members';
import { MessagesClient } from 'infrastructure/apiClients/Messages/Messages';
import { MyCompanyClient } from 'infrastructure/apiClients/MyCompany/MyCompany';
import { MyIntroducedProjectsClient } from 'infrastructure/apiClients/MyIntroducedProjects/MyIntroducedProjects';
import { MySelfClient } from 'infrastructure/apiClients/MySelf/MySelf';
import { NextRewardsClient } from 'infrastructure/apiClients/NextRewards/NextRewards';
import { PaymentBillClient } from 'infrastructure/apiClients/Payment/PaymentBillClient';
import { PaymentClient } from 'infrastructure/apiClients/Payment/PaymentClient';
import { CompanyCompletePaymentSessionClient } from 'infrastructure/apiClients/PaymentSession/PaymentSession';
import { PreCompanyUserByUrlIdClient } from 'infrastructure/apiClients/PreCompanyUserByUrlId/PreCompanyUserByUrlId';
import { PreCompanyUserByUrlIdFromInviteClient } from 'infrastructure/apiClients/PreCompanyUserByUrlIdFromInvite/PreCompanyUserByUrlIdFromInvite';
import { ProgressSpreaderChallengeClient } from 'infrastructure/apiClients/ProgressSpreaderChallenge/ProgressSpreaderChallenge';
import { ProjectBillingsClient } from 'infrastructure/apiClients/ProjectBillings/ProjectBillings';
import { ProjectByCodeClient } from 'infrastructure/apiClients/ProjectByCode/ProjectByCode';
import { ProjectQuotationClient } from 'infrastructure/apiClients/ProjectQuotation/ProjectQuotation';
import { ProjectTicketConsumptionClient } from 'infrastructure/apiClients/ProjectTicketConsumptionClient';
import { ProjectTutorialClient } from 'infrastructure/apiClients/ProjectTutorial/ProjectTutorial';
import { ProjectsAssignedClient } from 'infrastructure/apiClients/ProjectsAssigned/ProjectsAssigned';
import { PublicCompanyLogosClient } from 'infrastructure/apiClients/PublicCompanyLogos/PublicCompanyLogos';
import { PublicStatsClient } from 'infrastructure/apiClients/PublicStats/PublicStats';
import { PushedProjectsClient } from 'infrastructure/apiClients/PushedProjects/PushedProjects';
import { PushedProjectsForAcaricClient } from 'infrastructure/apiClients/PushedProjects/PushedProjectsForAcaric';
import { QuestionnaireClient } from 'infrastructure/apiClients/Questionnaire/Questionnaire';
import { ReadNewStampsClient } from 'infrastructure/apiClients/ReadNewStamps/ReadNewStamps';
import { ReadNewsClient } from 'infrastructure/apiClients/ReadNews/ReadNews';
import { RegisterClient } from 'infrastructure/apiClients/Register/Register';
import { ResendEmailChangeConfirmClient } from 'infrastructure/apiClients/ResendEmailChangeConfirm/ResendEmailChangeConfirm';
import { ResetPasswordClient } from 'infrastructure/apiClients/ResetPassword/ResetPassword';
import { SegmentsClient } from 'infrastructure/apiClients/Segments/Segments';
import { SelfApplicationClient } from 'infrastructure/apiClients/SelfApplication/SelfApplication';
import { SendEmailChangeEmailClient } from 'infrastructure/apiClients/SendEmailChangeEmail/SendEmailChangeEmail';
import { SendMessageClient } from 'infrastructure/apiClients/SendMessage/SendMessage';
import { SendPasswordResetEmailClient } from 'infrastructure/apiClients/SendPasswordResetMail/SendPasswordResetMail';
import { ShipInfoClient } from 'infrastructure/apiClients/ShipInfo/ShipInfo';
import { SkillSegmentsClient } from 'infrastructure/apiClients/SkillSegments/SkillSegments';
import { SpreaderChallengeStatusClient } from 'infrastructure/apiClients/SpreaderChallengeStatus/SpreaderChallengeStatus';
import { CurrentRewardProgressClient } from 'infrastructure/apiClients/CurrentRewardProgress/CurrentRewardProgress';
import { SupportChatClient } from 'infrastructure/apiClients/SupportChat/SupportChat';
import { SupportMessageClient } from 'infrastructure/apiClients/SupportMessage/SupportMessage';
import { TeamListClient } from 'infrastructure/apiClients/TeamList/TeamList';
import { TermsAgreementClient } from 'infrastructure/apiClients/TermsAgreement/TermsAgreement';
import { UpdateFacebookIdClient } from 'infrastructure/apiClients/UpdateFacebookId/UpdateFacebookId';
import { UploadFileClient } from 'infrastructure/apiClients/UploadFile/UploadFile';
import { UserMeetRequestDeclineClient } from 'infrastructure/apiClients/UserMeetRequestDecline/UserMeetRequestDecline';
import { UserMeetRequestsClient } from 'infrastructure/apiClients/UserMeetRequests/UserMeetRequests';
import { UserNewsClient } from 'infrastructure/apiClients/UserNews/UserNews';
import { UserNotificationClient } from 'infrastructure/apiClients/UserNotification/UserNotification';
import { UserProfileCoverageClient } from 'infrastructure/apiClients/UserProfileCoverage/UserProfileCoverage';
import { VerifyEmailChangeCodeClient } from 'infrastructure/apiClients/VerifyEmailChangeCode/VerifyEmailChangeCode';
import { WaitingIntroductionSelfAppliedClient } from 'infrastructure/apiClients/WaitingIntroductionSelfApplied/WaitingIntroductionSelfApplied';
import { WebinarGuestClient } from 'infrastructure/apiClients/WebinarGuest/WebinarGuest';
import { WithdrawalClient } from 'infrastructure/apiClients/Withdrawal/Withdrawal';
import { AnswerIntroductionSurveyForCompanyClient } from 'infrastructure/clients/AnswerIntroductionSurveyForCompany/AnswerIntroductionSurveyForCompany';
import { AnswerIntroductionSurveyForUserClient } from 'infrastructure/clients/AnswerIntroductionSurveyForUser/AnswerIntroductionSurveyForUser';
import { AnswerMeetRequestSurveyForCompanyClient } from 'infrastructure/clients/AnswerMeetRequestSurveyForCompany/AnswerMeetRequestSurveyForCompany';
import { AnswerMeetRequestSurveyForUserClient } from 'infrastructure/clients/AnswerMeetRequestSurveyForUser/AnswerMeetRequestSurveyForUser';
import { BulkProjectClient } from 'infrastructure/clients/BulkProject/BulkProject';
import { ChatsClient } from 'infrastructure/apiClients/Chats/Chats';
import { ChatUnArchiveClient } from 'infrastructure/clients/ChatUnArchive/ChatUnArchive';
import { CheckTokenClient } from 'infrastructure/clients/CheckToken/CheckToken';
import { CompanyCastedUsersClient } from 'infrastructure/clients/CompanyCastedUsers/CompanyCastedUsers';
import { CompanyCastingChatArchiveClient } from 'infrastructure/clients/CompanyCastingChatArchive/CompanyCastingChatArchive';
import { CompanyChatRoomMeetRequestsClient } from 'infrastructure/clients/CompanyChatRoomMeetRequests/CompanyChatRoomMeetRequests';
import { CompanyChatRoomMembersEditClient } from 'infrastructure/clients/CompanyChatRoomMembersEdit/CompanyChatRoomMembersEdit';
import { CompanyChatRoomMembersGetClient } from 'infrastructure/clients/CompanyChatRoomMembersGet/CompanyChatRoomMembersGet';
import { CompanyChatRoomMessageClient } from 'infrastructure/clients/CompanyChatRoomMessage/CompanyChatRoomMessage';
import { CompanyChatRoomMessagesClient } from 'infrastructure/clients/CompanyChatRoomMessages/CompanyChatRoomMessages';
import { CompanyDashboardProjectsClient } from 'infrastructure/clients/CompanyDashboardProjects/CompanyDashboardProjects';
import { CompanyEditClient } from 'infrastructure/clients/CompanyEdit/CompanyEdit';
import { CompanyIntroductionChatRoomsClient } from 'infrastructure/clients/CompanyIntroductionChatRooms/CompanyIntroductionChatRooms';
import { CompanyMeetRequestClient } from 'infrastructure/clients/CompanyMeetRequest/CompanyMeetRequest';
import { CompanyMeetRequestChatRoomsClient } from 'infrastructure/clients/CompanyMeetRequestChatRooms/CompanyMeetRequestChatRooms';
import { CompanyMeetRequestMeetingAtClient } from 'infrastructure/clients/CompanyMeetRequestMeetingAt/CompanyMeetRequestMeetingAt';
import { CompanyMeetRequestsClient } from 'infrastructure/clients/CompanyMeetRequests/CompanyMeetRequests';
import { CompanyProjectClient } from 'infrastructure/clients/CompanyProject/CompanyProject';
import { CompanyProjectCloseClient } from 'infrastructure/clients/CompanyProjectClose/CompanyProjectClose';
import { CompanyProjectCreateClient } from 'infrastructure/clients/CompanyProjectCreate/CompanyProjectCreate';
import { CompanyProjectEditClient } from 'infrastructure/clients/CompanyProjectEdit/CompanyProjectEdit';
import { CompanyProjectPendingClient } from 'infrastructure/clients/CompanyProjectPending/CompanyProjectPending';
import { CompanyProjectReopenClient } from 'infrastructure/clients/CompanyProjectReopen/CompanyProjectReopen';
import { CompanyProjectsClient } from 'infrastructure/clients/CompanyProjects/CompanyProjects';
import { CompanySpreadersClient } from 'infrastructure/clients/CompanySpreaders/CompanySpreaders';
import { CompanyStatsClient } from 'infrastructure/clients/CompanyStats/CompanyStats';
import { CompanySupportChatClient } from 'infrastructure/clients/CompanySupportChat/CompanySupportChat';
import { CompanySurveyCodeClient } from 'infrastructure/clients/CompanySurveyCode/CompanySurveyCode';
import { CompanyUnreadAllChatRoomsClient } from 'infrastructure/clients/CompanyUnreadAllChatRooms/CompanyUnreadAllChatRooms';
import { CompanyUserClient } from 'infrastructure/clients/CompanyUser/CompanyUser';
import { EditOwnCompanyUserClient } from 'infrastructure/apiClients/EditOwnCompanyUser/EditOwnCompanyUser';
import { EditOtherCompanyUserClient } from 'infrastructure/apiClients/EditOtherCompanyUser/EditOtherCompanyUser';
import { CompanyUserLogoutClient } from 'infrastructure/clients/CompanyUserLogout/CompanyUserLogout';
import { CompanyUserPasswordClient } from 'infrastructure/clients/CompanyUserPassword/CompanyUserPassword';
import { CompanyUsersClient } from 'infrastructure/clients/CompanyUsers/CompanyUsers';
import { EditCastedUserDataClient } from 'infrastructure/clients/EditCastedUserData/EditCastedUserData';
import { IntroductionSurveyForCompanyClient } from 'infrastructure/clients/IntroductionSurveyForCompany/IntroductionSurveyForCompany';
import { IntroductionSurveyForUserClient } from 'infrastructure/clients/IntroductionSurveyForUser/IntroductionSurveyForUser';
import { LoginByEmailClient } from 'infrastructure/clients/LoginByEmail/LoginByEmail';
import { LoginByFacebookClient } from 'infrastructure/clients/LoginByFacebook/LoginByFacebook';
import { MeetRequestSurveyForCompanyClient } from 'infrastructure/clients/MeetRequestSurveyForCompany/MeetRequestSurveyForCompany';
import { MeetRequestSurveyForUserClient } from 'infrastructure/clients/MeetRequestSurveyForUser/MeetRequestSurveyForUser';
import { PointLogClient } from 'infrastructure/clients/PointLog/PointLog';
import { PointRankingClient } from 'infrastructure/clients/PointRanking/PointRanking';
import { PreCompanyUserDeleteClient } from 'infrastructure/clients/PreCompanyUserDelete/PreCompanyUserDelete';
import { PreCompanyUserEmailClient } from 'infrastructure/clients/PreCompanyUserEmail/PreCompanyUserEmail';
import { PreCompanyUserGetClient } from 'infrastructure/clients/PreCompanyUserGet/PreCompanyUserGet';
import { ProjectClient } from 'infrastructure/clients/Project/Project';
import { PublicProjectClient } from 'infrastructure/clients/PublicProject/PublicProject';
import { PublicRewardsClient } from 'infrastructure/clients/PublicRewards/PublicRewards';
import { PublicSpreaderClient } from 'infrastructure/clients/PublicSpreader/PublicSpreader';
import { RejectCastingClient } from 'infrastructure/clients/RejectCasting/RejectCasting';
import { SendFirstContactClient } from 'infrastructure/clients/SendFirstContact/SendFirstContact';
import { SupportChatMessageClient } from 'infrastructure/clients/SupportChatMessage/SupportChatMessage';
import { SurveyCodeClient } from 'infrastructure/clients/SurveyCode/SurveyCode';
import { TestSlackWebhookClient } from 'infrastructure/clients/TestSlackWebhook/TestSlackWebhook';
import { UserMeetRequestAcceptClient } from 'infrastructure/clients/UserMeetRequestAccept/UserMeetRequestAccept';
import { TeamCreateClient } from 'infrastructure/apiClients/TeamCreate/TeamCreate';
import { TeamSwitchClient } from 'infrastructure/apiClients/TeamSwitch/TeamSwitch';
import { TeamMemberDisableMyselfClient } from 'infrastructure/apiClients/TeamMemberDisableMyself/TeamMemberDisableMySelf';
import { CookieUsable } from 'infrastructure/storages/CookieUsable';
import { AppliedProjectsQuery } from 'interfaceAdapter/queries/AppliedProjects';
import { CastCompaniesQuery } from 'interfaceAdapter/queries/CastCompanies';
import { ChatRoomQuery } from 'interfaceAdapter/queries/ChatRoom';
import { CompanyQuery } from 'interfaceAdapter/queries/Company';
import { CompanyBillingSettingQuery } from 'interfaceAdapter/queries/CompanyBillingSetting';
import { CompanyCastedUsersQuery } from 'interfaceAdapter/queries/CompanyCastedUsers';
import { CompanyDraftProjectQuery } from 'interfaceAdapter/queries/CompanyDraftProject';
import { CompanyMoneyForwardDepartmentsQuery } from 'interfaceAdapter/queries/CompanyMoneyForwardDepartments';
import { CompanyNewsQuery } from 'interfaceAdapter/queries/CompanyNews';
import { CompanyProjectQuery } from 'interfaceAdapter/queries/CompanyProject';
import { CompanyQuotationQuery } from 'interfaceAdapter/queries/CompanyQuotation';
import { CompanySearchQuery } from 'interfaceAdapter/queries/CompanySearch';
import { CompanyStatsQuery } from 'interfaceAdapter/queries/CompanyStats';
import { CompanyUserQuery } from 'interfaceAdapter/queries/CompanyUser';
import { FeaturedKeywordsQuery } from 'interfaceAdapter/queries/FeaturedKeywords';
import { GiftTicketLogQuery } from 'interfaceAdapter/queries/GiftTicketLog';
import { IntroducedUserReviewsQuery } from 'interfaceAdapter/queries/IntroducedUserReviews';
import { IntroductionCompanySurveyQuery } from 'interfaceAdapter/queries/IntroductionCompanySurvey';
import { IntroductionUserSurveyQuery } from 'interfaceAdapter/queries/IntroductionUserSurvey';
import { InvitedUsersQuery } from 'interfaceAdapter/queries/InvitedUsers';
import { MeetRequestQuery } from 'interfaceAdapter/queries/MeetRequest';
import { MeetRequestUserSurveyQuery } from 'interfaceAdapter/queries/MeetRequestUserSurvey';
import { MessageQuery } from 'interfaceAdapter/queries/Message';
import { MyCompanyQuery } from 'interfaceAdapter/queries/MyCompany';
import { MySelfQuery } from 'interfaceAdapter/queries/MySelft';
import { PaymentBillQuery } from 'interfaceAdapter/queries/PaymentBillQuery';
import { PaymentQuery } from 'interfaceAdapter/queries/PaymentQuery';
import { ProjectQuery } from 'interfaceAdapter/queries/Project';
import { ProjectTicketConsumptionQuery } from 'interfaceAdapter/queries/ProjectTicketConsumptionQuery';
import { PublicInfoQuery } from 'interfaceAdapter/queries/PublicInfo';
import { PublicProjectQuery } from 'interfaceAdapter/queries/PublicProject';
import { PublicProjectsForAcaricQuery } from 'interfaceAdapter/queries/PublicProjectsForAcaric';
import { PublicRewardsQuery } from 'interfaceAdapter/queries/PublicRewards';
import { PublicSpreaderQuery } from 'interfaceAdapter/queries/PublicSpreader';
import { QuestionnaireQuery } from 'interfaceAdapter/queries/Questionnaire';
import { TeamQuery } from 'interfaceAdapter/queries/Team';
import { SurveyCodeQuery } from 'interfaceAdapter/queries/SurveyCode';
import { TermsAgreementQuery } from 'interfaceAdapter/queries/TermsAgreement';
import { UserGiftQuery } from 'interfaceAdapter/queries/UserGift';
import { UserNewsQuery } from 'interfaceAdapter/queries/UserNews';
import { UserNotificationQuery } from 'interfaceAdapter/queries/UserNotification';
import { UserSegmentQuery } from 'interfaceAdapter/queries/UserSegment';
import { WebinarGuestQuery } from 'interfaceAdapter/queries/WebinarGuest';
import { CheckUserTokenRepository } from 'interfaceAdapter/repositories/CheckUserToken';
import { CompanyBillingRepository } from 'interfaceAdapter/repositories/CompanyBilling';
import { CompanyBillingSettingRepository } from 'interfaceAdapter/repositories/CompanyBillingSettingRepository';
import { CompanyCastedUsersRepository } from 'interfaceAdapter/repositories/CompanyCastedUsers';
import { CompanyChatRoomRepository } from 'interfaceAdapter/repositories/CompanyChatRoom';
import { CompanyMeetRequestRepository } from 'interfaceAdapter/repositories/CompanyMeetRequest';
import { CompanyMoneyForwardDepartmentRepository } from 'interfaceAdapter/repositories/CompanyMoneyForwardDepartment';
import { CompanyPaymentSessionRepository } from 'interfaceAdapter/repositories/CompanyPaymentSession';
import { CompanyProjectRepository } from 'interfaceAdapter/repositories/CompanyProject';
import { CompanyUserRepository } from 'interfaceAdapter/repositories/CompanyUser';
import { CompanyUserTokenRepository } from 'interfaceAdapter/repositories/CompanyUserToken';
import { FeedbackRepository } from 'interfaceAdapter/repositories/Feedback';
import { GiftRepository } from 'interfaceAdapter/repositories/Gift';
import { IntroTextRepository } from 'interfaceAdapter/repositories/IntroText';
import { IntroductionRepository } from 'interfaceAdapter/repositories/Introduction';
import { InvitationRepository } from 'interfaceAdapter/repositories/Invitation';
import { MessageRepository } from 'interfaceAdapter/repositories/Message';
import { TeamSwitchRepository } from 'interfaceAdapter/repositories/TeamSwitch';
import {
  KEY as MissionClearVisibilityKey,
  PREFIX as MissionClearVisibilityPrefix,
  MissionClearVisibilityRepository,
} from 'interfaceAdapter/repositories/MissionClearVisibility';
import { MySelfRepository } from 'interfaceAdapter/repositories/MySelf';
import {
  KEY as IProjectTutorialVisibilityKey,
  PREFIX as IProjectTutorialVisibilityPrefix,
  ProjectTutorialVisibilityRepository,
} from 'interfaceAdapter/repositories/ProjectTutorialVisibility';
import { QuestionnaireRepository } from 'interfaceAdapter/repositories/Questionnaire';
import { ReadLineNoticeRepository } from 'interfaceAdapter/repositories/ReadLineNotice';
import { ReadNewStampsRepository } from 'interfaceAdapter/repositories/ReadNewStamps';
import { ShipInfoRepository } from 'interfaceAdapter/repositories/ShipInfo';
import { TermsAgreementRepository } from 'interfaceAdapter/repositories/TermsAgreement';
import { TutorialRepository } from 'interfaceAdapter/repositories/Tutorial';
import { UploadFileRepository } from 'interfaceAdapter/repositories/UploadFile';
import { UserRepository } from 'interfaceAdapter/repositories/User';
import { UserLoginRepository } from 'interfaceAdapter/repositories/UserLogin';
import { UserMeetRequestRepository } from 'interfaceAdapter/repositories/UserMeetRequest';
import { UserTokenRepository } from 'interfaceAdapter/repositories/UserToken';
import { NewTeamRepository } from 'interfaceAdapter/repositories/NewTeam';
import { DraftProjectIdReservationRepository } from 'interfaceAdapter/repositories/DraftProjectIdReservation';
import { CastCodeRepository } from 'interfaceAdapter/repositories/CastCode';
import { MatchPointsRepository } from 'interfaceAdapter/repositories/MatchPoints';
import { container } from 'tsyringe';
import { LineAccountLinkRequestRepository } from 'interfaceAdapter/repositories/LineAccountLinkRequest';
import { LineAccountLinkRequestClient } from 'infrastructure/apiClients/LineAccountLinkRequest/LineAccountLinkRequestClient';
import { LineAccountLinkCallbackRepository } from 'interfaceAdapter/repositories/LineAccountLinkCallback';
import { LineAccountLinkCallbackClient } from 'infrastructure/apiClients/LineAccountLinkCallback/LineAccountLinkCallbackClient';
import { LineAccountLinkCompleteClient } from 'infrastructure/apiClients/LineAccountLinkComplete/LineAccountLinkCompleteClient';
import { LineAccountLinkCompleteRepository } from 'interfaceAdapter/repositories/LineAccountLinkComplete';

// Query
container.register('IProjectTicketConsumptionQuery', { useClass: ProjectTicketConsumptionQuery });
container.register('IProjectTicketConsumptionClient', { useClass: ProjectTicketConsumptionClient });
container.register('ICompanyDraftProjectQuery', { useClass: CompanyDraftProjectQuery });
container.register('IPaymentBillQuery', { useClass: PaymentBillQuery });
container.register('IPaymentBillClient', { useClass: PaymentBillClient });
container.register('IPaymentQuery', { useClass: PaymentQuery });
container.register('IPaymentClient', { useClass: PaymentClient });
container.register('IAppliedProjectsQuery', { useClass: AppliedProjectsQuery });
container.register('ICastCompaniesQuery', { useClass: CastCompaniesQuery });
container.register('IChatRoomQuery', { useClass: ChatRoomQuery });
container.register('ICompanyCastedUsersQuery', { useClass: CompanyCastedUsersQuery });
container.register('ICompanyNewsQuery', { useClass: CompanyNewsQuery });
container.register('ICompanyProjectQuery', { useClass: CompanyProjectQuery });
container.register('ICompanyQuery', { useClass: CompanyQuery });
container.register('ICompanySearchQuery', { useClass: CompanySearchQuery });
container.register('ICompanyUserQuery', { useClass: CompanyUserQuery });
container.register('IFeaturedKeywordsQuery', { useClass: FeaturedKeywordsQuery });
container.register('IGiftTicketLogQuery', { useClass: GiftTicketLogQuery });
container.register('IIntroducedUserReviewsQuery', { useClass: IntroducedUserReviewsQuery });
container.register('IIntroductionCompanySurveyQuery', { useClass: IntroductionCompanySurveyQuery });
container.register('IIntroductionUserSurveyQuery', { useClass: IntroductionUserSurveyQuery });
container.register('IInvitedUsersQuery', { useClass: InvitedUsersQuery });
container.register('IMeetRequestQuery', { useClass: MeetRequestQuery });
container.register('IMeetRequestUserSurveyQuery', { useClass: MeetRequestUserSurveyQuery });
container.register('IMessageQuery', { useClass: MessageQuery });
container.register('IMyCompanyQuery', { useClass: MyCompanyQuery });
container.register('IMySelfQuery', { useClass: MySelfQuery });
container.register('IProjectQuery', { useClass: ProjectQuery });
container.register('IPublicInfoQuery', { useClass: PublicInfoQuery });
container.register('IPublicProjectQuery', { useClass: PublicProjectQuery });
container.register('IPublicProjectsForAcaricQuery', { useClass: PublicProjectsForAcaricQuery });
container.register('IPublicSpreaderQuery', { useClass: PublicSpreaderQuery });
container.register('IQuestionnaireQuery', { useClass: QuestionnaireQuery });
container.register('ITeamQuery', { useClass: TeamQuery });
container.register('ITermsAgreementQuery', { useClass: TermsAgreementQuery });
container.register('IUserGiftQuery', { useClass: UserGiftQuery });
container.register('IUserNewsQuery', { useClass: UserNewsQuery });
container.register('IUserNotificationQuery', { useClass: UserNotificationQuery });
container.register('IWebinarGuestQuery', { useClass: WebinarGuestQuery });

// Repository
container.register('IIntroductionRepository', { useClass: IntroductionRepository });
container.register('IChatRoomQuery', { useClass: ChatRoomQuery });
container.register('ICheckUserTokenRepository', { useClass: CheckUserTokenRepository });
container.register('INewTeamRepository', { useClass: NewTeamRepository });
container.register('ICompanyBillingRepository', { useClass: CompanyBillingRepository });
container.register('ICompanyCastedUsersRepository', { useClass: CompanyCastedUsersRepository });
container.register('ICompanyChatRoomRepository', { useClass: CompanyChatRoomRepository });
container.register('ICompanyMeetRequestRepository', { useClass: CompanyMeetRequestRepository });
container.register('ICompanyProjectRepository', { useClass: CompanyProjectRepository });
container.register('ICompanyQuotationQuery', { useClass: CompanyQuotationQuery });
container.register('ICompanyMoneyForwardDepartmentsQuery', {
  useClass: CompanyMoneyForwardDepartmentsQuery,
});
container.register('ICompanyPaymentSessionRepository', {
  useClass: CompanyPaymentSessionRepository,
});
container.register('ICompanyStatsQuery', { useClass: CompanyStatsQuery });
container.register('ICompanyUserRepository', { useClass: CompanyUserRepository });
container.register('ICompanyUserTokenRepository', { useClass: CompanyUserTokenRepository });
container.register('ICompanyMoneyForwardDepartmentRepository', {
  useClass: CompanyMoneyForwardDepartmentRepository,
});
container.register('ICompanyBillingSettingRepository', {
  useClass: CompanyBillingSettingRepository,
});
container.register('ICompanyBillingSettingQuery', { useClass: CompanyBillingSettingQuery });
container.register('IShipInfoRepository', { useClass: ShipInfoRepository });
container.register('IFeedbackRepository', { useClass: FeedbackRepository });
container.register('IGiftRepository', { useClass: GiftRepository });
container.register('IIntroTextRepository', { useClass: IntroTextRepository });
container.register('IInvitationRepository', { useClass: InvitationRepository });
container.register('IMessageRepository', { useClass: MessageRepository });
container.register('ITeamSwitchRepository', { useClass: TeamSwitchRepository });
container.register('IMissionClearVisibilityRepository', {
  useClass: MissionClearVisibilityRepository,
});
container.register('IMySelfQuery', { useClass: MySelfQuery });
container.register('IMySelfRepository', { useClass: MySelfRepository });
container.register('IPublicRewardsQuery', { useClass: PublicRewardsQuery });
container.register('IQuestionnaireRepository', { useClass: QuestionnaireRepository });
container.register('IReadNewStampsRepository', { useClass: ReadNewStampsRepository });
container.register('IReadLineNoticeRepository', { useClass: ReadLineNoticeRepository });
container.register('ISurveyCodeQuery', { useClass: SurveyCodeQuery });
container.register('ITermsAgreementRepository', { useClass: TermsAgreementRepository });
container.register('ITutorialRepository', { useClass: TutorialRepository });
container.register('IUploadFileRepository', { useClass: UploadFileRepository });
container.register('IUserLoginRepository', { useClass: UserLoginRepository });
container.register('IUserMeetRequestRepository', { useClass: UserMeetRequestRepository });
container.register('IUserRepository', { useClass: UserRepository });
container.register('IUserSegmentQuery', { useClass: UserSegmentQuery });
container.register('IUserTokenRepository', { useClass: UserTokenRepository });
container.register('IProjectTutorialVisibilityRepository', {
  useClass: ProjectTutorialVisibilityRepository,
});
container.register('ILineAccountLinkRequestRepository', {
  useClass: LineAccountLinkRequestRepository,
});
container.register('ILineAccountLinkCallbackRepository', {
  useClass: LineAccountLinkCallbackRepository,
});
container.register('ILineAccountLinkCompleteRepository', {
  useClass: LineAccountLinkCompleteRepository,
});

// Repository Storage
container.register('IMissionClearVisibilityStorage', {
  useFactory: () => new CookieUsable(MissionClearVisibilityPrefix, MissionClearVisibilityKey),
});
container.register('IProjectTutorialVisibilityStorage', {
  useFactory: () =>
    new CookieUsable(IProjectTutorialVisibilityPrefix, IProjectTutorialVisibilityKey),
});
container.register('IDraftProjectIdReservationRepository', {
  useClass: DraftProjectIdReservationRepository,
});
container.register('ICastCodeRepository', { useClass: CastCodeRepository });
container.register('IMatchPointsRepository', {
  useFactory: () => {
    return (castCode: string) => new MatchPointsRepository(castCode);
  },
});

// Client
container.register('IAnswerIntroductionSurveyForCompanyClient', {
  useClass: AnswerIntroductionSurveyForCompanyClient,
});
container.register('IAnswerIntroductionSurveyForUserClient', {
  useClass: AnswerIntroductionSurveyForUserClient,
});
container.register('IAnswerMeetRequestSurveyForCompanyClient', {
  useClass: AnswerMeetRequestSurveyForCompanyClient,
});
container.register('IAnswerMeetRequestSurveyForUserClient', {
  useClass: AnswerMeetRequestSurveyForUserClient,
});
container.register('IBulkProjectClient', { useClass: BulkProjectClient });
container.register('IBusinessChatClient', { useClass: BusinessChatClient });
container.register('ICastCompaniesClient', { useClass: CastCompaniesClient });
container.register('IChatRoomsClient', { useClass: ChatRoomsClient });
container.register('IChatsClient', { useClass: ChatsClient });
container.register('IChatUnArchiveClient', { useClass: ChatUnArchiveClient });
container.register('ICheckTokenClient', { useClass: CheckTokenClient });
container.register('IClosingSoonProjectsClient', { useClass: ClosingSoonProjectsClient });
container.register('ICompanyCastedUsersClient', { useClass: CompanyCastedUsersClient });
container.register('ICompanyCastingChatArchiveClient', {
  useClass: CompanyCastingChatArchiveClient,
});
container.register('ICompanyChatRoomMeetRequestsClient', {
  useClass: CompanyChatRoomMeetRequestsClient,
});
container.register('ICompanyChatRoomMembersEditClient', {
  useClass: CompanyChatRoomMembersEditClient,
});
container.register('ICompanyChatRoomMembersGetClient', {
  useClass: CompanyChatRoomMembersGetClient,
});
container.register('ICompanyChatRoomMessageClient', { useClass: CompanyChatRoomMessageClient });
container.register('ICompanyChatRoomMessagesClient', { useClass: CompanyChatRoomMessagesClient });
container.register('ICompanyCheckTokenClient', { useClass: CompanyCheckTokenClient });
container.register('ICompanyClient', { useClass: CompanyClient });
container.register('ICompanyContractClient', { useClass: CompanyContractClient });
container.register('ICompanyDashboardProjectsClient', { useClass: CompanyDashboardProjectsClient });
container.register('ICompanyEditClient', { useClass: CompanyEditClient });
container.register('ICompanyIntroductionChatRoomsClient', {
  useClass: CompanyIntroductionChatRoomsClient,
});
container.register('ICompanyLoginByEmailClient', { useClass: CompanyLoginByEmailClient });
container.register('ICompanyMeetRequestChatRoomsClient', {
  useClass: CompanyMeetRequestChatRoomsClient,
});
container.register('ICompanyMeetRequestClient', { useClass: CompanyMeetRequestClient });
container.register('ICompanyMeetRequestMeetingAtClient', {
  useClass: CompanyMeetRequestMeetingAtClient,
});
container.register('ICompanyMeetRequestsClient', { useClass: CompanyMeetRequestsClient });
container.register('ICompanyNewsClient', { useClass: CompanyNewsClient });
container.register('ICompanyProjectClient', { useClass: CompanyProjectClient });
container.register('ICompanyProjectCloseClient', { useClass: CompanyProjectCloseClient });
container.register('ICompanyProjectCreateClient', { useClass: CompanyProjectCreateClient });
container.register('ICompanyProjectEditClient', { useClass: CompanyProjectEditClient });
container.register('ICompanyProjectPendingClient', { useClass: CompanyProjectPendingClient });
container.register('ICompanyProjectReopenClient', { useClass: CompanyProjectReopenClient });
container.register('ICompanyProjectsClient', { useClass: CompanyProjectsClient });
container.register('ICompanySendPasswordResetEmailClient', {
  useClass: CompanySendPasswordResetEmailClient,
});
container.register('ICompanySpreadersClient', { useClass: CompanySpreadersClient });
container.register('ICompanyStatsClient', { useClass: CompanyStatsClient });
container.register('ICompanySupportChatClient', { useClass: CompanySupportChatClient });
container.register('ISupportChatMessageClient', { useClass: SupportChatMessageClient });
container.register('ICompanySurveyCodeClient', { useClass: CompanySurveyCodeClient });
container.register('ICompanySearchByNameClient', { useClass: CompanySearchByNameClient });
container.register('ICompanySearchByNumberClient', { useClass: CompanySearchByNumberClient });
container.register('ICompanyTicketQuotationClient', { useClass: CompanyTicketQuotationClient });
container.register('ICompanyUnreadAllChatRoomsClient', {
  useClass: CompanyUnreadAllChatRoomsClient,
});
container.register('ICompanyUserClient', {
  useClass: CompanyUserClient,
});
container.register('IEditOwnCompanyUserClient', { useClass: EditOwnCompanyUserClient });
container.register('IEditOtherCompanyUserClient', { useClass: EditOtherCompanyUserClient });
container.register('ICompanyUserLogoutClient', { useClass: CompanyUserLogoutClient });
container.register('ICompanyUserPasswordResetClient', { useClass: CompanyUserPasswordClient });
container.register('ICompanyUsersClient', { useClass: CompanyUsersClient });
container.register('ICompanyMoneyForwardDepartmentsClient', {
  useClass: CompanyMoneyForwardDepartmentsClient,
});
container.register('ICompanyCompletePaymentSessionClient', {
  useClass: CompanyCompletePaymentSessionClient,
});
container.register('ICompanyBillingSettingClient', { useClass: CompanyBillingSettingClient });
container.register('IConfirmEmailClient', { useClass: ConfirmEmailClient });
container.register('ICurrentRewardProgressClient', { useClass: CurrentRewardProgressClient });
container.register('IShipInfoClient', { useClass: ShipInfoClient });
container.register('IEditCastedUserDataClient', { useClass: EditCastedUserDataClient });
container.register('IExchangedGiftsClient', { useClass: ExchangedGiftsClient });
container.register('IExchangeGiftsClient', { useClass: ExchangeGiftsClient });
container.register('IFeaturedKeywordsClient', { useClass: FeaturedKeywordsClient });
container.register('IFeedbackClient', { useClass: FeedbackClient });
container.register('IFinishTutorialClient', { useClass: FinishTutorialClient });
container.register('IFirstContactBillingClient', { useClass: FirstContactBillingClient });
container.register('IFrequencyWordProjectsClient', { useClass: FrequencyWordProjectsClient });
container.register('IGenerateFacebookAuthTokenClient', {
  useClass: GenerateFacebookAuthTokenClient,
});
container.register('IGenerateFacebookAuthUrlClient', { useClass: GenerateFacebookAuthUrlClient });
container.register('IGiftsClient', { useClass: GiftsClient });
container.register('IGiftTicketLogClient', { useClass: GiftTicketLogClient });
container.register('IGiftTicketProgressClient', { useClass: GiftTicketProgressClient });
container.register('IIntroducedUserReviewsClient', { useClass: IntroducedUserReviewsClient });
container.register('IIntroductionSurveyForCompanyClient', {
  useClass: IntroductionSurveyForCompanyClient,
});
container.register('IIntroductionSurveyForUserClient', {
  useClass: IntroductionSurveyForUserClient,
});
container.register('IInvitationCodeClient', { useClass: InvitationCodeClient });
container.register('IInvitationUrlClient', { useClass: InvitationUrlClient });
container.register('IInvitationWriteIntroductionClient', {
  useClass: InvitationWriteIntroductionClient,
});
container.register('IInvitedUsersClient', { useClass: InvitedUsersClient });
container.register('IInviterIntroductionClient', { useClass: InviterIntroductionClient });
container.register('ILoginByEmailClient', { useClass: LoginByEmailClient });
container.register('ILoginByFacebookClient', { useClass: LoginByFacebookClient });
container.register('ILogoutClient', { useClass: LogoutClient });
container.register('IInvitedApplicationClient', { useClass: InvitedApplicationClient });
container.register('IMeetRequestByRoomIdClient', { useClass: MeetRequestByRoomIdClient });
container.register('IMeetRequestSurveyForCompanyClient', {
  useClass: MeetRequestSurveyForCompanyClient,
});
container.register('IMeetRequestSurveyForUserClient', { useClass: MeetRequestSurveyForUserClient });
container.register('IMembersClient', { useClass: MembersClient });
container.register('IMessagesClient', { useClass: MessagesClient });
container.register('IMyCompanyClient', { useClass: MyCompanyClient });
container.register('IMyIntroducedProjectsClient', { useClass: MyIntroducedProjectsClient });
container.register('IMySelfClient', { useClass: MySelfClient });
container.register('INextRewardsClient', { useClass: NextRewardsClient });
container.register('IPointLogClient', { useClass: PointLogClient });
container.register('IPointRankingClient', { useClass: PointRankingClient });
container.register('IPreCompanyUserDeleteClient', { useClass: PreCompanyUserDeleteClient });
container.register('IPreCompanyUserEmailClient', { useClass: PreCompanyUserEmailClient });
container.register('IPreCompanyUserGetClient', { useClass: PreCompanyUserGetClient });
container.register('IProgressSpreaderChallengeClient', {
  useClass: ProgressSpreaderChallengeClient,
});
container.register('IProjectClient', { useClass: ProjectClient });
container.register('IProjectByCodeClient', { useClass: ProjectByCodeClient });
container.register('IProjectQuotationClient', { useClass: ProjectQuotationClient });
container.register('IProjectsAssignedClient', { useClass: ProjectsAssignedClient });
container.register('IPublicCompanyLogosClient', { useClass: PublicCompanyLogosClient });
container.register('IPublicProjectClient', { useClass: PublicProjectClient });
container.register('IPublicRewardsClient', { useClass: PublicRewardsClient });
container.register('IPublicSpreaderClient', { useClass: PublicSpreaderClient });
container.register('IPublicStatsClient', { useClass: PublicStatsClient });
container.register('IPushedProjectsClient', { useClass: PushedProjectsClient });
container.register('IPushedProjectsForAcaricClient', { useClass: PushedProjectsForAcaricClient });
container.register('IQuestionnaireClient', { useClass: QuestionnaireClient });
container.register('IReadNewStampsClient', { useClass: ReadNewStampsClient });
container.register('IRegisterClient', { useClass: RegisterClient });
container.register('IRejectCastingClient', { useClass: RejectCastingClient });
container.register('IResendEmailChangeConfirmClient', { useClass: ResendEmailChangeConfirmClient });
container.register('IResetPasswordClient', { useClass: ResetPasswordClient });
container.register('ISegmentsClient', { useClass: SegmentsClient });
container.register('ISelfApplicationClient', { useClass: SelfApplicationClient });
container.register('ISendEmailChangeEmailClient', { useClass: SendEmailChangeEmailClient });
container.register('ISendFirstContactClient', { useClass: SendFirstContactClient });
container.register('ISendMessageClient', { useClass: SendMessageClient });
container.register('ISendPasswordResetEmailClient', { useClass: SendPasswordResetEmailClient });
container.register('ISkillSegmentsClient', { useClass: SkillSegmentsClient });
container.register('ISpreaderChallengeStatusClient', { useClass: SpreaderChallengeStatusClient });
container.register('ISupportChatClient', { useClass: SupportChatClient });
container.register('ISupportMessageClient', { useClass: SupportMessageClient });
container.register('ITeamListClient', { useClass: TeamListClient });
container.register('ISurveyCodeClient', { useClass: SurveyCodeClient });
container.register('ITermsAgreementClient', { useClass: TermsAgreementClient });
container.register('ITestSlackWebhookClient', { useClass: TestSlackWebhookClient });
container.register('IUpdateFacebookIdClient', { useClass: UpdateFacebookIdClient });
container.register('IUploadFileClient', { useClass: UploadFileClient });
container.register('IUserNotificationClient', { useClass: UserNotificationClient });
container.register('IUserMeetRequestAcceptClient', { useClass: UserMeetRequestAcceptClient });
container.register('ITeamSwitchClient', { useClass: TeamSwitchClient });
container.register('ITeamMemberDisableMyselfClient', {
  useClass: TeamMemberDisableMyselfClient,
});
container.register('IUserMeetRequestDeclineClient', { useClass: UserMeetRequestDeclineClient });
container.register('IUserMeetRequestsClient', { useClass: UserMeetRequestsClient });
container.register('IUserNewsClient', { useClass: UserNewsClient });
container.register('IReadNewsClient', { useClass: ReadNewsClient });
container.register('IUserProfileCoverageClient', { useClass: UserProfileCoverageClient });
container.register('IVerifyEmailChangeCodeClient', { useClass: VerifyEmailChangeCodeClient });
container.register('IWaitingIntroductionSelfAppliedClient', {
  useClass: WaitingIntroductionSelfAppliedClient,
});
container.register('IWithdrawalClient', { useClass: WithdrawalClient });
container.register('IDraftProjectAssetClient', { useClass: DraftProjectAssetClient });
container.register('IDraftProjectRequestClient', { useClass: DraftProjectRequestClient });
container.register('IWebinarGuestClient', { useClass: WebinarGuestClient });
container.register('IProjectBillingsClient', { useClass: ProjectBillingsClient });
container.register('IProjectTutorialClient', { useClass: ProjectTutorialClient });
container.register('ICompanyRegistrationSendMailClient', {
  useClass: CompanyRegistrationSendMailClient,
});
container.register('ICompanyInvitedRegistrationSendMailClient', {
  useClass: CompanyInvitedRegistrationSendMailClient,
});
container.register('ICompanyInvitedRegistrationUserProfileClient', {
  useClass: CompanyInvitedRegistrationUserProfileClient,
});
container.register('IPreCompanyUserByUrlIdClient', { useClass: PreCompanyUserByUrlIdClient });
container.register('IPreCompanyUserByUrlIdFromInviteClient', {
  useClass: PreCompanyUserByUrlIdFromInviteClient,
});
container.register('ICompanyRegistrationPasswordClient', {
  useClass: CompanyRegistrationPasswordClient,
});
container.register('ICompanyUserProfileRegistrationClient', {
  useClass: CompanyUserProfileRegistrationClient,
});
container.register('ICompanyUserRegistrationConfirmClient', {
  useClass: CompanyUserRegistrationConfirmClient,
});
container.register('ICompanyDraftProjectEditClient', { useClass: CompanyDraftProjectEditClient });
container.register('ICompanyDraftProjectCreateClient', {
  useClass: CompanyDraftProjectCreateClient,
});
container.register('ICompanyDraftProjectsClient', { useClass: CompanyDraftProjectsClient });
container.register('ICompanyDraftProjectClient', { useClass: CompanyDraftProjectClient });
container.register('ICompanyDraftProjectDeleteClient', {
  useClass: CompanyDraftProjectDeleteClient,
});
container.register('ITeamCreateClient', { useClass: TeamCreateClient });
container.register('ILineAccountLinkRequestClient', {
  useClass: LineAccountLinkRequestClient,
});
container.register('ILineAccountLinkCallbackClient', {
  useClass: LineAccountLinkCallbackClient,
});
container.register('ILineAccountLinkCompleteClient', {
  useClass: LineAccountLinkCompleteClient,
});

// Factory
container.register('ICastingFactory', { useClass: CastingFactory });
container.register('IChatRoomFactory', { useClass: ChatRoomFactory });
container.register('ICompanyContractFactory', { useClass: CompanyContractFactory });
container.register('ICompanyFactory', { useClass: CompanyFactory });
container.register('ICompanyUserFactory', { useClass: CompanyUserFactory });
container.register('ICurrentRewardProgressFactory', { useClass: CurrentRewardProgressFactory });
container.register('IGiftTicketLogFactory', { useClass: GiftTicketLogFactory });
container.register('IGiftTicketProgressFactory', { useClass: GiftTicketProgressFactory });
container.register('IIntroductionSurveyFactory', { useClass: IntroductionSurveyFactory });
container.register('IMeetRequestFactory', { useClass: MeetRequestFactory });
container.register('IMeetRequestSurveyFactory', { useClass: MeetRequestSurveyFactory });
container.register('IMessageFactory', { useClass: MessageFactory });
container.register('INewsFactory', { useClass: NewsFactory });
container.register('IPreCompanyUserFactory', { useClass: PreCompanyUserFactory });
container.register('IProjectFactory', { useClass: ProjectFactory });
container.register('IQuestionnaireFactory', { useClass: QuestionnaireFactory });
container.register('IRewardFactory', { useClass: RewardFactory });
container.register('ISpreaderChallengeFactory', { useClass: SpreaderChallengeFactory });
container.register('ITeamFactory', { useClass: TeamFactory });
container.register('IUserFactory', { useClass: UserFactory });
container.register('IUserNotificationFactory', { useClass: UserNotificationFactory });
container.register('IWebinarGuestFactory', { useClass: WebinarGuestFactory });
container.register('IProjectBillingsFactory', { useClass: ProjectBillingsFactory });

// UseCase
container.register('ICompanyUserLogout', { useClass: CompanyUserLogout });
container.register('IUserClearAllStorages', { useClass: UserClearAllStorages });
container.register('IUserConfirmEmail', { useClass: UserConfirmEmail });
container.register('IUserLogout', { useClass: UserLogout });

export { container };
