import { Fragment } from 'react';
import { Link as OriginalLink } from 'react-router-dom';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { NotificationBadge } from 'ui/components/company/elements/NotificationBadge/NotificationBadge';
import { SIDEBAR_GROUPS, useSidebar } from './useSidebar';

const Wrapper = styled.div`
  height: 100%;
  padding: 4px 0 30px;
  padding-right: 12px;
  background-color: var(--white);
  overflow-y: auto;
`;

const Group = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  gap: 6px;
  width: 100%;
  margin-top: 4px;
  padding: 12px 14px;
  border-radius: 0 30px 30px 0;
  border: none;
  background-color: transparent;
  text-align: left;
  font-size: var(--font-large);
  font-weight: var(--font-bold);
  cursor: pointer;
  transition: background-color 0.2s ease-out;

  &:hover {
    background-color: var(--primary);
  }

  // いずれかのリンクに通知が表示されていたら、グループにもバッジ表示を行う
  &:has(+ .links .notification)::before {
    content: '';
    position: absolute;
    top: 8px;
    left: 8px;
    width: 12px;
    height: 12px;
    background-color: var(--warn);
    border-radius: 50%;
  }
`;

const Icon = styled.img`
  width: 20px;
  height: 20px;
`;

const GroupName = styled.span`
  padding-bottom: 1px;
`;

const ToggleIcon = styled.div<{ open: boolean }>`
  position: relative;
  margin-left: auto;
  &::before {
    content: '';
    width: 5px;
    height: 5px;
    border-top: solid 2px var(--gray-dark-3);
    border-right: solid 2px var(--gray-dark-3);
    position: absolute;
    left: ${(props) => (props.open ? '-9px' : '-10px')};
    top: ${(props) => (props.open ? '-5px' : '-4px')};
    transform: ${(props) => (props.open ? 'rotate(135deg)' : 'rotate(45deg)')};
  }
`;

const Links = styled.div<{ open: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 4px;
  height: 0;
  padding: ${(props) => (props.open ? '8px 4px 10px' : '0')};
  padding-left: 34px;
  opacity: ${(props) => (props.open ? 1 : 0)};
  overflow: hidden;
  transition: all 0.2s ease-out;

  ${(props) =>
    props.open
      ? `
        height: auto; /* fallback */
        height: calc-size(auto, size); /* height: auto を transition させる */
      `
      : ''};
`;

const linkStyle = css`
  width: 90%;
  padding: 4px 0;
  font-size: var(--font-medium);
  font-weight: var(--font-semi-bold);
  color: var(--gray-black);
  transition: opacity 0.1s ease-out;
  &:hover {
    opacity: 0.7;
  }
`;

const Link = styled(OriginalLink)`
  position: relative;
  ${linkStyle}
`;

const ExternalLink = styled.a`
  ${linkStyle}
`;

const Notification = css`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
`;

const Description = styled.p`
  position: relative;
  margin: 8px 8px 8px -14px;
  padding: 8px 12px;
  background-color: var(--primary-pale);
  border-radius: 10px;
  font-size: var(--font-default);
  line-height: 1.5;
`;

export const Sidebar: React.FC = () => {
  const { showSidebar, pageVisibility, notifications, openState, toggleOpenState } = useSidebar();

  if (!showSidebar) {
    return null;
  }

  return (
    <Wrapper>
      {SIDEBAR_GROUPS.map((group, index) => (
        <Fragment key={group.name}>
          <Group onClick={() => toggleOpenState(index)}>
            <Icon src={group.icon} alt={`${group.name}のアイコン`} />
            <GroupName>{group.name}</GroupName>
            <ToggleIcon open={openState.has(index)} />
          </Group>

          <Links className="links" open={openState.has(index)}>
            {group.links.map((link, i) => (
              <Fragment key={i}>
                {link.path ? (
                  <Link
                    to={link.path}
                    tabIndex={openState.has(index) ? 0 : -1}
                    // 動的に非表示する対象が1件だけなのでここで判定。増えてきたら別途関数化する。
                    hidden={link.key === 'contracts' && !pageVisibility.companyContract}
                  >
                    {link.key && notifications[link.key] > 0 && (
                      <NotificationBadge
                        className="notification"
                        size={18}
                        count={notifications[link.key]}
                        css={Notification}
                      />
                    )}

                    {link.name}
                  </Link>
                ) : (
                  <ExternalLink
                    href={link.href}
                    target="_blank"
                    rel="noopener"
                    tabIndex={openState.has(index) ? 0 : -1}
                  >
                    {link.name}
                  </ExternalLink>
                )}

                {link.description && <Description>{link.description}</Description>}
              </Fragment>
            ))}
          </Links>
        </Fragment>
      ))}
    </Wrapper>
  );
};
