import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { PublicHeader } from 'ui/components/user/features/PublicHeader/PublicHeader';
import { useIntroductionUserSurvey } from './useIntroductionUserSurvey';
import { SurveyQuestion } from 'ui/components/user/patterns/SurveyQuestion/SurveyQuestion';
import { SurveyReviewForm } from 'ui/components/user/patterns/SurveyReviewForm/SurveyReviewForm';
import { Button } from 'ui/components/user/elements/Button/Button';

const OverFlowY = styled.div`
  overflow-y: auto;
  height: 100vh;
  @supports (height: 100svh) {
    height: 100svh;
  }
`;

const Container = styled.div`
  line-height: 1.6em;
  padding: 20px 10px;
  max-width: 860px;
  margin: 0 auto;
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: 700;
  padding-bottom: 20px;
`;

const Description = styled.div`
  font-size: 12px;
  line-height: 1.5em;
  padding: 10px 0 40px 0;
`;

const ThanksMsg = styled.div`
  line-height: 1.6em;
  padding: 20px 10px;
  max-width: 860px;
  margin: 0 auto;
`;

const ButtonContainer = styled.div`
  text-align: center;
  width: 100%;
  padding: 15px;

  button {
    white-space: nowrap;
  }
`;

export const IntroductionUserSurvey: FC = () => {
  const { loading, survey, complete, fetchSurveyDataForUser, answerIntroductionSurveyForUser } =
    useIntroductionUserSurvey();
  const navigate = useNavigate();

  useEffect(() => {
    fetchSurveyDataForUser();
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const onSubmit = handleSubmit(async (data) => {
    const reviewText = data.review;
    delete data.review;
    const answers = survey?.question_data.map((question) => {
      // 空文字の場合はnullに変換する必要がある
      return data[question.id] || data[question.id] === 0 ? data[question.id] : null;
    });

    answers && answerIntroductionSurveyForUser(answers, reviewText);
  });

  return (
    <OverFlowY>
      <PublicHeader />
      {survey && (
        <Container>
          {complete ? (
            <>
              <ThanksMsg>アンケートへのご回答ありがとうございました。</ThanksMsg>
              <ButtonContainer>
                <Button
                  status="primary"
                  padding="12px 18px"
                  fontSize="14px"
                  disabled={loading}
                  onClick={() => navigate('/login/')}
                >
                  Spreadyへログイン
                </Button>
              </ButtonContainer>
            </>
          ) : (
            <>
              <Title>{survey.title}</Title>
              <Description>
                「{survey.project_title}」に関する
                {survey.company_name &&
                  `「${survey.company_name}
                」社との面談に関しての`}
                アンケートに、ご回答のご協力お願い致します。
                <br />
                このアンケートの内容は、{survey.casted_user_name}
                様のご了承なしに企業に公開されることはありません。
              </Description>
              {survey.question_data.map((question, index) => (
                <SurveyQuestion
                  key={question.id}
                  index={index + 1}
                  question={question}
                  errors={errors}
                  register={register}
                  setValue={setValue}
                />
              ))}
              <Title>
                {survey.company_name
                  ? `${survey.company_name}
                社`
                  : '面談した企業様'}
                へのレビューを募集しています
              </Title>
              <b>このアンケートの内容はアイコンの写真とともに公開される可能性があります。</b>
              <SurveyReviewForm
                formKey="review"
                title="レビュー"
                placeholder="新しいPJの話が聞けてわくわくしました。自分の経験が思わぬ形で役立つことがわかり、有意義な時間でした。〇〇業界の方におすすめのセッションです。きっと自分の可能性の広がりに気づけるかも。"
                required={false}
                register={register}
                maxLength={1000}
              />
              <ButtonContainer>
                <Button
                  status="primary"
                  padding="12px 18px"
                  fontSize="14px"
                  disabled={loading}
                  onClick={() => onSubmit()}
                >
                  回答する
                </Button>
              </ButtonContainer>
            </>
          )}
        </Container>
      )}
    </OverFlowY>
  );
};
