import styled from '@emotion/styled';
import { RoomListItem } from 'ui/components/user/patterns/RoomListItem/RoomListItem';
import { ChatTypeFilter } from 'ui/components/user/features/ChatTypeFilter/ChatTypeFilter';
import { Input } from 'ui/components/user/elements/Input/Input';
import { useRoomList } from './useRoomList';
import { mqUser } from 'utils/responsive';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  border-radius: 16px;
  background-color: var(--spr-white);
  box-shadow: 0px 2px 4px 0px #0000000d;
`;

const Header = styled.div`
  display: grid;
  grid-template-areas: 'title filter' 'search search';
  row-gap: 16px;
  padding: 8px 8px 20px;
  ${mqUser('SP')} {
    padding: 0 4px 20px;
  }
`;

const Title = styled.p`
  grid-area: title;
  place-self: center start;
  margin: 0;
  font-size: 18px;
  font-weight: 700;
`;

const Filter = styled.div`
  grid-area: filter;
  justify-self: end;
`;

const Search = styled.div`
  grid-area: search;
`;

const List = styled.div`
  height: 70vh;
  @supports (height: 70svh) {
    height: 70svh;
  }
  overflow-y: auto;
  ${mqUser('SP')} {
    height: calc(100vh - 150px - var(--height-user-pc-header) - 180px);
    @supports (height: 100svh) {
      height: calc(100svh - 150px - var(--height-user-pc-header) - 180px);
    }
  }
`;

const NoRooms = styled.div`
  padding: 20px;
  text-align: center;
`;

/**
 * チャットルーム内容一覧 表示
 */
export const RoomList: React.FC = () => {
  const {
    rooms,
    currentRoom,
    chatType,
    setChatType,
    query,
    setQuery,
    unreadIds,
    replyRequiredIds,
    onRoomClick,
  } = useRoomList();

  return (
    <Wrapper>
      <Header>
        <Title>メッセージ</Title>
        <Filter>
          <ChatTypeFilter value={chatType} onChange={setChatType} />
        </Filter>
        <Search>
          <Input
            value={query}
            style={{ padding: '6px 16px', fontSize: '12px' }}
            onChange={(e) => setQuery(e.target.value)}
            placeholder="セッション名、企業名、担当者名など"
          />
        </Search>
      </Header>
      <List>
        {rooms.length === 0 && <NoRooms>メッセージがありません。</NoRooms>}
        {rooms.map((room) => (
          <RoomListItem
            key={room.room_id}
            unread={unreadIds.includes(room.room_id)}
            replyRequired={replyRequiredIds.includes(room.room_id)}
            active={room.room_id === currentRoom?.room_id}
            room={room}
            onClick={() => onRoomClick(room.room_id)}
          />
        ))}
      </List>
    </Wrapper>
  );
};
