import styled from '@emotion/styled';
import ImgLeft from 'ui/images/com/meet-request/meet_request_description_left.png';
import ImgRight from 'ui/images/com/meet-request/meet_request_description_right.png';
import { mq } from 'utils/responsive';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 96px;
  margin: 16px 0;
  padding: 16px 24px;
  border-radius: 16px;
  background-color: var(--spr-primary-paler);
  color: var(--gray-dark-3);
`;

const Left = styled.div`
  width: 92px;
  height: 96px;
  ${mq('NARROW')} {
    display: none;
  }
`;

const Body = styled.div`
  font-size: 16px;
  font-weight: var(--font-weight--bold);
  margin: 4px auto 0;
  line-height: 26px;
  padding: 0 16px;
  width: calc(100% - 92px - 99px);
  ${mq('NARROW')} {
    padding: 0;
    width: 100%;
  }
`;

const Annotation = styled.div`
  margin-top: 12px;
  line-height: 20px;
  font-size: 14px;
  font-weight: var(--font-weight--normal);
`;

const Right = styled.div`
  width: 99px;
  height: 96px;
  ${mq('NARROW')} {
    display: none;
  }
`;

/**
 * 会いたいリクエスト 説明文
 */
export const MeetRequestDesc: React.FC = () => {
  return (
    <Wrapper>
      <Left>
        <img src={ImgLeft} width="92" height="96" />
      </Left>
      <Body>
        ユーザーヒアリングしたい対象者を登録ユーザーから検索し、会いたいリクエストを送信することができます。
        <br />
        会いたいリクエストを受け取ったユーザーが会いたいリクエストを承認すると、チャットルームが立ち上がります。
        <br />
        チャットルームにて、通常の応募と同じ形でユーザーヒアリングの調整をすることが可能です。
        <Annotation>
          ※
          会いたいリクエストは何通でも送信が可能ですが、対象セッションでまだチケットが消化されていない場合は、会いたいリクエストを受け取ったユーザーが会いたいリクエストを承認したタイミングで、チケットが消化されます。
          <br />
          （チケットをお持ちでない場合は請求が発生いたします。）
        </Annotation>
      </Body>
      <Right>
        <img src={ImgRight} width="99" height="96" />
      </Right>
    </Wrapper>
  );
};
