import dayjs from 'dayjs';
import { ICompany } from 'domain/entities/Company/Company';
import { ICompanyUser } from 'domain/entities/CompanyUser/CompanyUser';
import {
  CompanyFactory,
  companySchema,
  IPrivacyTeamSchema,
} from 'domain/entities/factories/Company';
import { CompanyUserFactory } from 'domain/entities/factories/CompanyUser';
import {
  COMBINED_TITLE_MAX_LENGTH,
  convertProjectStatusStr,
  IProject,
  Project,
  PROJECT_CATEGORY,
  PROJECT_ISSUE,
  PROJECT_STATUS,
  PROJECT_STATUS_NUMERICS,
  PROJECT_TITLE_OPINION,
  ProjectCategory,
  ProjectIssue,
  ProjectStatus,
  ProjectStatusNumerics,
  ProjectTitleOpinion,
  SPREADY_CHECK,
  SpreadyCheck,
} from 'domain/entities/Project/Project';
import { IntroducedUserReviewFactory } from 'domain/valueObjects/factories/IntroducedUserReview';
import yup from 'utils/yup';

export const projectCategoryOptions = [
  { value: PROJECT_CATEGORY.NORMAL, label: '通常セッション' },
  { value: PROJECT_CATEGORY.EVENT, label: 'イベント参加' },
];

export const projectSchema = yup.object().shape({
  id: yup.number().required(),
  company: yup.mixed<IPrivacyTeamSchema | typeof companySchema>().required(),
  category: yup
    .mixed<ProjectCategory>()
    .oneOf(Object.values(PROJECT_CATEGORY))
    .required()
    .nullable(),
  issues: yup
    .array(yup.mixed<ProjectIssue>().oneOf(Object.values(PROJECT_ISSUE)).required())
    .optional(),
  title: yup.string().required(),
  target_person: yup.string().optional().nullable(),
  topic_of_interest: yup.string().optional().nullable(),
  title_complement_text: yup.string().optional().nullable(),
  title_opinion_type: yup
    .mixed<ProjectTitleOpinion>()
    .oneOf(Object.values(PROJECT_TITLE_OPINION))
    .optional(),
  main_pic: yup.string().required(),
  targets: yup.array(yup.string().required()).required(),
  share_enable: yup.boolean().required(),
  status: yup
    .mixed<ProjectStatusNumerics>()
    .oneOf(Object.values(PROJECT_STATUS_NUMERICS))
    .optional(),
  enable_offline_interview: yup.boolean().optional().default(false),
  casting_count: yup.number().optional(),
  summary: yup.string().default('').defined().nullable(),
  privacy: yup.boolean().optional(),
  number_of_people: yup.number().optional().nullable(),
  meet_request_count: yup.number().optional(),
  meetup_fixed_count: yup.number().optional(),
  cancel_count: yup.number().optional(),
  themes: yup.array(yup.string().required()).optional().default([]),
  match_points: yup.array(yup.string().required()),
  match_point_text: yup.string().optional().default(''),
  background: yup.string().optional().default(''),
  goal: yup.string().optional().default('').nullable(),
  outro: yup.string().optional().default('').nullable(),
  main_thumbnail: yup.string().optional(),
  isOpen: yup.boolean().optional(),
  has_self_applied: yup.boolean().optional(),
  spready_check: yup.mixed<SpreadyCheck>().oneOf(Object.values(SPREADY_CHECK)).optional(),
  start_at: yup
    .date()
    .nullable()
    .optional()
    .default(null)
    .transform((current, original) => (original ? new Date(original) : current)),
  end_at: yup
    .date()
    .nullable()
    .optional()
    .default(null)
    .transform((current, original) => (original ? new Date(original) : current)),
  management_users: yup.array().optional(),
  create_user: yup.object().optional(),
  chat_template_text: yup.string().nullable(),
  is_favorite: yup.boolean().optional(),
  matched_barometer: yup
    .object()
    .shape({
      level: yup.number().required(),
      label: yup.string().required(),
    })
    .default(undefined),
  introduced_user_reviews: yup.array().optional(),
  satisfaction: yup.number().nullable(),
  start_datetime: yup
    .date()
    .nullable()
    .optional()
    .default(null)
    .transform((current, original) => (original ? new Date(original) : current)),
  end_datetime: yup
    .date()
    .nullable()
    .optional()
    .default(null)
    .transform((current, original) => (original ? new Date(original) : current)),
});

export type IProjectSchema = Readonly<yup.InferType<typeof projectSchema>>;

export const projectDraftForm = yup
  .object()
  .shape({
    object: yup.string().optional().default(''),
    target: yup.string().optional().default(''),
    issue: yup.string().optional().default(''),
    solution: yup.string().optional().default(''),
    state: yup.string().optional().default(''),
  })
  .required();

export const projectFormSchema = yup
  .object()
  .shape({
    start_at: yup.string().dateLike('YYYY/M/D').default(''),
    end_at: yup.string().dateLike('YYYY/M/D').default(''),
    management_user_ids: yup.array().of(yup.number().required()).min(1).default([]).required(),
    category: yup
      .string()
      .oneOf(Object.values(PROJECT_CATEGORY))
      .default(PROJECT_CATEGORY.USER_INTERVIEW)
      .required(),
    main_pic: yup.string().default('').required(),
    combined_title: yup.string().default('').required().max(COMBINED_TITLE_MAX_LENGTH),
    title: yup.string().default('').optional(),
    target_person: yup.string().default('').max(60).required(),
    topic_of_interest: yup.string().default('').max(60).required(),
    title_complement_text: yup
      .string()
      .default('')
      .when('title_opinion_type', {
        is: (option_type: string) => option_type === PROJECT_TITLE_OPINION.FREE_INPUT,
        then: (schema) => schema.max(50).required(),
        otherwise: (schema) => schema.optional(),
      }),
    title_opinion_type: yup
      .string()
      .oneOf(Object.values(PROJECT_TITLE_OPINION))
      .default(PROJECT_TITLE_OPINION.ASK)
      .required(),
    targets: yup
      .array(yup.object({ text: yup.string().required() }).required())
      .default([{ text: '' }])
      .required(),
    themes: yup
      .array(yup.object({ text: yup.string().required() }).required())
      .default([{ text: '' }])
      .required(),
    background: yup.string().default('').required().max(3000),
    goal: yup.string().max(500).default(''),
    outro: yup.string().max(500).default(''),
    match_points: yup
      .array(yup.object({ text: yup.string().required() }).required())
      .default([{ text: '' }])
      .required(),
    match_point_text: yup.string().max(500).default('').required(),
    share_enabled: yup.boolean().default(true),
    enable_offline_interview: yup.boolean().default(true),
    chat_template_text: yup.string().max(500).default(''),
    summary: yup.string().max(150).default(''),
    number_of_people: yup
      .number()
      .test('required', '', (value: unknown) => {
        return value !== undefined && value !== '' && !isNaN(value as number);
      })
      .test('range', '', (value: unknown) => {
        const min = value !== undefined && !isNaN(value as number) && (value as number) >= 1;
        const max = value !== undefined && !isNaN(value as number) && (value as number) <= 99;
        return min && max;
      }),
    privacy: yup.number().oneOf([0, 1]).required().default(0),
    draft_id: yup.number().nullable(),
  })
  .required();
export type IProjectForm = Readonly<yup.InferType<typeof projectFormSchema>>;

export type IBuildPostParamsInput = IProjectForm & {
  id: number | null;
  spready_check?: SpreadyCheck;
};

type IBuildPreviewProjectInput = IProjectForm & {
  company: ICompany;
  companyUsers: ICompanyUser[];
};

export type BuildProjectInput = IProjectSchema & {
  status: ProjectStatusNumerics & ProjectStatus;
  number_of_people?: number | null;
};

export type ProjectByCodeSchema = {
  project: IProject;
  matchPoints: string; // JSON.stringify() した文字列
  isAskingCasting: boolean;
};

export type ProjectPostParamsInput = {
  id: number | null;
  title: string;
  main_pic: string;
  start_at: string | null;
  end_at: string | null;
  chat_template_text: string;
  background: string;
  goal: string;
  outro: string;
  enable_offline_interview: boolean;
  share_enable: boolean;
  category: ProjectCategory;
  themes: string[];
  targets: string[];
  management_user_ids: number[] | null;
  match_points: Array<string>;
  match_point_text: string;
  spready_check?: SpreadyCheck;
  summary: string;
  number_of_people?: number | null;
  privacy: boolean;
  title_opinion_type: ProjectTitleOpinion;
  target_person: string | null;
  topic_of_interest: string | null;
  title_complement_text: string | null;
  draft_id: number | null;
};

export interface IProjectFactory {
  buildEmptyProject(): IProject;
  buildProject(data: BuildProjectInput): IProject;
  buildPostParams(input: IBuildPostParamsInput): ProjectPostParamsInput;
}

const isCompanySchema = (data: unknown): data is yup.InferType<typeof companySchema> => {
  try {
    companySchema.validateSync(data);
    return true;
  } catch (e) {
    return false;
  }
};

// IPrivacyTeamSchema型であるか
const isPrivacyTeamSchema = (data: unknown): data is IPrivacyTeamSchema => {
  if (isCompanySchema(data) || data === null) {
    return false;
  }
  if (typeof data === 'object') {
    // IPrivacyTeamSchema は status のみ渡される
    if ('status' in data && typeof (data as { status: unknown }).status === 'number') {
      return true;
    }
  }
  return false;
};

const buildCompany = (data: unknown): ICompany | null => {
  if (isCompanySchema(data)) {
    return new CompanyFactory().build(data);
  }
  // 企業情報非公開の場合は、companyが IPrivacyTeamSchema型になる
  if (isPrivacyTeamSchema(data)) {
    return new CompanyFactory().buildPrivacy(data);
  }
  return null;
};

export class ProjectFactory implements IProjectFactory {
  public buildEmptyProject(): IProject {
    return new Project(
      null,
      null,
      PROJECT_CATEGORY.NORMAL,
      [],
      '',
      '',
      [],
      true,
      PROJECT_STATUS.CLOSE,
      true,
      0,
      0,
      0,
      0,
      '',
      false,
      null,
      null,
      undefined,
      undefined,
      undefined,
      [],
      [],
      '',
      '',
      '',
      '',
      '',
      false,
      false,
      false,
      SPREADY_CHECK.OK,
      null,
      null,
      [],
      null,
      '',
      false,
      undefined,
      [],
      null,
      null,
    );
  }

  public buildProject(data: BuildProjectInput): IProject {
    const thumb = data.main_pic ? data.main_pic.replace('.png', '_thumb.png') : '';
    const status = Number.isInteger(data.status)
      ? (convertProjectStatusStr(data.status as ProjectStatusNumerics) as ProjectStatus)
      : data.status;

    // start_datetimeが7日間以内
    const isNew = data.start_datetime
      ? Date.now() - new Date(data.start_datetime).getTime() < 86400 * 7 * 1000
      : false;

    return new Project(
      data.id,
      buildCompany(data.company),
      data.category ?? PROJECT_CATEGORY.NORMAL,
      data.issues ?? [],
      data.title,
      data.main_pic,
      data.targets,
      data.share_enable,
      status,
      data.enable_offline_interview,
      data.casting_count ?? 0,
      data.meet_request_count ?? 0,
      data.meetup_fixed_count ?? 0,
      data.cancel_count ?? 0,
      data.summary,
      data.privacy ?? false,
      data.target_person,
      data.topic_of_interest,
      data.title_complement_text,
      data.title_opinion_type,
      data.number_of_people,
      data.themes,
      data.match_points,
      data.match_point_text ? data.match_point_text.replace(/\\n/g, '\n') : '',
      data.background,
      data.goal ?? '',
      data.outro ?? '',
      thumb,
      data.isOpen,
      data.has_self_applied,
      isNew,
      data.spready_check,
      data.start_at,
      data.end_at,
      data.management_users &&
        (data.management_users.map((companyUser) =>
          new CompanyUserFactory().buildByPartial(companyUser),
        ) ??
          []),
      // @ts-expect-error CompanyUserの型が違う
      data.create_user ? new CompanyUserFactory().build(data.create_user) : null,
      data.chat_template_text,
      data.is_favorite,
      data.matched_barometer,
      data.introduced_user_reviews
        ? data.introduced_user_reviews.map((review) =>
            new IntroducedUserReviewFactory().buildIntroducedUserReview(review),
          )
        : [],
      data.satisfaction,
      data.start_datetime,
      data.end_datetime,
    );
  }

  public buildPostParams(input: IBuildPostParamsInput): ProjectPostParamsInput {
    return {
      id: input.id,
      title: ProjectFactory.generateTitle(input),
      background: input.background,
      goal: input.goal,
      outro: input.outro,
      main_pic: input.main_pic,
      start_at: input.start_at ? dayjs(input.start_at).format('YYYY-MM-DD') : null,
      end_at: input.end_at ? dayjs(input.end_at).format('YYYY-MM-DD') : null,
      chat_template_text: input.chat_template_text,
      enable_offline_interview: input.enable_offline_interview,
      share_enable: input.share_enabled,
      category: input.category,
      themes: input.themes.map((theme) => theme.text).filter((theme) => theme !== ''),
      targets: input.targets.map((target) => target.text).filter((target) => target !== ''),
      management_user_ids:
        input.management_user_ids.length === 0 ? null : input.management_user_ids,
      match_points: input.match_points
        .map((match_point) => match_point.text)
        .filter((match_point) => match_point !== ''),
      match_point_text: input.match_point_text,
      spready_check: input.spready_check,
      summary: input.summary,
      number_of_people: input.number_of_people,
      privacy: input.privacy === 1,
      title_opinion_type: input.title_opinion_type,
      topic_of_interest: input.topic_of_interest,
      target_person: input.target_person,
      title_complement_text: input.title_complement_text,
      draft_id: input.draft_id ?? null,
    };
  }

  public buildPreviewProject(input: IBuildPreviewProjectInput): IProject {
    return new Project(
      null,
      input.company,
      input.category,
      [],
      ProjectFactory.generateTitle(input),
      input.main_pic,
      input.targets.map((target) => target.text),
      input.share_enabled,
      PROJECT_STATUS.OPEN, // 要調査
      input.enable_offline_interview,
      0,
      0,
      0,
      0,
      input.summary,
      input.privacy === 1,
      input.target_person,
      input.topic_of_interest,
      input.title_complement_text,
      input.title_opinion_type,
      input.number_of_people,
      input.themes.map((theme) => theme.text),
      input.match_points.map((match_point) => match_point.text),
      input.match_point_text,
      input.background,
      input.goal,
      input.outro,
      '',
      false,
      false,
      false,
      SPREADY_CHECK.OK,
      null,
      null,
      [],
      null,
      input.chat_template_text,
      false,
      undefined,
      [],
      0,
    );
  }

  /**
   * 入力内容をもとに、タイトルを組み立てる
   */
  public static generateTitle(
    input: Pick<
      IProjectForm,
      'title_opinion_type' | 'target_person' | 'topic_of_interest' | 'title_complement_text'
    >,
  ) {
    const action = {
      [PROJECT_TITLE_OPINION.ASK]: 'ご意見を伺いたい',
      [PROJECT_TITLE_OPINION.FEEDBACK]: 'フィードバックを頂きたい',
      [PROJECT_TITLE_OPINION.TEACH_ME]: '教えて下さい',
      [PROJECT_TITLE_OPINION.FREE_INPUT]: input.title_complement_text ?? '',
    }[input.title_opinion_type];

    // すべて未入力の場合
    if (!input.target_person && !input.topic_of_interest && !action) {
      return '';
    }

    return `【${input.target_person ?? ''}】${input.topic_of_interest ?? ''}${action}`;
  }
}
