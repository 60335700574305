import { inject, injectable } from 'tsyringe';
import { type IChatRoomFactory } from 'domain/entities/factories/ChatRoom';
import { type IUserTokenRepository } from 'application/repositorySchemas/IUserTokenRepository';
import { type IChatRoomQuery } from 'application/querySchemas/IChatRoomQuery';
import { type IUserNotificationClient } from 'interfaceAdapter/gatewaySchemas/IUserNotificationClient';
import { type IChatRoomsClient } from 'interfaceAdapter/gatewaySchemas/IChatRoomsClient';
import { type IMembersClient } from 'interfaceAdapter/gatewaySchemas/IMembersClient';
import { type IUserFactory } from 'domain/entities/factories/User';
import { type ICompanyUserFactory } from 'domain/entities/factories/CompanyUser';
import dayjs from 'dayjs';

@injectable()
export class ChatRoomQuery implements IChatRoomQuery {
  constructor(
    @inject('IUserTokenRepository')
    public readonly userToken: IUserTokenRepository,
    @inject('IChatRoomsClient')
    public readonly chatRoomsClient: IChatRoomsClient,
    @inject('IMembersClient')
    public readonly membersClient: IMembersClient,
    @inject('IChatRoomFactory')
    public readonly factory: IChatRoomFactory,
    @inject('IUserFactory')
    public readonly userFactory: IUserFactory,
    @inject('ICompanyUserFactory')
    public readonly companyUserFactory: ICompanyUserFactory,
    @inject('IUserNotificationClient')
    public readonly userNotificationClient: IUserNotificationClient,
  ) {}

  public getRooms = async () => {
    const { data, message } = await this.chatRoomsClient.get({
      token: this.userToken.get() || '',
    });
    if (message) {
      throw new Error(message);
    }

    return (
      data.chat_rooms
        .map((room) => this.factory.build(room))
        // 最終メッセージの送信日時で降順ソート
        .sort((a, b) => dayjs(b.last_message_sent_at).diff(dayjs(a.last_message_sent_at)))
    );
  };

  public getChatRoomsStats = async () => {
    const { data, message } = await this.userNotificationClient.get({
      token: this.userToken.get() || '',
    });
    if (message) {
      throw new Error(message);
    }
    return data;
  };

  public getMembers = async (roomId: string) => {
    const { data, message } = await this.membersClient.get({
      token: this.userToken.get() || '',
      roomId,
    });
    if (message) {
      throw new Error(message);
    }
    return {
      users: data.users.map((r) => this.userFactory.buildByChatRoom(r)),
      companyUsers: data.company_users,
    };
  };
}
