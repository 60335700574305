import dayjs from 'dayjs';
import styled from '@emotion/styled';
import Autolinker from 'autolinker';
import { mq } from 'utils/responsive';
import { User } from 'domain/entities/User/User';
import { ICompanyUser } from 'domain/entities/CompanyUser/CompanyUser';
import { MESSAGE_USER_TYPE, Message } from 'domain/entities/Message/Message';
import { MessageMenu } from 'ui/components/user/features/MessageMenu/MessageMenu';
import { UserIcon } from 'ui/components/user/elements/UserIcon/UserIcon';
import { RemovedChat } from 'ui/components/user/elements/RemovedChat/RemovedChat';

const Wrapper = styled.div<{ isMe: boolean }>`
  display: grid;
  grid-template-areas: ${({ isMe }) => {
    if (isMe) {
      return `
        'icon name name'
        'icon card menu' // 自身のチャットにはメニューを表示
        'time time .';
      `;
    }
    return `
      'icon name'
      'icon card'
      'time time';
    `;
  }};
  grid-template-columns: ${({ isMe }) => {
    if (isMe) {
      return '48px auto 24px';
    }
    return '48px auto';
  }};
  gap: 4px 8px;
  max-width: 80%;
  margin-left: ${({ isMe }) => (isMe ? 'auto' : null)};
  margin-bottom: 12px;
  ${mq('SP')} {
    max-width: 90%;
  }
`;

const Card = styled.div`
  grid-area: card;
  padding: 16px;
  border: ${({ isMe }: { isMe: boolean }) => (isMe ? '1px solid var(--gray-light-1)' : 'none')};
  border-radius: 16px;
  background-color: ${({ isMe }: { isMe: boolean }) =>
    isMe ? 'var(--spr-white)' : 'var(--gray-light-3)'};
  color: var(--gray-dark-3);
  font-size: 14px;
  line-height: 20px;
  word-break: break-all;
  white-space: pre-line;
`;

const UserName = styled.span`
  grid-area: name;
  font-size: 12px;
  font-weight: 700;
`;

const Icon = styled.div`
  grid-area: icon;
`;

const Menu = styled.div`
  grid-area: menu;
  place-self: end;
`;

const Time = styled.div`
  grid-area: time;
  margin-left: auto;
  font-size: 10px;
  color: var(--gray-dark-2);
`;

const RemovedChatWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 16px auto;
`;

interface Props {
  message: Message;
  user?: User;
  companyUser?: ICompanyUser;
  removeMessage: (chatId: number) => Promise<void>;
}

/**
 * メッセージの吹き出し表示
 */
export const MessageItem: React.FC<Props> = ({ message, user, companyUser, removeMessage }) => {
  if (!message) {
    return null;
  }

  const isMyUserId =
    !!user && message.user_type === MESSAGE_USER_TYPE.USER && message.user_id === user.id;
  const isMyCompanyUserId =
    !!companyUser &&
    message.user_type === MESSAGE_USER_TYPE.COMPANY_USER &&
    message.user_id === companyUser.id;
  const isMe = isMyUserId || isMyCompanyUserId;
  const sentAt = dayjs(message.datetime).format('YYYY/MM/DD HH:mm');

  if (message.removed) {
    return (
      <RemovedChatWrapper>
        <RemovedChat />
      </RemovedChatWrapper>
    );
  }

  return (
    <Wrapper isMe={isMe}>
      <Icon>
        {isMe ? null : (
          <UserIcon
            src={message.user_icon_image}
            alt={message.user_last_name + message.user_first_name}
            size={48}
          />
        )}
      </Icon>
      <UserName>
        {isMe ? null : (
          <>
            {message.user_last_name} {message.user_first_name}
          </>
        )}
      </UserName>
      <Card
        isMe={isMe}
        dangerouslySetInnerHTML={{
          __html: new Autolinker({
            newWindow: true,
            className: 'text-link',
            stripPrefix: false,
          }).link(message.message),
        }}
      />
      {isMe && (
        <Menu>
          <MessageMenu removeMessage={() => removeMessage(message.chat_id)} />
        </Menu>
      )}
      <Time>{sentAt}</Time>
    </Wrapper>
  );
};
