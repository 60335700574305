import dayjs from 'dayjs';
import styled from '@emotion/styled';
import Autolinker from 'autolinker';
import { mq } from 'utils/responsive';
import { User } from 'domain/entities/User/User';
import { ICompanyUser } from 'domain/entities/CompanyUser/CompanyUser';
import { Message, MESSAGE_USER_TYPE } from 'domain/entities/Message/Message';
import { MessageMenu } from 'ui/components/user/features/MessageMenu/MessageMenu';
import { UserIcon } from 'ui/components/company/elements/UserIcon/UserIcon';
import { RemovedChat } from 'ui/components/user/elements/RemovedChat/RemovedChat';

const Wrapper = styled.div<{ isMe: boolean }>`
  display: grid;
  grid-template-areas: ${({ isMe }) => {
    if (isMe) {
      return `
        'card menu'
        'meta meta';
      `;
    }
    return `
      'card'
      'meta';
    `;
  }};
  grid-template-columns: ${({ isMe }) => (isMe ? 'auto 24px' : 'auto')};
  gap: 8px;
  max-width: 70%;
  margin-left: ${({ isMe }) => (isMe ? 'auto' : null)};
  margin-bottom: 10px;
  ${mq('SP')} {
    max-width: 90%;
  }
`;

const Card = styled.div`
  grid-area: card;
  padding: 10px;
  border: 1px solid var(--gray-light-2);
  border-radius: 10px;
  background-color: ${({ isMe }: { isMe: boolean }) =>
    isMe ? 'var(--spr-white)' : 'var(--gray-light-3)'};
  color: var(--gray-dark-3);
  font-size: 16px;
  line-height: 26px;
  word-break: break-all;
  white-space: pre-line;
`;

const Menu = styled.div`
  grid-area: menu;
  place-self: end;
`;

const Meta = styled.div`
  grid-area: meta;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  gap: 8px;
  padding-inline: 10px;
`;

const UserName = styled.span`
  font-size: 12px;
  line-height: 18px;
  color: var(--gray-dark-2);
`;

const Time = styled.div`
  margin-left: auto;
  font-size: 10px;
  color: var(--gray-dark-2);
`;

const RemovedChatWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 16px auto;
`;

interface Props {
  message: Message;
  user?: User;
  companyUser?: ICompanyUser;
  removeMessage: (chatId: number) => Promise<void>;
}

/**
 * メッセージの吹き出し表示
 */
export const MessageItem: React.FC<Props> = ({ message, user, companyUser, removeMessage }) => {
  if (!message) {
    return null;
  }

  const isMyUserId =
    !!user && message.user_type === MESSAGE_USER_TYPE.USER && message.user_id === user.id;
  const isMyCompanyUserId =
    !!companyUser &&
    message.user_type === MESSAGE_USER_TYPE.COMPANY_USER &&
    message.user_id === companyUser.id;
  const isMe = isMyUserId || isMyCompanyUserId;

  const sentAt = dayjs(message.datetime).format('YYYY/MM/DD HH:mm');

  if (message.removed) {
    return (
      <RemovedChatWrapper>
        <RemovedChat />
      </RemovedChatWrapper>
    );
  }

  return (
    <Wrapper isMe={isMe}>
      <Card
        isMe={isMe}
        dangerouslySetInnerHTML={{
          __html: new Autolinker({
            newWindow: true,
            className: 'text-link',
            stripPrefix: false,
          }).link(message.message),
        }}
      />
      {isMe && (
        <Menu>
          <MessageMenu removeMessage={() => removeMessage(message.chat_id)} />
        </Menu>
      )}
      <Meta>
        {!isMe && (
          <>
            <UserIcon
              src={message.user_icon_image ?? null}
              alt={message.user_last_name + message.user_first_name}
              size={24}
            />
            <UserName>
              {message.user_last_name} {message.user_first_name}
            </UserName>
          </>
        )}
        <Time>{sentAt}</Time>
      </Meta>
    </Wrapper>
  );
};
