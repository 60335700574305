import { useContext, useEffect, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { LineAccountLinkCallbackRepository } from 'interfaceAdapter/repositories/LineAccountLinkCallback';

export const useLineAccountLinkCallback = () => {
  const diContainer = useContext(diContainerContext);
  const lineAccountLinkCallbackRepository = diContainer.resolve(LineAccountLinkCallbackRepository);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const ref = useRef(false);

  useEffect(() => {
    if (ref.current) {
      return;
    }
    ref.current = true;

    callback();
  }, []);

  const callback = async () => {
    const code = searchParams.get('code') ?? '';
    const state = searchParams.get('state') ?? '';
    const res = await lineAccountLinkCallbackRepository.callback({ code, state });

    if (!res.success) {
      if (res.error_code === 'UNKNOWN_STATE') {
        // stateが未知の値になっていてエラーになった場合、ユーザーがLINE自動ログインに失敗した可能性がある
        // 自動ログインを行わないURLにリダイレクトする
        // ref: https://developers.line.biz/ja/docs/line-login/how-to-handle-auto-login-failure/#how-to-verify
        navigate('/user/line/account-link/request?disableAutoLogin=true');
        return;
      }

      // その他のエラーの場合は正常でない操作をしたケース
      navigate('/user/line/account-link/failed');
      return;
    }

    navigate('/user/line/account-link/complete');
  };
};
