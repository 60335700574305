import styled from '@emotion/styled';
import Autolinker from 'autolinker';
import linkIcon from 'ui/images/com/open-in-new.svg';
import { useCompanyDetail } from './useCompanyDetail';
import { OgpView } from 'ui/components/user/features/OgpView/OgpView';
import { CompanyOgp } from 'domain/valueObjects/Ogp/CompanyOgp';
import { ICompany } from 'domain/entities/Company/Company';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
`;

const Name = styled.h4`
  color: var(--gray-dark-2);
  font-size: 16px;
  font-weight: var(--font-weight--bolder);
  line-height: 1.6;
`;

const Pics = styled.div`
  display: flex;
  align-items: center;
  img {
    width: 48%;
    margin-inline: 1%;
    border-radius: 8px;
  }
`;

const Text = styled.div`
  color: #1e1e1e;
  font-size: 16px;
  line-height: 1.6;
  white-space: pre-wrap;
  &:empty {
    display: none;
  }
`;

const Link = styled.a`
  display: inline-flex;
  align-items: center;
  font-size: 16px;
  gap: 2px;
  text-decoration: underline;
  cursor: pointer;
  color: #1e1e1e;
  img {
    width: 16px;
    height: 16px;
  }
`;

const Title = styled.div`
  margin-top: 5px;
  color: #b4b4b4;
  font-size: 14px;
  font-weight: var(--font-weight--bolder);
  line-height: 1.6;
`;

const OgpWrapper = styled.div`
  width: 100%;
`;

interface Props {
  // 実質この company は null にはならないのだが、IProject.company が nullable であり、それが渡されることがあるため
  company: ICompany | null;
}

/**
 * 企業詳細
 */
export const CompanyDetail: React.FC<Props> = ({ company }) => {
  if (company === null) return null;

  const { ogpList } = useCompanyDetail(company);

  return (
    <Wrapper>
      <Name>{company.name}</Name>
      {company.pics && (
        <Pics>
          {company.pics.map((pic) => (
            <img key={pic} src={pic} alt={company.name} />
          ))}
        </Pics>
      )}
      <Text
        dangerouslySetInnerHTML={{ __html: new Autolinker().link(company.about_company ?? '') }}
      />
      <Text>{company.copy}</Text>
      {company.address && (
        <>
          <Title>住所</Title>
          <Text>{company.address}</Text>
        </>
      )}
      {company.employee != null && company.employee > 0 && (
        <>
          <Title>従業員数</Title>
          <Text>{company.employee}人</Text>
        </>
      )}
      {company.capital != null && company.capital > 0 && (
        <>
          <Title>資本金</Title>
          <Text>{company.capital}万円</Text>
        </>
      )}
      {company.url && (
        <>
          <Title>コーポレートサイト</Title>
          <Link href={company.url} target="_blank" rel="noopener noreferrer">
            {company.url}
            <img src={linkIcon} alt="open in new tab" />
          </Link>
        </>
      )}

      {ogpList && ogpList.length > 0 && (
        <>
          <Title>参考URL</Title>
          {ogpList.map((ogp: CompanyOgp) => (
            <OgpWrapper className="ogpWrapper" key={ogp.url}>
              <OgpView ogp={ogp} />
            </OgpWrapper>
          ))}
        </>
      )}
    </Wrapper>
  );
};
