import { useCallback, useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { projectDraftForm } from 'domain/entities/factories/Project';
import { useModal } from 'ui/components/company/screens/modals/useModal';
import { useNavigate } from 'react-router-dom';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { DraftProjectRequestRepository } from 'interfaceAdapter/repositories/DraftProjectRequest';
import yup from 'utils/yup';
import { ideaObjectiveOptions } from 'domain/entities/Project/DraftProjectAsset';

export const useProjectDraftMaker = () => {
  const navigate = useNavigate();
  const { formState, control, handleSubmit } = useForm({
    resolver: yupResolver(projectDraftForm),
    mode: 'onChange',
    defaultValues: projectDraftForm.cast({}),
  });
  const progressModal = useModal();
  const completeModal = useModal();
  const [formInput, setFormInput] = useState<yup.InferType<typeof projectDraftForm> | null>(null);
  const [connection, setConnection] = useState<WebSocket | null>(null);
  const [connectionOpen, setConnectionOpen] = useState(false);
  const [connectionMessage, setConnectionMessage] = useState<{
    connectionId?: string;
    success?: boolean;
    assetsId?: string;
  } | null>(null);
  const [progress, setProgress] = useState(0);
  const [completed, setCompleted] = useState(false);
  const diContainer = useContext(diContainerContext);
  const draftProjectRequestRepository = diContainer.resolve(DraftProjectRequestRepository);

  const onSubmit = handleSubmit(async (data) => {
    // ローカル環境の場合は生成APIを呼ばず、WebSocketが成功したように見せかける
    if (process.env.REACT_APP_IS_LOCAL === 'true') {
      progressModal.openModal();
      setTimeout(() => setProgress(90), 0);
      setTimeout(() => setConnectionMessage({ success: true, assetsId: '1' }), 10_000);
      return;
    }

    setFormInput({
      ...data,
      // valueではなくlabelの内容を送りたいので
      object: ideaObjectiveOptions.find((option) => option.value === data.object)?.label || '',
    });

    const { connection: con } = await draftProjectRequestRepository.connect();
    con.addEventListener('open', () => {
      setConnectionOpen(true);
    });

    con.addEventListener('message', (event) => {
      const data = JSON.parse(event.data);
      setConnectionMessage(data);
    });

    // con.addEventListener('close', () => {
    //   console.log('Disconnect', 'Connection closed');
    // });
    setConnection(con);

    progressModal.openModal();

    // リクエスト中の装い
    setTimeout(() => {
      setProgress(90); // APIは小刻みな進捗度を返さないので、ゆっくりと90%まで進めてしまう
    }, 0); // モーダルが開いてからアニメーションさせるために1フレーム遅らせる
  });

  // connectionがOpenになったら空メッセージを送る、するとconnectionIdが得られる
  useEffect(() => {
    if (!connectionOpen || !connection) return;
    draftProjectRequestRepository.sendEmpty(connection);
  }, [connectionOpen]);

  // connectionIdが得られたらフォームの内容を送って生成開始
  useEffect(() => {
    if (!connection || !formInput || !connectionMessage || !connectionMessage.connectionId) return;
    draftProjectRequestRepository.send(connection, connectionMessage.connectionId, formInput);
  }, [connection, connectionMessage]);

  // 生成が終わるとassetsIdが得られるので終了してセッション作成画面へ
  useEffect(() => {
    if (connectionMessage && connectionMessage.success && connectionMessage.assetsId) {
      setProgress(100);
      setCompleted(true);

      // モーダル切り替え
      setTimeout(() => {
        progressModal.closeModal();
        completeModal.openModal();
      }, 1000);

      // セッション作成画面へ
      setTimeout(
        () => navigate(`/workgroup/projects/create/assets/${connectionMessage.assetsId}/?new`),
        4000,
      );
    }
  }, [connectionMessage]);

  // 終了処理
  useEffect(() => {
    if (!completed || !connection) return;

    draftProjectRequestRepository.disconnect(connection);
    connection.removeEventListener('open', () => {});
    connection.removeEventListener('message', () => {});
    connection.removeEventListener('close', () => {});
  }, [completed]);

  const onClose = useCallback(() => {
    setProgress(0);

    if (connection) {
      draftProjectRequestRepository.disconnect(connection);
    }
  }, [connection]);

  return {
    ideaObjectiveOptions,
    formState,
    control,
    progress,
    completed,
    onSubmit,
    onClose,
    progressModal,
    completeModal,
  };
};
