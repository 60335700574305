import { API_LOCATION_SPREADY } from 'infrastructure/config';
import {
  ILineAccountLinkRequestClient,
  IPostInput,
  IPostResponse,
} from 'interfaceAdapter/gatewaySchemas/ILineAccountLinkRequestClient';
import { fetchFixture } from 'utils/fetchFixture';
import fixture from './LineAccountLinkRequest.post.json';

export class LineAccountLinkRequestClient implements ILineAccountLinkRequestClient {
  async post(input: IPostInput): Promise<IPostResponse> {
    const PATH = `${API_LOCATION_SPREADY}/line/account-link/request`;
    const headers = {
      'Content-Type': 'application/json',
    };
    const method = 'POST';

    try {
      const response = input.mock
        ? await fetchFixture(fixture)
        : await fetch(`${PATH}${input.disableAutoLogin ? '?disable_auto_login=true' : ''}`, {
            method,
            headers,
            // ローカルの場合はフロントとAPIが別オリジンなのでincludeにしないとCookieの送受信がされない
            credentials: process.env.REACT_APP_IS_LOCAL === 'true' ? 'include' : 'same-origin',
          });

      return response.json();
    } catch {
      throw new Error('処理に失敗しました');
    }
  }
}
