export const MESSAGE_USER_TYPE = {
  USER: 'u',
  COMPANY_USER: 'c',
};

export type MessageUserType = (typeof MESSAGE_USER_TYPE)[keyof typeof MESSAGE_USER_TYPE];

export interface IMessage {
  chat_id: number;
  user_type: MessageUserType;
  user_id: number;
  message: string;
  removed: boolean;
  user_last_name: string;
  user_first_name: string;
  user_icon_image: string | null;
  datetime: Date;
  timestamp: number;
}

export class Message implements IMessage {
  constructor(
    public chat_id: number,
    public user_type: MessageUserType,
    public user_id: number,
    public message: string,
    public removed: boolean,
    public user_last_name: string,
    public user_first_name: string,
    public user_icon_image: string | null,
    public datetime: Date,
    public timestamp: number,
  ) {}
}
