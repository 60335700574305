import { companyUserContext } from 'application/contexts/useCompanyUser';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TabOption } from '../../elements/Tabs/Tabs';
import { PreCompanyUser } from 'domain/entities/CompanyUser/PreCompanyUser';
import { ICompanyUser } from 'domain/entities/CompanyUser/CompanyUser';
import { TEAM_ROLE_NAMES } from 'domain/entities/Team/TeamRole';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { PreCompanyUserRepository } from 'interfaceAdapter/repositories/PreCompanyUser';
import { CompanyUserRepository } from 'interfaceAdapter/repositories/CompanyUser';
import { CompanyUserQuery } from 'interfaceAdapter/queries/CompanyUser';
import { PreCompanyUserQuery } from 'interfaceAdapter/queries/PreCompanyUser';
import { useModal } from 'ui/components/company/screens/modals/useModal';

export const TAB_OPTION_VALUES = {
  ACTIVE: 'active',
  PROVISIONAL: 'provisional',
} as const;
export type UserListTab = (typeof TAB_OPTION_VALUES)[keyof typeof TAB_OPTION_VALUES];
const tabOptions: TabOption<UserListTab>[] = [
  {
    label: '有効',
    value: TAB_OPTION_VALUES.ACTIVE,
  },
  {
    label: '仮登録中',
    value: TAB_OPTION_VALUES.PROVISIONAL,
  },
];

export interface UserListTableRow {
  id: number;
  icon_image: string;
  name: string;
  email: string;
  team_role: string;
  department: string | null;
  job: string | null;
  showActionButtons: boolean;
}

export const useUserListTable = () => {
  const navigate = useNavigate();
  const diContainer = useContext(diContainerContext);
  const companyUserRepository = diContainer.resolve<CompanyUserRepository>(CompanyUserRepository);
  const companyUserQuery = diContainer.resolve<CompanyUserQuery>(CompanyUserQuery);
  const preCompanyUserQuery = diContainer.resolve(PreCompanyUserQuery);
  const preCompanyUserRepository = diContainer.resolve(PreCompanyUserRepository);
  const { user } = useContext(companyUserContext);
  const [users, setUsers] = useState<ICompanyUser[] | PreCompanyUser[]>([]);
  const [requesting, setRequesting] = useState(false);
  const [selectedTab, setSelectedTab] = useState<UserListTab>('active');
  const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const inviteRestrictionModal = useModal();

  const formattedUsers = useMemo(() => {
    return users.map((u) => ({
      id: u.id,
      // @ts-expect-error PreCompanyUser には icon_image がないため
      icon_image: u?.icon_image ?? '',
      name: `${u.last_name} ${u.first_name}`,
      email: u.email,
      team_role: 'team_role' in u && u.team_role ? TEAM_ROLE_NAMES[u.team_role] : '',
      department: u.department,
      job: u.job,
      showActionButtons: user.isAdministrator || u.id === user.id,
    }));
  }, [users]);

  const canInviteUser = useMemo(
    () => user.isContracted && (user.isAdministrator || user.isSupportUser),
    [user],
  );
  const showTabs = useMemo(() => user.isAdministrator || user.isSupportUser, [user]);

  const gotoCreateUserPage = useCallback(
    () =>
      canInviteUser ? navigate('/workgroup/users/invite/') : inviteRestrictionModal.openModal(),
    [],
  );

  const gotoEditUserPage = useCallback(
    (id: number) => {
      if (selectedTab !== TAB_OPTION_VALUES.ACTIVE) {
        return;
      }
      navigate(`/workgroup/users/edit/${id}/`);
    },
    [selectedTab],
  );

  const getCompanyUsers = useCallback(async () => {
    const users = await companyUserQuery.getAll();
    setUsers(users);
  }, [user]);

  const getPreCompanyUsers = useCallback(async () => {
    const users = await preCompanyUserQuery.getAll();
    setUsers(users);
  }, []);

  const resendRegisterEmail = useCallback(async (id: number) => {
    setRequesting(true);
    try {
      await preCompanyUserRepository.resendEmail(id);
      setShowSuccessSnackbar(true);
      setSuccessMessage('本登録メールを再送しました');
    } finally {
      setRequesting(false);
    }
  }, []);

  const disableUser = useCallback(
    async (id: number) => {
      if (
        !confirm(
          `参加解除してもよろしいですか？\n\n参加解除をするとこのワークグループから抜けることになります。\n抜けた後でも招待を受けることで再びワークグループに参加することは可能です。`,
        )
      ) {
        return;
      }
      setRequesting(true);

      if (id === user.id) {
        try {
          await companyUserRepository.disableMyself();
          setShowSuccessSnackbar(true);
          setSuccessMessage('ワークグループへの参加を解除しました');
          setTimeout(() => navigate('/workgroup/switch/'), 2000);
        } finally {
          setRequesting(false);
        }
        // 成功時はリダイレクトするので、requesting=trueのままにしておき、不要な操作をさせないようにする
      } else {
        try {
          await companyUserRepository.disable(id);
          setShowSuccessSnackbar(true);
          setSuccessMessage('ユーザーをワークグループから解除しました');

          getCompanyUsers();
        } finally {
          setRequesting(false);
        }
      }
    },
    [user.id],
  );

  const deletePreUser = useCallback(async (id: number) => {
    setRequesting(true);
    try {
      await preCompanyUserRepository
        .delete(id)
        .then(() => preCompanyUserQuery.getAll().then(setUsers));
      setShowSuccessSnackbar(true);
      setSuccessMessage('仮登録中のユーザーを削除しました');

      const newUsers = await preCompanyUserQuery.getAll();
      setUsers(newUsers);
    } finally {
      setRequesting(false);
    }
  }, []);

  useEffect(() => {
    if (selectedTab === TAB_OPTION_VALUES.ACTIVE) {
      getCompanyUsers();
    }
    if (selectedTab === TAB_OPTION_VALUES.PROVISIONAL) {
      getPreCompanyUsers();
    }
  }, [selectedTab]);

  return {
    users: formattedUsers,
    inviteRestrictionModal,
    requesting,
    successMessage,
    showSuccessSnackbar,
    setShowSuccessSnackbar,
    canInviteUser,
    showTabs,
    gotoCreateUserPage,
    gotoEditUserPage,
    tabOptions,
    selectedTab,
    setSelectedTab,
    resendRegisterEmail,
    disableUser,
    deletePreUser,
  };
};
