import { useContext, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import yup from 'utils/yup';
import { CompanyUserRepository } from 'interfaceAdapter/repositories/CompanyUser';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { CompanyUserLogout } from 'application/usecases/CompanyUserLogout';
import { HttpGone, HttpBadRequest, HttpForbidden } from 'domain/types/httpResponse';

const schema = yup
  .object({
    password: yup
      .string()
      .required()
      .min(9)
      .max(30)
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[-_.@])[a-zA-Z0-9\-_.@]*$/,
        '英字の大文字、英字の小文字、記号 -_.@ 、数字を全て含めて設定してください',
      ),
    agreed: yup.boolean().default(false).oneOf([true], '規約に同意してください').required(),
  })
  .required();

type CompanyUserSignUpForm = yup.InferType<typeof schema>;

export const useInvitedCompanyUserSignUp = () => {
  const diContainer = useContext(diContainerContext);
  const logout = diContainer.resolve(CompanyUserLogout);
  const companyUserRepository = diContainer.resolve(CompanyUserRepository);
  const navigate = useNavigate();
  const params = useParams();
  const { control, handleSubmit, formState } = useForm<CompanyUserSignUpForm>({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [email, setEmail] = useState('');
  const [expired, setExpired] = useState(false);
  const [message, setMessage] = useState('');

  const onSubmit = handleSubmit(async ({ password }) => {
    if (!params.url_id) {
      return;
    }
    setSubmitting(true);
    try {
      await companyUserRepository.registerPassword(params.url_id, password);
      setCompleted(true);
      setTimeout(() => navigate('/business/register/user/profile/'), 3000);
    } catch (e) {
      if (e instanceof Error) {
        throw new Error(e.message);
      }
    } finally {
      setSubmitting(false);
    }
  });

  useEffect(() => {
    if (!params.url_id) {
      return;
    }

    const fetch = async () => {
      try {
        const { data } = await companyUserRepository.fetchPreCompanyUserByUrlIdFromInvite(
          params.url_id ?? '',
        );

        if (data.required_user_action === 'none') {
          companyUserRepository.removeStorage();
          window.location.href = '/workgroup/';
          return;
        }
        if (data.required_user_action === 'login') {
          // 未ログインだが、Spreadyに登録済みの場合はログイン画面に遷移
          companyUserRepository.setStorage(params.url_id ?? '');
          navigate('/business/');
          return;
        }

        setEmail(data.email);
      } catch (e) {
        if (e instanceof HttpGone) {
          setExpired(true);
          return;
        }
        if (e instanceof HttpForbidden) {
          setMessage(
            `現在ログイン中のユーザーと招待された方の情報が一致していないようです。\n招待を受理するために、ログアウト処理を行います。数秒後、自動でログイン画面に切り替わります。`,
          );
          companyUserRepository.setStorage(params.url_id ?? '');
          await logout.execute();
          setTimeout(() => navigate('/business/'), 2000);
          return;
        }
        if (e instanceof Error || e instanceof HttpBadRequest) {
          setMessage(`認証に失敗しました。\n数秒後、自動でログイン画面に切り替わります。`);
          setTimeout(() => navigate('/business/?comes_from=auth_user_registration'), 2000);
          throw new Error(e.message);
        }
      } finally {
        setLoading(false);
      }
    };

    fetch();
  }, [params.url_id]);

  return {
    completed,
    expired,
    loading,
    submitting,
    email,
    message,
    control,
    errors: formState.errors,
    onSubmit,
  };
};
