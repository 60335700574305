import { Controller } from 'react-hook-form';
import styled from '@emotion/styled';
import { FormSectionTitle } from 'ui/components/company/elements/FormSectionTitle/FormSectionTitle';
import { ActionButton } from 'ui/components/company/elements/ActionButton/ActionButton';
import { FormInput } from 'ui/components/company/elements/FormInput/FormInput';
import { FormError } from 'ui/components/company/elements/FormError/FormError';
import { FormCheckbox } from 'ui/components/company/elements/FormCheckbox/FormCheckbox';
import { FormTextArea } from 'ui/components/company/elements/FormTextArea/FormTextArea';
import { SnackBar } from 'ui/components/company/elements/SnackBar/SnackBar';
import { ImageUpload } from 'ui/components/company/features/ImageUpload/ImageUpload';
import { MultiInput } from 'ui/components/company/features/MultipleInput/MultipleInput';
import { useTeamProfileSetup } from './useTeamProfileSetup';
import Step2Img from 'ui/images/company/icons/step2.svg';
import { mqUser } from 'utils/responsive';

const Wrapper = styled.div`
  height: calc(100vh - 70px);
  @supports (height: 100svh) {
    min-height: calc(100svh - 70px);
  }
  padding: 20px 20px 60px;
  background-color: var(--primary);
  overflow-y: auto;
  margin: -16px -30px -60px;
`;

const TitleContainer = styled.div`
  font-size: var(--font-xxxx-large);
  color: var(--spr-black);
  font-weight: var(--font-extra-bold);
  width: 100%;
  margin: 10px 0 20px;
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: flex-start;

  img {
    width: 70px;
    height: 70px;
  }
`;

const FormContainer = styled.div`
  max-width: 1080px;
  margin-inline: auto;
`;

const TitleText = styled.div`
  font-size: var(--font-xxxx-large);
  font-weight: var(--font-extra-bold);
  color: var(--spr-black);
  line-height: 1;
  ${mqUser('SP')} {
    font-size: var(--font-xx-large);
  }
`;

const BgWhite = styled.div`
  padding: 40px 28px;
  border-radius: 12px;
  background-color: var(--white);
`;

const SmallText = styled.p`
  margin: 8px 0 0;
  font-weight: 300;
  font-size: var(--font-default);
`;

const Button = styled.button<{ disabled?: boolean }>`
  margin-top: 15px;
  align-items: center;
  padding: 12px 20px;
  border: 1px solid var(--gray);
  border-radius: 38px;
  appearance: none;
  background-color: ${({ disabled }) => (disabled ? 'var(--gray-light-2)' : 'var(--white)')};
  color: ${({ disabled }) => (disabled ? 'var(--gray-dark-1)' : 'var(--accent-dark)')};
  font-size: var(--font-medium);
  font-weight: var(--font-bold);
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};

  &:not(:disabled):hover {
    opacity: 0.7;
  }
`;

const Description = styled.div`
  background-color: var(--accent-paler);
  border-radius: 20px;
  padding: 20px 30px;
  font-size: var(--font-x-large);
  line-height: 1.8;

  b {
    font-weight: 800;
  }
`;

export const TeamProfileSetup: React.FC = () => {
  const {
    loading,
    requesting,
    errors,
    control,
    showSnackbar,
    snackbarMessage,
    arrayFields,
    watch,
    setValue,
    onSubmit,
    onPicsUpload,
    removeEmptyFields,
    testSlackWebhook,
    setShowSnackbar,
    appendField,
  } = useTeamProfileSetup();

  if (loading) return null;

  const picsData = watch('pics');
  return (
    <>
      <SnackBar open={showSnackbar} setOpen={setShowSnackbar} message={snackbarMessage} />
      <Wrapper>
        <FormContainer>
          <TitleContainer>
            <img src={Step2Img} alt="step2" />
            <TitleText>企業情報（ワークグループ）の入力</TitleText>
          </TitleContainer>
          <BgWhite>
            <Description>
              ここで入力した企業情報をもとに<b>ワークグループが作成</b>されます。
              <br />
              ワークグループには<b>他のメンバーを招待</b>できます。
              <br />
              インタビュー募集案件を作成してワークグループ内の<b>メンバーと共有</b>しましょう。
            </Description>
            <FormSectionTitle
              title="企業名"
              titleSize="18px"
              isRequired
              style={{ margin: '32px 0 8px' }}
            />
            <Controller
              name="name"
              control={control}
              render={({ field }) => <FormInput {...field} disabled />}
            />
            <FormSectionTitle
              title="郵便番号"
              titleSize="18px"
              isRequired
              style={{ margin: '32px 0 8px' }}
            />
            <Controller
              name="zipcode"
              control={control}
              render={({ field }) => (
                <FormInput
                  {...field}
                  placeholder="000-0000"
                  width={'80%'}
                  hasError={!!errors.zipcode?.message}
                  disabled
                />
              )}
            />
            <FormError message={errors.zipcode?.message} style={{ marginBottom: '8px' }} />

            <FormSectionTitle
              title="御社所在地"
              titleSize="18px"
              isRequired
              style={{ margin: '32px 0 8px' }}
            />
            <Controller
              name="address"
              control={control}
              render={({ field }) => (
                <FormInput
                  {...field}
                  placeholder="東京都渋谷区恵比寿"
                  hasError={!!errors.address?.message}
                  disabled
                />
              )}
            />
            <FormError message={errors.address?.message} style={{ marginBottom: '8px' }} />

            <FormSectionTitle
              title="コーポレートサイトURL"
              titleSize="18px"
              isRequired
              style={{ margin: '32px 0 8px' }}
            />
            <Controller
              name="url"
              control={control}
              render={({ field }) => (
                <FormInput
                  {...field}
                  placeholder="https://spready.co.jp/"
                  hasError={!!errors.url?.message}
                />
              )}
            />
            <FormError message={errors.url?.message} style={{ marginBottom: '8px' }} />

            <FormSectionTitle
              title="企業ロゴ（アイコン画像）"
              titleSize="18px"
              style={{ margin: '32px 0 8px' }}
            />
            <ImageUpload
              imagePath={watch('icon_image') ?? ''}
              directory="profile_tmp"
              fileName={'icon'}
              fileCategory="company"
              disabled={requesting}
              width={180}
              height={180}
              withPreview
              onComplete={(fileUrl) => setValue('icon_image', fileUrl)}
            />
            <SmallText>※ 推奨サイズ：縦横400px以上、形式：JPG、PNG、2MB未満</SmallText>
            <FormError message={errors.icon_image?.message} style={{ marginBottom: '8px' }} />

            <FormSectionTitle
              title="利用企業ご紹介サイトへの企業ロゴの掲載許可"
              titleSize="18px"
              style={{ margin: '32px 0 8px' }}
            />
            <Controller
              name="logo_display_enable"
              control={control}
              render={({ field }) => (
                <FormCheckbox
                  id="logo_display_enable"
                  checked={field.value}
                  disableBorder
                  onChange={() => field.onChange(!field.value)}
                >
                  使用可
                </FormCheckbox>
              )}
            />

            <FormSectionTitle
              title="会社説明（案件ページ下に表示されます）"
              titleSize="18px"
              style={{ margin: '32px 0 8px' }}
            />
            <Controller
              name="about_company"
              control={control}
              render={({ field }) => (
                <FormTextArea
                  {...field}
                  rows={4}
                  placeholder="会社説明を入力"
                  hasError={!!errors.about_company?.message}
                />
              )}
            />
            <SmallText>改行が使えます。URLはリンクされます。</SmallText>
            <FormError message={errors.about_company?.message} style={{ marginBottom: '8px' }} />

            <FormSectionTitle
              title="会社説明画像1"
              titleSize="18px"
              style={{ margin: '32px 0 8px' }}
            />
            <ImageUpload
              imagePath={picsData && picsData.length > 0 ? picsData[0] : ''}
              directory="profile_tmp"
              fileName="company_pic1"
              fileCategory="company"
              placeholder="image"
              aspectRatio={16 / 9}
              width={480}
              height={270}
              disabled={requesting}
              withPreview
              isSquare
              onComplete={(path) => onPicsUpload(path, 0)}
            />
            <SmallText>※ 推奨サイズ：横480px以上、縦270px以上。縦:横=16:9</SmallText>

            <FormSectionTitle
              title="会社説明画像2"
              titleSize="18px"
              style={{ margin: '32px 0 8px' }}
            />
            <ImageUpload
              imagePath={picsData && picsData.length > 1 ? picsData[1] : ''}
              directory="profile_tmp"
              fileName="company_pic2"
              fileCategory="company"
              placeholder="image"
              aspectRatio={16 / 9}
              width={480}
              height={270}
              disabled={requesting}
              withPreview
              isSquare
              onComplete={(path) => onPicsUpload(path, 1)}
            />
            <SmallText>※ 推奨サイズ：横480px以上、縦270px以上。縦:横=16:9</SmallText>
            <FormError message={errors.pics?.message} style={{ marginBottom: '8px' }} />

            <FormSectionTitle
              title="企業紹介URL"
              titleSize="18px"
              style={{ margin: '32px 0 8px' }}
            />
            <MultiInput
              label=""
              fields={arrayFields['urls'].fields}
              control={control}
              errors={errors}
              keyName="urls"
              placeholder="https://spready.co.jp/"
              appendText="URLを追加"
              append={() => appendField('urls')}
              remove={() => removeEmptyFields('urls')}
            />
            <FormError message={errors.urls?.message} style={{ marginBottom: '8px' }} />

            <FormSectionTitle
              title="企業のコピーや理念など"
              titleSize="18px"
              style={{ margin: '32px 0 8px' }}
            />
            <Controller
              name="copy"
              control={control}
              render={({ field }) => <FormInput {...field} hasError={!!errors.copy?.message} />}
            />
            <FormError message={errors.copy?.message} style={{ marginBottom: '8px' }} />

            <FormSectionTitle
              title="HERP連携用WebHookURL"
              titleSize="18px"
              style={{ margin: '32px 0 8px' }}
            />
            <Controller
              name="herp_webhook_url"
              control={control}
              render={({ field }) => (
                <FormInput {...field} hasError={!!errors.herp_webhook_url?.message} />
              )}
            />
            <FormError message={errors.herp_webhook_url?.message} style={{ marginBottom: '8px' }} />

            <FormSectionTitle
              title="Slack通知用WebHookURL"
              titleSize="18px"
              style={{ margin: '32px 0 8px' }}
            />
            <Controller
              name="slack_webhook_url"
              control={control}
              render={({ field }) => (
                <FormInput {...field} hasError={!!errors.slack_webhook_url?.message} />
              )}
            />
            <FormError
              message={errors.slack_webhook_url?.message}
              style={{ marginBottom: '8px' }}
            />
            <Button disabled={requesting} onClick={testSlackWebhook}>
              連携テストする
            </Button>

            <ActionButton
              bgColor="secondary-dark"
              style={{ margin: '32px auto 0' }}
              onClick={onSubmit}
            >
              次へ
            </ActionButton>
          </BgWhite>
        </FormContainer>
      </Wrapper>
    </>
  );
};
