import styled from '@emotion/styled';
import { SpreaderCard } from 'ui/components/company/patterns/SpreaderCard/SpreaderCard';
import { MeetRequestItem } from 'ui/components/company/patterns/MeetRequestItem/MeetRequestItem';
import { SpreaderFilter } from 'ui/components/company/patterns/SpreaderFilter/SpreaderFilter';
import {
  MEET_REQUEST_TAB,
  MeetRequestTabs,
} from 'ui/components/company/features/MeetRequestTabs/MeetRequestTabs';
import { MeetRequestDesc } from 'ui/components/company/features/MeetRequestDesc/MeetRequestDesc';
import { Pager } from 'ui/components/company/features/Pager/Pager';
import { PageTitle } from 'ui/components/company/elements/PageTitle/PageTitle';
import { useMeetRequestLayout } from './useMeetRequest';

const Wrapper = styled.div`
  padding-block: 16px;
`;

const Spreaders = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(265px, 1fr));
  column-gap: 8px;
  row-gap: 20px;
`;

const PagerWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-block: 10px 20px;
`;

/**
 * 企業系 会いたいリクエスト
 */
const CompanyMeetRequestLayout = () => {
  const {
    currentTab,
    setCurrentTab,
    page,
    setPage,
    totalPage,
    setFilterAndFetch,
    spreaders,
    meetRequests,
  } = useMeetRequestLayout();
  return (
    <Wrapper>
      <PageTitle title="会いたいリクエスト" />
      <MeetRequestTabs value={currentTab} onSelect={setCurrentTab} />

      {currentTab === MEET_REQUEST_TAB.ALL ? (
        <>
          <MeetRequestDesc />
          <SpreaderFilter onSearch={setFilterAndFetch} />
          <Spreaders>
            {spreaders.map((spreader) => (
              <SpreaderCard key={spreader.id} user={spreader} />
            ))}
          </Spreaders>
        </>
      ) : null}

      {currentTab === MEET_REQUEST_TAB.WAITING_ACCEPT || currentTab === MEET_REQUEST_TAB.CONNECTED
        ? meetRequests.map((meetRequest) => (
            <MeetRequestItem key={meetRequest.id} meetRequest={meetRequest} />
          ))
        : null}

      <PagerWrapper>
        <Pager page={page} total={totalPage} onChange={setPage} />
      </PagerWrapper>
    </Wrapper>
  );
};

export default CompanyMeetRequestLayout;
