import { API_LOCATION_SPREADY } from 'infrastructure/config';
import {
  IDeleteInput,
  IDeleteResponse,
  IChatsClient,
} from 'interfaceAdapter/gatewaySchemas/IChatsClient';

export class ChatsClient implements IChatsClient {
  async delete(input: IDeleteInput): Promise<IDeleteResponse> {
    const { token, chatId } = input;
    const path = `${API_LOCATION_SPREADY}/user/chats/${chatId}`;
    const method = 'DELETE';
    const headers = {
      'Content-Type': 'application/json',
      'X-HTTP-AUTH-TOKEN': token,
    };
    try {
      const response = await fetch(path, {
        method,
        headers,
      });
      return response.json();
    } catch {
      throw new Error('処理に失敗しました');
    }
  }
}
