import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useModal } from '../../screens/modals/useModal';

/**
 * サービス利用の説明モーダルに関するHooks
 */
export const useUsageDescriptionModal = () => {
  const { openModal, closeModal, createModal } = useModal();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    // 下書きを新規作成する場合にモーダルを表示
    if (searchParams.has('new')) {
      openModal();
    }
  }, []);

  return {
    openModal,
    closeModal,
    createModal,
  };
};
