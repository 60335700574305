import { useEffect, useState, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import yup from 'utils/yup';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { NewTeamRepository } from 'interfaceAdapter/repositories/NewTeam';

const schema = yup
  .object()
  .shape({
    name: yup.string().default('').optional(),
    corporate_number: yup.string().required(),
    copy: yup.string().default('').optional(),
    address: yup.string().default('').required(),
    zipcode: yup.string().default('').required(),
    url: yup
      .string()
      .matches(/^(http(s)?:\/\/.*)?$/, '正しく入力してください')
      .default('')
      .required(),
    president_name: yup.string().default('').optional(),
    capital: yup
      .number()
      .transform((convertedValue, value) => (value === '' ? null : convertedValue))
      .optional()
      .nullable()
      .typeError('正しく入力してください'),
    employee: yup
      .number()
      .transform((convertedValue, value) => (value === '' ? null : convertedValue))
      .optional()
      .nullable()
      .typeError('正しく入力してください'),
    about_company: yup.string().default('').optional(),
    icon_image: yup.string().default('').optional(),
    logo_display_enable: yup.boolean().default(true).required(),
    herp_webhook_url: yup
      .string()
      .matches(/^(http(s)?:\/\/.*)?$/, '正しく入力してください')
      .default('')
      .optional(),
    pics: yup.array(yup.string().required()).default([]).required(),
    urls: yup
      .array(
        yup.object({
          text: yup
            .string()
            .matches(/^(http(s)?:\/\/.*)?$/, '正しく入力してください')
            .default(''),
        }),
      )
      .default([{ text: '' }]),
    slack_webhook_url: yup
      .string()
      .matches(/^(http(s)?:\/\/.*)?$/, '正しく入力してください')
      .default('')
      .optional(),
  })
  .required();
type Form = yup.InferType<typeof schema>;

export const useTeamCreateConfirm = () => {
  const diContainer = useContext(diContainerContext);
  const teamCreateRepository = diContainer.resolve(NewTeamRepository);
  const [loading, setLoading] = useState(true);
  const [requesting, setRequesting] = useState(false);
  const navigate = useNavigate();
  const {
    setValue,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<Form>({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const onSubmit = handleSubmit(async (data) => {
    setRequesting(true);
    try {
      const urls = getValues('urls');
      const filteredUrls = urls.filter((url) => url.text !== '');

      const teamProfile = {
        ...data,
        urls: filteredUrls.map((url) => url.text),
        capital: data.capital ? data.capital : null,
        employee: data.employee ? data.employee : null,
        copy: data.copy ? data.copy : null,
        president_name: data.president_name ? data.president_name : null,
        herp_webhook_url: data.herp_webhook_url ? data.herp_webhook_url : null,
        slack_webhook_url: data.slack_webhook_url ? data.slack_webhook_url : null,
      };
      await teamCreateRepository.create(teamProfile);
      teamCreateRepository.remove();
      setTimeout(() => (window.location.href = '/business/registration/complete/'), 1500);
    } finally {
      setRequesting(false);
    }
  });

  useEffect(() => {
    const initialFetch = async () => {
      const result = await teamCreateRepository.get();
      if (result) {
        setValue('name', result.name);
        setValue('zipcode', result.zipcode ?? '');
        setValue('address', result.address ?? '');
        setValue('corporate_number', result.corporate_number ?? '');
        setValue('url', result.url ?? '');
        setValue('president_name', result.president_name ?? '');
        setValue('capital', result.capital ?? '');
        setValue('employee', result.employee ?? '');
        setValue('about_company', result.about_company ?? '');
        setValue('icon_image', result.icon_image ?? '');
        setValue('herp_webhook_url', result.herp_webhook_url ?? '');
        setValue('slack_webhook_url', result.slack_webhook_url ?? '');
        setValue('pics', result.pics ?? []);
        setValue(
          'urls',
          result.urls ? result.urls.map((url: string) => ({ text: url })) : [{ text: '' }],
        );
        setValue('logo_display_enable', result.logo_display_enable ?? true);
        setValue('copy', result.copy ?? '');
      } else {
        navigate('/workgroup/create/');
      }
      setLoading(false);
    };
    initialFetch();
  }, [location]);

  return {
    loading,
    errors,
    requesting,
    getValues,
    onSubmit,
  };
};
