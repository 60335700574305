import styled from '@emotion/styled';
import { FC } from 'react';
import { PublicHeader } from 'ui/components/company/features/PublicHeader/PublicHeader';

const Body = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-items: center;
`;

export const LineAccountLinkFailed: FC = () => {
  return (
    <>
      <PublicHeader title="LINEアカウント連携" />
      <Body>
        <>
          <h2>連携失敗</h2>
          <p>アカウント連携に失敗しました。</p>
          <p>お手数ですが最初からやり直してください。</p>
        </>
      </Body>
    </>
  );
};
