import { ComponentPropsWithoutRef, forwardRef } from 'react';
import styled from '@emotion/styled';

type Props = ComponentPropsWithoutRef<'textarea'> & {
  fontSize?: string;
  resize?: 'none' | 'vertical' | 'horizontal' | 'both';
  placeholderColor?: string;
  bgColor?: string;
  maxLength?: number;
  showLength?: boolean;
};

const TextAreaElement = styled.textarea<{
  disabled: boolean;
  fontSize?: Props['fontSize'];
  resize?: Props['resize'];
  placeholderColor?: Props['placeholderColor'];
  bgColor?: Props['bgColor'];
}>`
  color: rgba(0, 0, 0, 0.87);
  min-height: 57px;
  max-height: 420px;
  width: 100%;
  resize: ${(props) => props.resize || 'none'};
  padding: 8px 10px;
  border: 1px solid var(--gray-light-2);
  border-radius: 4px;
  font-size: ${(props) => props.fontSize || 'var(--font-size--large)'};
  ${(props) => props.bgColor && `background-color: ${props.bgColor};`}
  cursor: ${(props) => (props.disabled ? 'not-allowed' : null)};
  &:focus {
    border: 1px solid var(--gray-light-2);
    outline: none;
  }

  &::placeholder {
    padding: 10px;
    ${(props) => props.placeholderColor && `color: ${props.placeholderColor};`}
  }
`;

const Length = styled.div`
  color: var(--gray-dark-2);
  text-align: right;
  font-size: 12px;
  width: 100%;
`;

/**
 * テキストエリアコンポーネント
 */
export const TextArea = forwardRef<HTMLTextAreaElement, Props>(
  (
    {
      disabled = false,
      placeholder,
      value,
      fontSize,
      resize,
      rows = 2,
      placeholderColor,
      bgColor,
      maxLength = 2000,
      showLength = false,
      onChange,
      onFocus = () => {},
      onBlur = () => {},
    },
    ref,
  ) => {
    return (
      <>
        <TextAreaElement
          ref={ref}
          value={value}
          rows={rows}
          disabled={disabled}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          fontSize={fontSize}
          resize={resize}
          placeholder={placeholder}
          placeholderColor={placeholderColor}
          bgColor={bgColor}
          maxLength={maxLength}
        />
        {showLength && (
          <Length>
            {value?.toString().length || 0} / {maxLength}
          </Length>
        )}
      </>
    );
  },
);
TextArea.displayName = 'TextArea';
