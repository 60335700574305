import styled from '@emotion/styled';
import HelpIcon from '@mui/icons-material/Help';
import { Controller } from 'react-hook-form';
import dayjs from 'dayjs';
import { useMyPageProfile } from './useMyPageProfile';
import { ToggleFormLabel } from 'ui/components/user/elements/FormLabel/ToggleFormLabel';
import { mqUser } from 'utils/responsive';
import { ToggleFormInput } from 'ui/components/user/elements/Input/ToggleFormInput';
import { GroupedToggleFormInput } from 'ui/components/user/elements/Input/GroupedToggleFormInput';
import { GroupedToggleFormSelect } from 'ui/components/user/elements/Select/GroupedToggleFormSelect';
import { ToggleSegmentSelector } from 'ui/components/user/screens/modals/SegmentSelector/ToggleSegmentSelector';
import { Switcher } from 'ui/components/user/elements/Switcher/Switcher';
import { ImageUploader } from 'ui/components/user/patterns/ImageUploader/ImageUploader';
import { UserIcon } from 'ui/components/user/elements/UserIcon/UserIcon';
import { FormButton } from 'ui/components/user/elements/Button/FormButton';
import { SnackBar } from 'ui/components/user/elements/SnackBar/SnackBar';
import { ToggleFormTextArea } from 'ui/components/user/elements/TextArea/ToggleFormTextArea';
import { Tag } from 'ui/components/user/elements/Tag/Tag';
import { SkillsSelector } from 'ui/components/user/screens/modals/SkillsSelector/SkillsSelector';
import { Checkbox } from 'ui/components/user/elements/Checkbox/Checkbox';
import { Button } from 'ui/components/user/elements/Button/Button';
import { ResendEmailAuth } from 'ui/components/user/patterns/ResendEmailAuth/ResendEmailAuth';
import { useModal } from 'ui/components/user/screens/modals/useModal';
import { SimpleModalContent } from 'ui/components/user/elements/SimpleModalContent/SimpleModalContent';
import { TextLink } from 'ui/components/user/elements/TextLink/TextLink';

const Card = styled.div`
  margin-top: 16px;
  display: flex;
  width: 100%;
  padding: 32px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  border-radius: 16px;
  background-color: var(--spr-white);
  word-break: break-all;
`;

const Head = styled.div`
  color: var(--gray-dark-3);
  font-size: 14px;
  font-weight: var(--font-weight--bolder);
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const Actions = styled.div`
  display: flex;
  gap: 10px;
`;

const Separator = styled.div`
  width: 1px;
  height: 16px;
  background-color: var(--gray-light-1);
`;

const Group = styled.div`
  position: relative;
  width: 100%;
`;

const Names = styled.div`
  display: flex;

  > * {
    max-width: 35%;
    flex: 1;
  }
  ${mqUser('SP')} {
    > * {
      max-width: 45%;
    }
  }
`;

const Birthday = styled.div`
  display: flex;
  width: 70%;
  > * {
    flex: 1;
  }
  ${mqUser('SP')} {
    width: 100%;
    > * {
      max-width: 33%;
    }
  }
`;

const Text = styled.div`
  width: 100%;
  font-size: var(--font-size--large);
`;

const GridTemplate = styled.div`
  ${(props: { isEditing?: boolean }) =>
    !props.isEditing &&
    `
      display: grid;
      grid-template-columns: 1fr 5fr;
    `}

  input {
    margin-bottom: 8px;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 50px;
  margin-block: 20px 15px;
  padding-inline: 40px;
`;

const IconDesc = styled.p`
  margin: 0;
  margin-left: 10px;
  margin-bottom: 20px;
  font-size: 9px;
  line-height: 1.4em;
`;

const IconPreview = styled.div`
  margin-left: auto;
  display: flex;
  position: absolute;
  right: 70px;

  ${mqUser('SP')} {
    width: 100%;
    position: relative;
    right: 0;

    > * {
      margin: auto;
    }
  }
`;

const Small = styled.div`
  color: var(--gray-dark-2);
  font-size: 14px;
  white-space: pre-wrap;

  ${(props: { position?: string }) =>
    props.position === 'bottom' ? 'margin-top: 8px;' : 'margin-bottom: 8px;'}
`;

const Border = styled.div`
  width: 100%;
  height: 1px;
  background-color: #d9d9d9;
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;
  > * {
    width: 50%;
  }

  ${mqUser('SP')} {
    flex-direction: column-reverse;
    > * {
      width: 100%;
    }
  }
`;

const Tags = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 8px;
  row-gap: 4px;
`;

const GrayBorder = styled.div`
  width: 100%;
  border-radius: 8px;

  ${(props: { disabled?: boolean }) =>
    !props.disabled &&
    `
  border: 1px solid #d9d9d9;
  padding: 16px 16px 8px 16px;
  `};
`;

const Buttons = styled.div`
  display: flex;
  gap: 10px;
  white-space: nowrap;
  margin-top: 8px;
  width: 80%;

  ${mqUser('SP')} {
    width: 100%;
  }
`;

const ProfileUrl = styled.div`
  margin-bottom: 10px;
`;

const HandleDirection = styled.div`
  display: flex;
  flex-direction: ${(props: { isEditing?: boolean }) => (props.isEditing ? 'column' : 'row')};
  align-items: center;

  ${mqUser('SP')} {
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 20px;
  }

  > p {
    position: relative;
    font-size: 16px;
    margin: 0 0 8px 0;
    width: 100%;
    ${(props: { isEditing?: boolean }) =>
      props.isEditing &&
      `
    text-align: left;
    margin-bottom: 12px;
    `}
  }
`;

const DirectionColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: left;
  width: 100%;

  > div {
    text-align: left;
    width: 100%;
  }
`;

const Help = styled(HelpIcon)`
  opacity: 0.5;
  cursor: pointer;
  margin-left: 2px;
  position: relative;
  top: 2px;
`;

/**
 * マイページ プロフィール
 */
export const MyPageProfile: React.FC = () => {
  const {
    user,
    yearOptions,
    monthOptions,
    dayOptions,
    isEditing,
    setIsEditing,
    segments,
    skills,
    links,
    showSnackbar,
    applyingForChangeEmail,
    setShowSnackbar,
    removeEmptyLinks,
    watch,
    control,
    errors,
    dirtyFields,
    requesting,
    onImageUpload,
    onSubmit,
  } = useMyPageProfile();

  const { createModal, openModal } = useModal();

  // 現在のメールアドレス表示制御
  const RenderEmailDisplay = () => {
    return user.email ? (
      <>
        {!isEditing && applyingForChangeEmail && <p>有効なメールアドレス</p>}
        <p>{user.email}</p>
      </>
    ) : null;
  };

  // 説明文表示制御
  const RenderEmailDescription = () => {
    const isChangeRequested = isEditing && (user.email || applyingForChangeEmail);
    const shouldShowChangeEmailMessage =
      isEditing && dirtyFields.email && watch('email') !== user.email;

    if (!isChangeRequested && !shouldShowChangeEmailMessage) {
      return null;
    }

    return (
      <DirectionColumn>
        {isChangeRequested && (
          <Small>
            変更先メールアドレス（変更申請後、認証が完了していない場合もここに表示されます）
          </Small>
        )}
        {shouldShowChangeEmailMessage && (
          <Small>
            保存すると認証メールが送信されるので、メール本文中の認証リンクをクリックして手続きを完了してください
          </Small>
        )}
      </DirectionColumn>
    );
  };

  return (
    <div id="profile-top-position">
      <Card>
        <SnackBar
          open={showSnackbar}
          setOpen={setShowSnackbar}
          message="プロフィールを変更しました"
        />
        <Head>
          プロフィール
          <Actions>
            {isEditing ? (
              <>
                <TextLink status="default" fontSize="13px" onClick={() => setIsEditing(false)}>
                  キャンセル
                </TextLink>
                <Separator />
                <TextLink status="primary" fontSize="13px" onClick={onSubmit}>
                  保存
                </TextLink>
              </>
            ) : (
              <TextLink status="primary" fontSize="13px" onClick={() => setIsEditing(true)}>
                編集
              </TextLink>
            )}
          </Actions>
        </Head>
        <Group>
          {isEditing ? (
            <>
              <ToggleFormLabel title="アイコン画像" isEditing={isEditing} />
              <IconWrapper>
                <ImageUploader
                  imagePath={watch('icon_image')}
                  directory="profile"
                  fileName="icon"
                  fileCategory="user"
                  disabled={requesting}
                  withPreview
                  onComplete={onImageUpload}
                />
              </IconWrapper>
              <IconDesc>正方形の画像をアップしてください。縦横比が変わる場合があります。</IconDesc>
            </>
          ) : (
            <IconPreview>
              <UserIcon src={user.icon_image} size={75} />
            </IconPreview>
          )}
        </Group>
        <Group>
          <ToggleFormLabel title="ユーザー名" isEditing={isEditing} />
          <Help fontSize="small" onClick={openModal} />
          {createModal(
            <SimpleModalContent
              title=""
              description="「Spready」上で表示されるユーザー名です。<br />入力しない場合ご登録のお名前が表示されます。"
            />,
          )}
          <Controller
            name="spreader_name"
            control={control}
            render={({ field }) => (
              <ToggleFormInput
                {...field}
                placeholder="ユーザー名"
                error={errors.spreader_name?.message}
                isEditing={isEditing}
                width="70%"
              />
            )}
          />
        </Group>
        <Group>
          <ToggleFormLabel title="お名前" isRequired isEditing={isEditing} />
          <Names>
            <Controller
              name="last_name"
              control={control}
              render={({ field }) => (
                <GroupedToggleFormInput
                  {...field}
                  placeholder="姓"
                  error={errors.last_name?.message}
                  isEditing={isEditing}
                  position="first"
                />
              )}
            />
            <Controller
              name="first_name"
              control={control}
              render={({ field }) => (
                <GroupedToggleFormInput
                  {...field}
                  placeholder="名"
                  error={errors.first_name?.message}
                  isEditing={isEditing}
                  position="last"
                />
              )}
            />
          </Names>
        </Group>
        <Group>
          <ToggleFormLabel title="生年月日" isRequired isEditing={isEditing} />
          {isEditing ? (
            <Birthday>
              <Controller
                name="year"
                control={control}
                render={({ field }) => (
                  <GroupedToggleFormSelect
                    {...field}
                    options={yearOptions}
                    placeholder="年"
                    name="年を選択"
                    error={errors.year?.message || errors.month?.message || errors.day?.message}
                    isEditing={isEditing}
                    position="first"
                  />
                )}
              />
              <Controller
                name="month"
                control={control}
                render={({ field }) => (
                  <GroupedToggleFormSelect
                    {...field}
                    options={monthOptions}
                    placeholder="月"
                    name="月を選択"
                    isEditing={isEditing}
                    position="middle"
                  />
                )}
              />
              <Controller
                name="day"
                control={control}
                render={({ field }) => (
                  <GroupedToggleFormSelect
                    {...field}
                    options={dayOptions}
                    placeholder="日"
                    name="日を選択"
                    isEditing={isEditing}
                    position="last"
                  />
                )}
              />
            </Birthday>
          ) : (
            <Text>{dayjs(user?.birthday).format('YYYY年MM月DD日')}</Text>
          )}
        </Group>

        <Group>
          <ToggleFormLabel title="メールアドレス" isEditing={isEditing} />
          <HandleDirection isEditing={isEditing}>
            <RenderEmailDisplay />
            {isEditing && (
              <>
                <RenderEmailDescription />
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <ToggleFormInput
                      {...field}
                      placeholder="メールアドレス"
                      error={errors.email?.message}
                      isEditing={isEditing}
                      width="70%"
                    />
                  )}
                />
              </>
            )}
          </HandleDirection>
          {!isEditing && applyingForChangeEmail && (
            <HandleDirection>
              <p>変更申請中または未認証</p>
              <ResendEmailAuth applyingForChangeEmail={applyingForChangeEmail} />
            </HandleDirection>
          )}
        </Group>

        {isEditing && (
          <>
            <Border />
            <Group>
              <ToggleFormLabel title="パスワード" isEditing />
              <Small>パスワードを変更したい場合のみ入力してください</Small>
              <Controller
                name="password"
                control={control}
                render={({ field }) => (
                  <ToggleFormInput
                    {...field}
                    placeholder="Password"
                    error={errors.password?.message}
                    isEditing
                    width="70%"
                  />
                )}
              />
            </Group>
            <Border />
          </>
        )}

        <Group>
          <ToggleFormLabel title="所属企業・職種情報" />
          <GrayBorder>
            <GridTemplate isEditing={isEditing}>
              <ToggleFormLabel title="業界" isRequired isEditing={isEditing} bold={isEditing} />
              <Controller
                name="industry"
                control={control}
                render={({ field }) => (
                  <ToggleSegmentSelector
                    title="業界"
                    segments={segments.company}
                    selectedSegment={field.value}
                    error={errors.industry?.child?.message}
                    setSelectedSegment={field.onChange}
                    isEditing={isEditing}
                    width="80%"
                  />
                )}
              />
              <ToggleFormLabel title="職種" isRequired isEditing={isEditing} bold={isEditing} />
              <Controller
                name="job"
                control={control}
                render={({ field }) => (
                  <ToggleSegmentSelector
                    title="職種"
                    segments={segments.job}
                    selectedSegment={field.value}
                    error={errors.job?.child?.message}
                    setSelectedSegment={field.onChange}
                    isEditing={isEditing}
                    width="80%"
                  />
                )}
              />
              <ToggleFormLabel title="所属企業" isEditing={isEditing} bold={isEditing} />
              <Controller
                name="company_name"
                control={control}
                render={({ field }) => (
                  <ToggleFormInput
                    {...field}
                    placeholder="企業名"
                    error={errors.company_name?.message}
                    isEditing={isEditing}
                    width="80%"
                  />
                )}
              />
              <ToggleFormLabel title="役職名" isEditing={isEditing} bold={isEditing} />
              <Controller
                name="company_job"
                control={control}
                render={({ field }) => (
                  <ToggleFormInput
                    {...field}
                    placeholder="役職名"
                    error={errors.company_job?.message}
                    isEditing={isEditing}
                    width="80%"
                  />
                )}
              />
            </GridTemplate>
            <Group>
              <br />
              <ToggleFormLabel title="プロフィールURL" isEditing={isEditing} bold={isEditing} />

              {links.fields.map((field, index) => (
                <ProfileUrl key={field.id}>
                  <Controller
                    name={`links.${index}.url`}
                    control={control}
                    render={({ field }) => (
                      <ToggleFormInput
                        {...field}
                        isEditing={isEditing}
                        placeholder="https://"
                        width="80%"
                      />
                    )}
                  />
                </ProfileUrl>
              ))}

              {isEditing && (
                <>
                  <Small>Facebookやプロフィール情報のリンクなどのURL</Small>
                  <Buttons>
                    <Button button_type="weak_red" width="100%" onClick={removeEmptyLinks}>
                      - 未入力項目を削除
                    </Button>
                    <Button
                      button_type="gray"
                      width="100%"
                      onClick={() => links.append({ url: '' })}
                    >
                      + 追加
                    </Button>
                  </Buttons>
                </>
              )}
            </Group>
            <br />
            <br />
            <Controller
              name="is_company_info_open"
              control={control}
              render={({ field }) => (
                <Checkbox
                  id="is_company_info_open"
                  checked={field.value}
                  onChange={field.onChange}
                  disabled={!isEditing}
                >
                  あなたの所属企業・役職名・プロフィールURLを「会いたいリクエスト」検索に使用する
                </Checkbox>
              )}
            />
            {isEditing && (
              <Small>
                「会いたいリクエスト」とは、Spready掲載企業が登録個人ユーザーの中から会いたいユーザーを検索し、声を掛けられるスカウトのような機能です。
                企業名、役職名、プロフィールのURLを表示することによって、会いたいリクエスト受信の可能性が高まります。
              </Small>
            )}
          </GrayBorder>
        </Group>

        <Controller
          name="mail_news_receive"
          control={control}
          render={({ field }) => (
            <Checkbox
              id="mail_news_receive"
              checked={field.value}
              onChange={field.onChange}
              disabled={!isEditing}
            >
              Spreadyの新着セッションのメルマガを受信する
            </Checkbox>
          )}
        />

        <GrayBorder disabled={!watch('job_news_recieve')}>
          <Group>
            <ToggleFormLabel title="お仕事の紹介" isEditing={isEditing} />
            <Group>
              <Controller
                name="job_news_recieve"
                control={control}
                render={({ field }) => (
                  <Switcher
                    id="job_news_recieve"
                    checked={field.value}
                    onChange={field.onChange}
                    disabled={!isEditing}
                  >
                    副業・業務委託の紹介を希望する
                  </Switcher>
                )}
              />
            </Group>
            {watch('job_news_recieve') && (
              <>
                <Group>
                  <br />
                  <ToggleFormLabel title="活かせるスキル" isEditing={isEditing} />
                  <Controller
                    name="skill_ids"
                    control={control}
                    render={({ field }) => (
                      <Tags>
                        {field.value.map((id) => (
                          <Tag
                            key={id}
                            bgColor="var(--spr-primary-paler)"
                            color="var(--spr-primary-dark)"
                            border="1px solid var(--spr-primary-dark)"
                            disabled={!isEditing}
                            onRemove={() =>
                              field.onChange(field.value.filter((v: number) => v !== id))
                            }
                          >
                            {skills.getChildName(id)}
                          </Tag>
                        ))}
                        {isEditing && (
                          <SkillsSelector
                            skills={skills}
                            selectedSkillIds={watch<'skill_ids'>('skill_ids')}
                            onChange={field.onChange}
                          />
                        )}
                      </Tags>
                    )}
                  />
                </Group>
                <Group>
                  <br />
                  <ToggleFormLabel
                    title="対応可能な業務や過去委託されたお仕事内容"
                    isEditing={isEditing}
                  />
                  <Controller
                    name="past_job_description"
                    control={control}
                    render={({ field }) => (
                      <ToggleFormTextArea
                        {...field}
                        rows={7}
                        isEditing={isEditing}
                        placeholder={`入力例）\n\n・アクセラレーターの事務局サポート・メンター\n・エンジニア採用（戦略立案、母集団形成）`}
                      />
                    )}
                  />
                </Group>
              </>
            )}
          </Group>
        </GrayBorder>
        {isEditing && (
          <ButtonWrapper>
            <FormButton
              color="#F1A204"
              bgColor="var(--spr-white)"
              border="1px solid #F1A204"
              onClick={() => setIsEditing(false)}
            >
              キャンセル
            </FormButton>
            <FormButton onClick={onSubmit}>保存</FormButton>
          </ButtonWrapper>
        )}
      </Card>
    </div>
  );
};
