import { useEffect, useContext } from 'react';
import styled from '@emotion/styled';
import CloseIcon from '@mui/icons-material/Close';
import dayjs from 'dayjs';
import { useModal } from 'ui/components/user/screens/modals/useModal';
import { giftsContext } from 'application/contexts/useGifts';
import { DELIVERY_TYPE } from 'domain/entities/Reward/Reward';
import { mqUser } from 'utils/responsive';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { UserGiftQuery } from 'interfaceAdapter/queries/UserGift';
import { Button } from 'ui/components/user/elements/Button/Button';

const ModalContainer = styled.div`
  background-color: var(--spr-white);
  max-width: 80vw;
  @supports (width: 100svw) {
    max-width: 80svw;
  }
  height: auto;
  border-radius: 8px;
  padding: 24px;

  p {
    margin: 0;
  }
  ${mqUser('SP')} {
    width: 80vw;
    @supports (width: 100svw) {
      width: 80svw;
    }
  }
`;

const ModalContent = styled.div`
  background-color: var(--spr-white);
  display: block;
  border-radius: 4px;
  box-sizing: border-box;
  overflow: auto;
  outline: 0;
  width: 100%;
  height: 100%;
`;

const Top = styled.div`
  margin: 0 0 20px;
  display: block;

  h2 {
    font-size: 20px;
    font-weight: 500;
  }
`;

const Close = styled.div`
  right: 10px;
  top: 10px;
  cursor: pointer;
  width: 40px;
  height: 40px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const AlignCenter = styled.div`
  text-align: center;
  margin-bottom: 8px;
`;

const GiftListContainer = styled.div`
  max-height: 300px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const GiftContainer = styled.div`
  padding: 8px 12px;
  border: 1px solid var(--gray-light-2);
  border-radius: 8px;
`;

const Flex = styled.div`
  display: flex;
`;

const DeliveryTxt = styled.div`
  text-align: center;
  max-width: 50px;
  height: 15px;
  font-size: 10px;
  line-height: 15px;
  color: var(--gray-dark-3);
  background: var(--gray-light-3);
  border-radius: 2px;
  padding: 2px 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
`;

const StampTxt = styled.div`
  font-size: 10px;
  line-height: 15px;
  color: var(--gray-dark-2);
  margin-left: 4px;
`;

const DateExchanged = styled.p`
  font-size: 10px;
  line-height: 14px;
  color: var(--gray-dark-2);
`;
/**
 * ギフトの交換履歴のモーダル
 */
export const GiftExchangedHistory: React.FC = () => {
  const { openModal, closeModal, createModal } = useModal();
  const diContainer = useContext(diContainerContext);
  const userGiftQuery = diContainer.resolve(UserGiftQuery);
  const { exchangedGifts, fetchExchangedGifts } = useContext(giftsContext);

  useEffect(() => {
    fetchExchangedGifts(userGiftQuery.getExchangedGifts);
  }, []);

  return (
    <>
      <Button status="cancel" onClick={openModal}>
        交換履歴
      </Button>
      {createModal(
        <ModalContainer>
          <Top>
            <h2>ギフトの交換履歴</h2>
            <Close>
              <CloseIcon style={{ width: 24, height: 24 }} onClick={closeModal} />
            </Close>
          </Top>
          <AlignCenter>
            <p>これまでに以下のギフトを交換されました</p>
          </AlignCenter>
          <ModalContent>
            <GiftListContainer>
              {exchangedGifts.map((gift) => (
                <div key={gift.id}>
                  <GiftContainer>
                    <Flex>
                      <DeliveryTxt>{DELIVERY_TYPE[gift.stamp_gift.delivery_type]}</DeliveryTxt>
                      <StampTxt>{`${gift.used_ticket.user_stamp.card_number}枚目の${gift.used_ticket.user_stamp.stamp_number_of_card}個目と交換`}</StampTxt>
                    </Flex>
                    <p>{gift.stamp_gift.name}</p>
                  </GiftContainer>
                  <DateExchanged>
                    {dayjs(gift.created_at).format('YYYY/MM/DD HH:mm')}に交換
                  </DateExchanged>
                </div>
              ))}
            </GiftListContainer>
          </ModalContent>
        </ModalContainer>,
      )}
    </>
  );
};
