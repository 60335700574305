import { SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';

const Badge = styled.span<{ size: number; css?: SerializedStyles }>`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  background-color: var(--warn);
  color: var(--white);
  text-align: center;
  border-radius: 50%;
  font-weight: var(--font-bold);
  font-size: var(--font-small);

  ${({ css }) => css}
`;

interface Props {
  className?: string;
  size: number;
  count: number;
  css?: SerializedStyles;
}

/**
 * 通知数などを表す赤い丸ポチ
 */
export const NotificationBadge: React.FC<Props> = ({ className, size, count, css }) => {
  return (
    <Badge className={className} size={size} css={css}>
      {count}
    </Badge>
  );
};
