import { useContext } from 'react';
import styled from '@emotion/styled';
import { TEAM_ROLE_TYPE } from 'domain/entities/Team/TeamRole';
import { companyUserContext } from 'application/contexts/useCompanyUser';
import { companyAppContext } from 'application/contexts/useCompanyApp';
import MenuIcon from 'ui/images/company/icons/menu.svg';
import { HeaderTeamInfo } from 'ui/components/company/patterns/HeaderTeamInfo/HeaderTeamInfo';
import { UserProfilePopover } from 'ui/components/company/patterns/UserProfilePopover/UserProfilePopover';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  height: 100%;
  padding-inline: 16px;
  background: var(--spr-white);
`;

const Menu = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2px;
  border: none;
  background: none;
  cursor: pointer;

  img {
    width: 37px;
    height: 37px;
  }
  span {
    font-size: var(--font-small);
    line-height: var(--font-small);
    font-weight: var(--font-bold);
  }
`;

export const Header: React.FC = () => {
  const { user } = useContext(companyUserContext);
  const { setIsSideOpen } = useContext(companyAppContext);

  return (
    <Wrapper>
      {user.team_role !== TEAM_ROLE_TYPE.SUPPORT_CHAT_PERMITTED && (
        <Menu
          role="button"
          aria-label="サイドメニューの開閉"
          onClick={() => setIsSideOpen((v) => !v)}
        >
          <img src={MenuIcon} alt="メニューアイコン" />
          <span>MENU</span>
        </Menu>
      )}

      {user.company && <HeaderTeamInfo team={user.company} />}

      <UserProfilePopover />
    </Wrapper>
  );
};
