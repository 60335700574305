import styled from '@emotion/styled';
import { useRoomDetail } from './useRoomDetail';
import { mqUser } from 'utils/responsive';
import { UserIcon } from 'ui/components/user/elements/UserIcon/UserIcon';
import { truncate } from 'utils/string';
import Clipboard from 'ui/images/user/clipboard_white.svg';
import { Only } from 'ui/components/user/elements/Only/Only';
import { MessageItem } from 'ui/components/user/features/MessageItem/MessageItem';
import { MessageInput } from 'ui/components/user/features/MessageInput/MessageInput';
import { RoomProjectSummary } from 'ui/components/user/features/RoomProjectSummary/RoomProjectSummary';
import { RoomMeetRequest } from 'ui/components/user/features/RoomMeetRequest/RoomMeetRequest';
import { BackButton } from 'ui/components/user/elements/BackButton/BackButton';
import { CHAT_LABEL_MAP } from 'domain/entities/ChatRoom/ChatRoom';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - var(--height-user-pc-header) - 30px - 20px);
  @supports (height: 100svh) {
    height: calc(100svh - var(--height-user-pc-header) - 30px - 20px);
  }
  padding: 16px;
  border-radius: 16px;
  background-color: var(--spr-white);
  box-shadow: 0px 2px 4px 0px #0000000d;

  ${mqUser('SP')} {
    flex: unset;
    position: absolute;
    top: 100vh;
    left: 0;
    @supports (width: 100svw) {
      width: 100svw;
    }
    height: calc(100vh - var(--height-user-pc-header) - 30px);
    padding: 8px 16px;
    border-radius: 16px 16px 0 0;

    overflow: hidden;
    z-index: var(--z-room-detail);
    transition: transform 0.2s ease-out;
    // 下からスッと登場
    transform: translateY(
      ${(props: { isOpen: boolean }) =>
        props.isOpen ? 'calc(-100vh + var(--height-user-pc-header) + 30px)' : '0'}
    );
    @supports (height: 100svh) {
      height: calc(100svh - var(--height-user-pc-header) - 30px);
      top: 100svh;
      transform: translateY(
        ${(props: { isOpen: boolean }) =>
          props.isOpen ? 'calc(-100svh + var(--height-user-pc-header) + 30px)' : '0'}
      );
    }
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  box-shadow: 0px 2px 4px 0px #0000000d;
  ${mqUser('SP')} {
    align-items: start;
    gap: 10px;
    height: auto;
    padding-bottom: 6px;
    border-bottom: 1px solid var(--gray-light-3);
    box-shadow: none;
    z-index: var(--z-room-detail-header);
    .btn {
      margin-top: 10px;
    }
  }
`;

const Company = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  margin-left: 10px;
  ${mqUser('SP')} {
    margin-left: 0;
  }

  // 会社名
  p {
    display: inline-block;
    margin: 0;
    margin-left: 6px;
    font-weight: 700;
    font-size: 16px;
    color: var(--gray-dark-3);
    ${mqUser('SP')} {
      font-size: 14px;
    }
  }
`;

const Label = styled.div`
  height: 22px;
  padding-inline: 6px;
  background-color: var(--spr-primary-paler);
  border-radius: 6px;
  color: var(--spr-secondary);
  line-height: 22px;
  font-weight: 700;
  white-space: nowrap;
`;

const Project = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  max-width: 442px;
  margin-inline: auto 16px;
  ${mqUser('SP')} {
    width: 96px;
    height: 100%;
  }
`;
const ClipboardIcon = styled.img`
  width: 20px;
  height: 20px;
  filter: invert(1);
`;

const ProjectTitle = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 4px;
  max-width: 328px;
  p {
    margin: 0;
    font-weight: 700;
    font-size: 14px;
    line-height: 1.4;
  }
  ${mqUser('SP')} {
    width: 100%;
    max-width: unset;
    margin-block: 10px;
  }
`;

const CompanyUsers = styled.div<{ count: number }>`
  position: relative;
  display: flex;
  align-items: center;
  width: calc(40px + ${(props) => (props.count - 1) * 30}px);
  margin-left: 10px;
  ${mqUser('SP')} {
    width: 86px;
    margin-left: 4px;
  }

  // その他の人数
  > span {
    width: 32px;
    height: 32px;
    margin-left: auto;
    border: 2px solid var(--spr-white);
    border-radius: 50%;
    background-color: var(--gray-light-3);
    color: var(--gray-dark-4);
    text-align: center;
    line-height: 34px;
    ${mqUser('SP')} {
      width: 24px;
      height: 24px;
      line-height: 24px;
    }
  }
`;

const CompanyUserIcon = styled.div`
  position: absolute;
  &:nth-of-type(1) {
    left: 0;
    z-index: 3;
  }
  &:nth-of-type(2) {
    left: 30px;
    z-index: 2;
  }
  &:nth-of-type(3) {
    left: 60px;
    z-index: 1;
  }
  ${mqUser('SP')} {
    &:nth-of-type(1) {
      left: 0;
    }
    &:nth-of-type(2) {
      left: 18px;
    }
    &:nth-of-type(3) {
      left: 36px;
    }
  }
`;

const MessageWrapper = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 0 16px;
  ${mqUser('SP')} {
    padding: 0;
  }
`;

const NoData = styled.div`
  margin-top: 20px;
  text-align: center;
`;

/**
 * チャットルーム内容 表示
 */
export const RoomDetail: React.FC = () => {
  const {
    user,
    room,
    isOpen,
    messages,
    members: { companyUsers },
    meetRequests,
    sendMessage,
    removeMessage,
    onMessageSent,
    messagesRef,
    backToList,
  } = useRoomDetail();

  return (
    <Wrapper isOpen={isOpen}>
      {room && room.room_id && (
        <>
          <Header>
            <Only sp>
              <BackButton className="btn" onClick={backToList} />
            </Only>

            {room?.company && (
              <Company>
                {room.project?.privacy ? (
                  <>
                    <UserIcon src={null} alt={'企業情報：非公開'} size={40} />
                    <p>企業情報：非公開</p>
                  </>
                ) : (
                  <>
                    <UserIcon
                      src={room.company.icon_image}
                      alt={room.company.name ?? ''}
                      size={40}
                    />
                    <p>{room.company.name}</p>
                  </>
                )}
                <Label>{CHAT_LABEL_MAP[room.label]}</Label>
              </Company>
            )}
            <Project>
              {room.project && (
                <Only pc>
                  <ProjectTitle>
                    <ClipboardIcon src={Clipboard} alt="クリップボードアイコン" />
                    <p>{truncate(room.project.title, 40)}</p>
                  </ProjectTitle>
                </Only>
              )}
              <CompanyUsers count={Math.min(3, companyUsers.length)}>
                {companyUsers.slice(0, 3).map((companyUser) => (
                  <CompanyUserIcon key={companyUser.id}>
                    <UserIcon
                      src={companyUser.icon_image}
                      alt={`${companyUser.last_name} ${companyUser.first_name}`}
                      size={32}
                    />
                  </CompanyUserIcon>
                ))}
                {companyUsers.length > 3 && <span>{companyUsers.length - 3}</span>}
              </CompanyUsers>
            </Project>
          </Header>
          {room.project && (
            <Only sp>
              <ProjectTitle>
                <ClipboardIcon src={Clipboard} alt="クリップボードアイコン" />
                <p>{truncate(room.project.title, 28)}</p>
              </ProjectTitle>
            </Only>
          )}

          <MessageWrapper ref={messagesRef}>
            {/* 応募・紹介 */}
            {meetRequests.length === 0 ? <RoomProjectSummary project={room.project} /> : null}

            {/* 会いたいリクエスト */}
            {meetRequests.length > 0
              ? meetRequests.map((mr) => (
                  <RoomMeetRequest key={mr.created_at.getTime()} meetRequest={mr} />
                ))
              : null}

            {messages.length === 0 && <NoData>メッセージがまだありません</NoData>}
            {messages.map((message) => {
              // 衝突回避のためtimestamp単体ではやらないようにした
              const key = `${message.timestamp}_${message.user_type}_${message.user_id}`;
              return (
                <MessageItem
                  key={key}
                  message={message}
                  user={user}
                  removeMessage={removeMessage}
                />
              );
            })}
          </MessageWrapper>

          <MessageInput
            roomId={room.room_id}
            sendMessage={sendMessage}
            onComplete={onMessageSent}
          />
        </>
      )}
    </Wrapper>
  );
};
