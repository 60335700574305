import styled from '@emotion/styled';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { mqUser } from 'utils/responsive';
import { ActionButton } from 'ui/components/company/elements/ActionButton/ActionButton';
import { companyUserContext } from 'application/contexts/useCompanyUser';

const Wrapper = styled.div`
  height: calc(100vh - var(--height-company-pc-header));
  background-color: var(--primary);
  overflow-y: auto;
  margin: -16px -30px -60px;
  padding: 40px 20px 60px;
`;

const Content = styled.div`
  max-width: 1000px;
  margin-inline: auto;
  gap: 40px;
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  font-size: var(--font-xxxx-large);
  font-weight: var(--font-extra-bold);
  color: var(--spr-black);
  line-height: 1;
  text-align: center;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 28px;
  border-radius: 12px;
  background-color: var(--white);
`;

const Container = styled.div`
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;

  ${mqUser('SP')} {
    flex-direction: column;
    gap: 5px;
  }
`;

const LargeText = styled.p`
  font-size: var(--font-xxx-large);
  line-height: 1.5;
  font-weight: 800;
  text-align: center;
  margin: 0 auto 10px;
  ${mqUser('SP')} {
    font-size: var(--font-xx-large);
  }
`;

const MediumText = styled.p`
  font-size: var(--font-large);
  line-height: 1.5;
  font-weight: 800;
`;

const Button = styled.button`
  display: block;
  padding: 12px 20px;
  border: 1px solid var(--gray);
  outline: none;
  border-radius: 60px;
  background-color: var(--white);
  font-size: var(--font-size--medium);
  font-weight: 800;
  color: var(--accent);
  white-space: nowrap;
  cursor: pointer;
  line-height: 1.5;

  &:hover {
    opacity: 0.7;
  }
`;

const Service = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  font-size: var(--font-size--xx-large);
  font-weight: 800;
  padding: 20px 0;
`;

export const CurrentWorkGroup: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useContext(companyUserContext);
  return (
    <Wrapper>
      <Content>
        <Title>ご利用中のワークグループ（企業）</Title>

        <Card>
          {user.isMyCompanyGetActive() ? (
            <>
              <LargeText>ただいま{user.company.name}にログイン中です</LargeText>
              <Container>
                <MediumText>他のワークグループに切り替えますか？</MediumText>
                <Button onClick={() => navigate('/workgroup/switch/')}>
                  切り替え または 新規作成
                </Button>
              </Container>
            </>
          ) : (
            <>
              <LargeText>所属しているワークグループがありません</LargeText>
              <Container>
                <MediumText>ワークグループを作成しますか？</MediumText>
                <Button onClick={() => navigate('/workgroup/create/')}>新規作成</Button>
              </Container>
            </>
          )}
        </Card>
        {user.isMyCompanyGetActive() && (
          <Service>
            サービス
            <ActionButton bgColor="white" onClick={() => navigate('/workgroup/')}>
              ダッシュボードに移動
            </ActionButton>
          </Service>
        )}
      </Content>
    </Wrapper>
  );
};
