import { inject, injectable } from 'tsyringe';

import {
  ILineAccountLinkCallbackRepository,
  LineAccountLinkCallbackResponse,
} from 'application/repositorySchemas/ILineAccountLinkCallbackRepository';
import { type ILineAccountLinkCallbackClient } from 'interfaceAdapter/gatewaySchemas/ILineAccountLinkCallbackClient';

@injectable()
export class LineAccountLinkCallbackRepository implements ILineAccountLinkCallbackRepository {
  constructor(
    @inject('ILineAccountLinkCallbackClient')
    public readonly client: ILineAccountLinkCallbackClient,
  ) {}

  async callback({
    code,
    state,
  }: {
    code: string;
    state: string;
  }): Promise<LineAccountLinkCallbackResponse> {
    const response = await this.client.post({ code, state });

    if (response.data.error_code) {
      return {
        success: false,
        error_code: response.data.error_code,
      };
    }

    return { success: true };
  }
}
