import styled from '@emotion/styled';

const Wrapper = styled.div`
  padding: 6px 16px;
  border-radius: 16px;
  background-color: var(--gray-light-3);
  color: var(--gray-dark-2);
  text-align: center;
`;

/**
 * 送信取り消しされたメッセージ表示
 */
export const RemovedChat: React.FC = () => {
  return <Wrapper>このメッセージは取り消されました</Wrapper>;
};
