import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { IconButton } from 'ui/components/company/elements/IconButton/IconButton';
import { ActionButton } from 'ui/components/company/elements/ActionButton/ActionButton';
import AddIcon from 'ui/images/company/icons/add.svg';
import Person from 'ui/images/company/session-description-person.png';
import { mqUser } from 'utils/responsive';

const Wrapper = styled.div``;

const Card = styled.div`
  margin-bottom: 20px;
  padding: 40px 28px;
  border-radius: 12px;
  background-color: var(--white);
`;

const Title = styled.div`
  margin-bottom: 20px;
  font-size: var(--font-xxx-large);
  line-height: 1.2;
  font-weight: 900;
  text-align: center;
  ${mqUser('SP')} {
    font-size: var(--font-xx-large);
  }
`;

const TextBold = styled.p`
  font-size: var(--font-x-large);
  line-height: 1.5;
  font-weight: 900;
  text-align: center;
  ${mqUser('SP')} {
    font-size: var(--font-medium);
  }
`;

const TextNormal = styled.p`
  font-size: var(--font-xx-large);
  line-height: 1.5;
  font-weight: 400;
  text-align: center;
  margin: 0 auto 15px;
  ${mqUser('SP')} {
    font-size: var(--font-x-large);
  }
`;

const BelowTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;

  ${mqUser('SP')} {
    flex-direction: column;
    gap: 0;
  }
`;

const Desc = styled.div`
  position: relative;
  padding: 44px 30px 20px;
  border-radius: 20px;
  background-color: var(--accent-paler);
  ${mqUser('SP')} {
    padding: 20px 24px;
    text-align: center;
  }
`;

const DescTitle = styled.p`
  margin: 12px 0 54px;
  padding-left: 7%;
  font-size: var(--font-xxx-large);
  line-height: 1.2;
  color: var(--accent);
  font-weight: 900;
  text-align: center;
  ${mqUser('SP')} {
    margin: 12px 0 24px;
    font-size: var(--font-x-large);
  }
`;

const Img = styled.img`
  position: absolute;
  top: -55px;
  left: 3%;
  width: 156px;
  height: 190px;
  ${mqUser('SP')} {
    position: static;
  }
`;

const ButtonContainer = styled.div`
  align-items: center;
  max-width: 380px;
  margin-inline: auto;
`;

const Button = styled.button`
  display: block;
  padding: 15px;
  border: 3px solid var(--gray-dark-2);
  outline: none;
  border-radius: 60px;
  background-color: var(--white);
  font-size: var(--font-x-large);
  font-weight: var(--font-bold);
  color: var(--gray-dark-3);
  white-space: nowrap;
  cursor: pointer;
  width: 100%;

  &:not(:disabled):hover {
    opacity: 0.7;
  }
`;

/**
 * 企業ユーザー登録 完了画面
 */
export const CompanyUserComplete: React.FC = () => {
  const navigate = useNavigate();
  return (
    <Wrapper>
      <Card>
        <Title>ご登録ありがとうございます</Title>
        <BelowTitle>
          <TextBold>このワークグループにユーザーを招待しますか？</TextBold>
          <IconButton
            icon={AddIcon}
            buttonStyle={{ padding: '13px 20px' }}
            onClick={() => navigate('/workgroup/users/invite/')}
          >
            ユーザーを招待する
          </IconButton>
        </BelowTitle>
      </Card>

      <Card>
        <Desc>
          <Img src={Person} alt="案件を解説している人物" />
          <DescTitle>募集案件は無料で掲載できます</DescTitle>
          <TextBold>
            Spreadyは簡単な質問に答えるだけで、案件の下書きが作成されてとても便利。
            <br />
            すばやく案件掲載ができます。
          </TextBold>
        </Desc>
        <ButtonContainer>
          <ActionButton
            bgColor="secondary-dark"
            style={{ maxWidth: '100%', margin: '40px auto 0', padding: '22px 80px' }}
            onClick={() => navigate('/workgroup/projects/prepare/')}
          >
            案件を作成する
          </ActionButton>
        </ButtonContainer>
      </Card>
      <Card>
        <TextNormal>その他の機能やページにアクセスする</TextNormal>
        <ButtonContainer>
          <Button onClick={() => navigate('/workgroup/')}>ダッシュボードに移動</Button>
        </ButtonContainer>
      </Card>
    </Wrapper>
  );
};
