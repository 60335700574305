import { FC } from 'react';
import styled from '@emotion/styled';
import { useLineAccountLinkRequest } from 'ui/pages/User/LineAccountLink/useLineAccountLinkRequest';

const Body = styled.div`
  text-align: center;
  padding-top: 200px;
`;

const Image = styled.img`
  width: 30px;
`;

export const LineAccountLinkRequest: FC = () => {
  useLineAccountLinkRequest();

  return (
    <Body>
      <Image src="/loading.gif" />
    </Body>
  );
};
