import { inject, injectable } from 'tsyringe';
import { type IChatRoomFactory } from 'domain/entities/factories/ChatRoom';
import { type IMessageFactory } from 'domain/entities/factories/Message';
import { type IMeetRequestFactory } from 'domain/entities/factories/MeetRequest';
import { type ICompanyUserTokenRepository } from 'application/repositorySchemas/ICompanyUserTokenRepository';
import { type ICompanyChatRoomRepository } from 'application/repositorySchemas/ICompanyChatRoomRepository';
import { type ICompanyCastingChatArchiveClient } from 'interfaceAdapter/gatewaySchemas/ICompanyCastingChatArchive';
import { type ICompanyChatRoomMessageClient } from 'interfaceAdapter/gatewaySchemas/ICompanyChatRoomMessage';
import { type IBusinessChatClient } from 'interfaceAdapter/gatewaySchemas/IBusinessChatClient';
import { type ICompanyUserFactory } from 'domain/entities/factories/CompanyUser';
import { type ICompanyChatRoomMembersEditClient } from 'interfaceAdapter/gatewaySchemas/ICompanyChatRoomMembersEdit';
import { type IUserFactory } from 'domain/entities/factories/User';

@injectable()
export class CompanyChatRoomRepository implements ICompanyChatRoomRepository {
  constructor(
    @inject('ICompanyUserTokenRepository')
    public readonly companyUserToken: ICompanyUserTokenRepository,
    @inject('ICompanyUserFactory')
    public readonly companyUserFactory: ICompanyUserFactory,
    @inject('IChatRoomFactory')
    public readonly chatRoomFactory: IChatRoomFactory,
    @inject('IMessageFactory')
    public readonly messageFactory: IMessageFactory,
    @inject('IMeetRequestFactory')
    public readonly meetRequestFactory: IMeetRequestFactory,
    @inject('IUserFactory')
    public readonly userFactory: IUserFactory,
    @inject('ICompanyCastingChatArchiveClient')
    public readonly companyCastingChatArchiveClient: ICompanyCastingChatArchiveClient,
    @inject('ICompanyChatRoomMessageClient')
    public readonly companyChatRoomMessageClient: ICompanyChatRoomMessageClient,
    @inject('IBusinessChatClient')
    public readonly businessChatClient: IBusinessChatClient,
    @inject('ICompanyChatRoomMembersEditClient')
    public readonly companyChatRoomMembersEditClient: ICompanyChatRoomMembersEditClient,
  ) {}

  public async archiveCastingChatRoom(roomId: string) {
    await this.companyCastingChatArchiveClient.post({
      token: this.companyUserToken.get() || '',
      roomId,
    });
  }

  public async sendMessage(roomId: string, message: string, replyRequired: boolean) {
    await this.companyChatRoomMessageClient.post({
      token: this.companyUserToken.get() || '',
      roomId,
      message,
      replyRequired,
    });
  }

  public async removeChat(chatId: number) {
    await this.businessChatClient.delete({ token: this.companyUserToken.get() || '', chatId });
  }

  public async editMembers(roomId: string, userIds: number[]) {
    await this.companyChatRoomMembersEditClient.post({
      token: this.companyUserToken.get() || '',
      roomId,
      userIds,
    });
  }
}
