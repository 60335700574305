import styled from '@emotion/styled';
import { ChatInput } from 'ui/components/company/patterns/ChatInput/ChatInput';
import { MessageItem } from 'ui/components/company/features/MessageItem/MessageItem';
import { ChatDetailProps, useSupportChatDetail } from './useSupportChatDetail';
import { UserIcon } from 'ui/components/user/elements/UserIcon/UserIcon';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 20px;
  background-color: ${(props: { noData: boolean }) =>
    props.noData ? 'var(--gray-light-2)' : 'var(--spr-white)'};
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  margin-bottom: 20px;
  padding-right: 10px;
  gap: 8px;
`;

const UserIconContainer = styled.div`
  ${(props: { width?: string }) => props.width && `width: ${props.width};`}
  display: flex;
`;

const UserName = styled.span`
  font-size: 14px;
`;

const Icon = styled.div`
  display: flex;
`;
const UserId = styled.span`
  font-size: 12px;
`;

const MessageWrapper = styled.div`
  height: 100%;
  padding-inline: 16px;
  overflow-y: auto;
  flex: 1;
`;

const Meta = styled.div`
  margin-block: 20px;
  text-align: center;
  color: var(--gray-dark-1);
`;

export const SupportChatDetail: React.FC<ChatDetailProps> = (props) => {
  const { ref, user, supportChatPermittedUser, roomId, messages, onSendMessage, removeMessage } =
    useSupportChatDetail(props);

  return (
    <Wrapper noData={!roomId}>
      {roomId && (
        <>
          {user && (
            <TitleWrapper>
              <Icon>
                <UserIconContainer>
                  <UserIcon src={user.icon_image ?? undefined} size={28} />
                </UserIconContainer>
              </Icon>
              <UserName>
                {user.last_name} {user.first_name} 様
              </UserName>
              <UserId>(user_id: {user.id})</UserId>
            </TitleWrapper>
          )}
          <MessageWrapper ref={ref}>
            {messages.length > 0 && <Meta>先頭のメッセージ</Meta>}
            {messages.map((message) => (
              <MessageItem
                key={`${message.timestamp}_${message.user_type}_${message.user_id}`}
                message={message}
                companyUser={supportChatPermittedUser}
                removeMessage={removeMessage}
              />
            ))}
            {messages.length > 0 && <Meta>これ以上メッセージはありません</Meta>}
          </MessageWrapper>

          <ChatInput isAdminSupport roomId={roomId ?? ''} onSend={onSendMessage} />
        </>
      )}
    </Wrapper>
  );
};
