import { API_LOCATION_SPREADY } from 'infrastructure/config';
import {
  ILineAccountLinkCompleteClient,
  IPostInput,
  IPostResponse,
} from 'interfaceAdapter/gatewaySchemas/ILineAccountLinkCompleteClient';
import { fetchFixture } from 'utils/fetchFixture';
import fixture from './LineAccountLinkComplete.post.json';

export class LineAccountLinkCompleteClient implements ILineAccountLinkCompleteClient {
  async post(input: IPostInput): Promise<IPostResponse> {
    const PATH = `${API_LOCATION_SPREADY}/line/account-link/complete`;
    const headers = {
      'Content-Type': 'application/json',
      'X-HTTP-AUTH-TOKEN': input.token,
    };
    const method = 'POST';

    try {
      const response = input.mock
        ? await fetchFixture(fixture)
        : await fetch(`${PATH}`, {
            method,
            headers,
            // ローカルの場合はフロントとAPIが別オリジンなのでincludeにしないとCookieの送受信がされない
            credentials: process.env.REACT_APP_IS_LOCAL === 'true' ? 'include' : 'same-origin',
          });

      return response.json();
    } catch {
      throw new Error('処理に失敗しました');
    }
  }
}
