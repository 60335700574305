import { useCallback, useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import yup from 'utils/yup';
import { TEAM_ROLE_TYPE } from 'domain/entities/Team/TeamRole';
import { companyUserContext } from 'application/contexts/useCompanyUser';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { CompanyUserRepository } from 'interfaceAdapter/repositories/CompanyUser';
import { CompanyUserCheckToken } from 'application/usecases/CompanyUserCheckToken';

const schema = yup
  .object({
    email: yup.string().required().email(),
    password: yup.string().required(),
  })
  .required();

type LoginForm = yup.InferType<typeof schema>;

export const useLoginForm = () => {
  const navigate = useNavigate();
  const diContainer = useContext(diContainerContext);
  const companyUserRepository = diContainer.resolve(CompanyUserRepository);
  const companyUserCheckToken = diContainer.resolve(CompanyUserCheckToken);
  const { setUser, urlAfterLogin, setUrlAfterLogin } = useContext(companyUserContext);
  const [loading, setLoading] = useState(false);
  const [isInviteProcessing, setIsInviteProcessing] = useState(false);
  const [showNeedRegistrationMessage, setNeedRegistrationShowMessage] = useState(false);
  const [message, setMessage] = useState('');
  const searchParams = new URLSearchParams(window.location.search);
  const referrer = searchParams.get('comes_from');

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginForm>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      email: '',
      password: '',
    },
  });

  useEffect(() => {
    // 既存ユーザーが新規登録フローから遷移してきた場合
    if (referrer === 'already_registered_notification') {
      setUrlAfterLogin('/current/workgroup/');
      return;
    }

    // 別メールアドレスへのワークグループ招待
    const inviteUrlId = companyUserRepository.getStorage();
    if (inviteUrlId !== null) {
      setMessage(
        'ワークグループへの招待を受理するため、ログインまたはアカウントの新規登録を行ってください',
      );
      return;
    }

    // ユーザー登録の認証URLから遷移してきた場合
    if (referrer === 'auth_user_registration') {
      setNeedRegistrationShowMessage(true);
    }
    setUrlAfterLogin('/workgroup/');
  }, [referrer]);

  const redirectIfLoggedIn = useCallback(async () => {
    const result = await companyUserCheckToken.execute(false);
    if (result !== false) {
      setUser(result);
      navigate(urlAfterLogin);
    }
  }, [urlAfterLogin]);

  const login = handleSubmit(async ({ email, password }) => {
    setLoading(true);
    try {
      const user = await companyUserRepository.loginByEmail(email, password);
      setUser(user);

      if (user.team_role === TEAM_ROLE_TYPE.SUPPORT_CHAT_PERMITTED) {
        return navigate('/workgroup/chat/support/');
      }

      const inviteUrlId = await companyUserRepository.getStorage();
      if (inviteUrlId) {
        setIsInviteProcessing(true);

        setTimeout(() => {
          setIsInviteProcessing(false);
          navigate(`/business/invitation/auth/${inviteUrlId}`);
        }, 2000);
      } else {
        navigate(urlAfterLogin);
      }
    } finally {
      setLoading(false);
    }
  });

  const register = () => {
    navigate('/business/register/email/');
  };

  return {
    control,
    errors,
    loading,
    isInviteProcessing,
    showNeedRegistrationMessage,
    message,
    redirectIfLoggedIn,
    login,
    register,
  };
};
