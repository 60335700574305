import { useContext, useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { diContainerContext } from 'application/contexts/useDiContainer';
import { LineAccountLinkRequestRepository } from 'interfaceAdapter/repositories/LineAccountLinkRequest';

export const useLineAccountLinkRequest = () => {
  const diContainer = useContext(diContainerContext);
  const lineAccountLinkRequestRepository = diContainer.resolve(LineAccountLinkRequestRepository);
  const [searchParams] = useSearchParams();
  const ref = useRef(false);

  useEffect(() => {
    if (ref.current) {
      return;
    }
    ref.current = true;

    request();
  }, []);

  const request = async () => {
    const disableAutoLogin = searchParams.get('disableAutoLogin') === 'true';
    const res = await lineAccountLinkRequestRepository.request({ disableAutoLogin });

    window.location.href = res.redirectUrl;
  };
};
