import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { UserIcon } from 'ui/components/company/elements/UserIcon/UserIcon';
import { useMyTeamList } from './useMyTeamList';
import { mqUser } from 'utils/responsive';

const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  padding: 40px 28px;
  border-radius: 12px;
  background-color: var(--white);
`;

const TeamInfo = styled(Link)`
  display: grid;
  grid-template-areas:
    'icon name'
    'icon desc';
  grid-template-columns: 40px 1fr;
  place-items: center start;
  column-gap: 8px;
  row-gap: 4px;
  width: 100%;
  padding-inline: 16px;
  transition: opacity 0.3s ease-out;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
`;

const TeamIcon = styled.div`
  grid-area: icon;
`;

const TeamName = styled.div`
  grid-area: name;
  font-size: var(--font-x-large);
  font-weight: var(--font-bold);
`;

const TeamDesc = styled.div`
  grid-area: desc;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: var(--font-default);
  white-space: nowrap;
`;

const Separator = styled.div`
  width: 100%;
  height: 1px;
  margin-block: 16px;
  background-color: var(--gray-light-1);
`;

const Text = styled.p`
  font-size: var(--font-xx-large);
  line-height: 1.5;
  font-weight: 400;
  text-align: center;
  margin: 0 auto;
  ${mqUser('SP')} {
    font-size: var(--font-x-large);
  }
`;

/**
 * ログイン中ユーザーが所属しているワークグループ一覧
 */
export const MyTeamList: React.FC = () => {
  const { myTeams } = useMyTeamList();

  return (
    <Card>
      {myTeams.map((team, i) => (
        <>
          {i > 0 && <Separator />}

          <TeamInfo key={team.url_hash} to={`/workgroup/${team.url_hash}/`}>
            {/* Todo: ｢アーカイブ」ラベルの表示 */}
            <TeamIcon>
              <UserIcon src={team.icon_image} size={40} />
            </TeamIcon>
            <TeamName>{team.name}</TeamName>
            <TeamDesc>{team.about_company}</TeamDesc>
          </TeamInfo>
        </>
      ))}
      {myTeams.length === 0 && <Text>他に所属しているワークグループはありません</Text>}
    </Card>
  );
};
