import { useState } from 'react';
import styled from '@emotion/styled';
import { mqUser } from 'utils/responsive';

const Wrapper = styled.div`
  position: relative;
`;

const Button = styled.button`
  position: relative;
  width: 24px;
  height: 24px;
  border: 1px solid var(--gray-light-1);
  border-radius: 50%;
  background: none;
  cursor: pointer;
  line-height: 24px;

  &:hover {
    background-color: var(--gray-light-3);
  }
  &:before {
    content: '...';
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: inline-block;
    height: 1px;
    font-size: 12px;
    line-height: 1px;
    font-weight: var(--font-bold);
    color: var(--gray-dark-2);
  }
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  @supports (height: 100svh) {
    height: 100svh;
  }
  background-color: transparent;
  overscroll-behavior: contain;
  overflow-y: scroll;
  scrollbar-width: none;
  z-index: var(--mobile-menu-overlay);
`;

const Menu = styled.ul`
  position: absolute;
  top: 20px;
  right: -4px;
  margin: 0;
  padding: 0;
  border: 1px solid var(--gray-light-1);
  background-color: var(--white);
  border-radius: 4px;
  list-style: none;
  z-index: var(--mobile-menu);
  box-shadow: 0 0 4px var(--gray-light-2);

  ${mqUser('SP')} {
    right: 0;
  }
`;

const MenuItem = styled.li`
  padding: 6px 12px;
  font-size: 12px;
  color: var(--gray-dark-3);
  white-space: nowrap;
  cursor: pointer;
  &:hover {
    background-color: var(--gray-light-4);
  }
`;

interface Props {
  removeMessage: () => Promise<void>;
}

/**
 * メッセージのアクション一覧をツールチップで表示
 */
export const MessageMenu: React.FC<Props> = ({ removeMessage }) => {
  const [showMenu, setShowMenu] = useState(false);

  const menuItems = [
    {
      text: 'メッセージの取り消し',
      handler: removeMessage,
    },
  ];

  return (
    <Wrapper>
      <Button
        type="button"
        aria-label="メッセージのメニューを開く"
        onClick={() => setShowMenu(true)}
      />

      {showMenu && (
        <Overlay aria-label="メッセージのメニューを閉じる" onClick={() => setShowMenu(false)} />
      )}

      {showMenu && (
        <Menu role="dialog" aria-modal="true">
          {menuItems.map((item, index) => (
            <MenuItem
              key={index}
              tabIndex={0}
              onClick={async () => {
                await item.handler();
                setShowMenu(false);
              }}
            >
              {item.text}
            </MenuItem>
          ))}
        </Menu>
      )}
    </Wrapper>
  );
};
